import { SignIn } from "@phosphor-icons/react";

import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import { useResetChat } from "@/hooks/useResetChat";
import useTranslate from "@/hooks/useTranslate";

export const ContactFormSubmissionError = () => {
	const __ = useTranslate("Chat");
	const { showContactFormSubmissionError, setShowContactFormSubmissionError } = useDataContext();
	const { clientConfig } = useAppContext();
	const { handleCloseWidget, handleReset } = useResetChat();

	const handleContact = () => {
		window.open(clientConfig?.contactPageUrl, "_blank");
		handleReset();
		setShowContactFormSubmissionError(false);
	};

	if (!showContactFormSubmissionError) return null;

	return (
		<div className="w-full relative flex flex-col rounded-lg p-3 border-2 border-neutral-200 bg-neutral-100 mt-6">
			<p className="text-sm text-neutral-800 font-bold text-center mt-2">{__("sorrySomethingWentWrong")}</p>
			<p className="text-xs text-neutral-500 font-medium text-center mt-2">{__("contactUsHere")}</p>
			<button
				onClick={handleContact}
				className="w-1/2 px-4 py-2.5 mx-auto mt-4 bg-client-primary text-white font-semibold rounded-md flex items-center justify-center hover:opacity-80 transition-opacity duration-200"
			>
				<SignIn size={20} className="mr-2" weight="bold" />
				{__("contactUs")}
			</button>
			<div className="flex space-x-3 mt-5">
				<button
					onClick={handleReset}
					className="w-1/2 px-4 py-2.5 bg-client-tertiary text-white font-semibold rounded-md flex items-center justify-center hover:opacity-80 transition-opacity duration-200"
				>
					{__("newConversation")}
					<span className="text-sm py-0.5 px-1.5 text-white/50 bg-white bg-opacity-10 rounded-md font-bold ml-1">
						KI
					</span>
				</button>
				<button
					className="w-1/2 px-4 py-2.5 bg-gray-300 font-semibold rounded-md transition-colors hover:bg-gray-200"
					onClick={handleCloseWidget}
				>
					{__("close")}
				</button>
			</div>
		</div>
	);
};
