import { Role } from "@/types";

export const mapRoleToChatbaseRole = (role: Role) => {
	switch (role) {
		case Role.assistant:
			return "assistant";
		case Role.human:
			return "user";
		case Role.system:
			return "system";
		default:
			return "user";
	}
};
