"use client";

import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from "react";

import { useAgent } from "@/hooks/useAgent";
import { useCustomer } from "@/hooks/useCustomer";
import { useHandoverState } from "@/hooks/useHandoverState";
import { useSession } from "@/hooks/useSession";
import { AgentType, Customer, Message, User } from "@/types";
import { HandoverState } from "@/types/HandoverState";

import { useAppContext } from "./AppContext";

interface DataContextProps {
	sessionId: string;
	setSessionId: (sessionId: string) => void;
	resetSession: (sessionId?: string) => void;
	activeAgent: AgentType;
	setActiveAgent: Dispatch<SetStateAction<AgentType>>;
	agentDetails: User;
	setAgentDetails: Dispatch<SetStateAction<User>>;
	customer: Customer;
	setCustomer: (customer: Customer) => void;
	messages: Message[];
	setMessages: Dispatch<SetStateAction<Message[]>>;
	postalCode: string | null;
	setPostalCode: Dispatch<SetStateAction<string | null>>;
	handoverState: HandoverState;
	setHandoverState: Dispatch<SetStateAction<HandoverState>>;
	isHandoverCompletedRef: React.MutableRefObject<boolean>;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

interface DataProviderProps {
	children: ReactNode;
}

export const DataProvider = ({ children }: DataProviderProps) => {
	const { activeAgent, setActiveAgent, agentDetails, setAgentDetails } = useAgent();
	const { setIsDisabledInput } = useAppContext();
	const { sessionId, resetSession, setSessionId } = useSession({ setActiveAgent });
	const { customer, setCustomer } = useCustomer();
	const [messages, setMessages] = useState<Message[]>([]);
	const { isHandoverCompletedRef, handoverState, setHandoverState } = useHandoverState({
		setActiveAgent,
		setMessages,
		setIsDisabledInput,
	});
	const [postalCode, setPostalCode] = useState<string | null>(null);
	const contextValue = useMemo(
		() => ({
			isHandoverCompletedRef,
			handoverState,
			setHandoverState,
			postalCode,
			setPostalCode,
			sessionId,
			resetSession,
			setSessionId,
			activeAgent,
			setActiveAgent,
			agentDetails,
			setAgentDetails,
			customer,
			setCustomer,
			messages,
			setMessages,
		}),
		[
			activeAgent,
			setActiveAgent,
			agentDetails,
			setAgentDetails,
			customer,
			setCustomer,
			messages,
			setMessages,
			postalCode,
			setPostalCode,
			resetSession,
			sessionId,
			setSessionId,
		],
	);

	return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

export const useDataContext = (): DataContextProps => {
	const context = useContext(DataContext);
	if (!context) {
		throw new Error("useDataContext must be used within a DataProvider");
	}
	return context;
};
