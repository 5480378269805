import useTranslate from "@/hooks/useTranslate";
import { LiveChatUserType, createMessage } from "@/services/liveChat/LiveChat";
import { ChatResponse, livechatUserInterface } from "@/services/liveChat/types";
import { AgentType, LangflowMessageType, Message, MessageType, Role } from "@/types";
import { saveMessageInDB } from "@/utils/saveMessageInDB";

interface ChatData extends Omit<ChatResponse, "users"> {
	users: Array<livechatUserInterface>;
}

const getWelcomeMessage = (chat: ChatData): Message => {
	const agent = chat.users.find((user) => user.type === LiveChatUserType.AGENT);
	const events = chat.thread.events;
	return {
		role: Role.assistant,
		content: events[0].text ?? "",
		agentType: AgentType.LiveChat,
		messageType: MessageType.message,
		avatar: agent?.avatar ?? undefined,
		seen: false,
		timestamp: new Date(events[0].createdAt),
	};
};

export const initialLivechatMessages = (chat: ChatData, sessionId: string, translate: Function): Promise<Message[]> => {
	const agent = chat.users.find((user: livechatUserInterface) => user.type === LiveChatUserType.AGENT);
	const joinMessage = createMessage(
		`${agent?.name ?? "Agent"} ${translate("hasJoinedConversation")}`,
		Role.system,
		AgentType.System,
	);
	const welcomeMessage = getWelcomeMessage(chat);
	return saveMessageInDB({
		message: joinMessage,
		sessionId,
		type: LangflowMessageType.message,
	}).then(() => {
		const events = chat.thread.events;
		if (events.length === 1) {
			saveMessageInDB({
				message: welcomeMessage,
				sessionId,
				type: LangflowMessageType.message,
			});

			return [joinMessage, welcomeMessage];
		}

		return [joinMessage];
	});
};
