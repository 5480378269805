"use client";

import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";

import { AgentType, Message, MessageType, Role } from "@/types";
import { HandoverState } from "@/types/HandoverState";

import useTranslate from "./useTranslate";

type Props = {
	setActiveAgent: Dispatch<SetStateAction<AgentType>>;
	setMessages: Dispatch<SetStateAction<Message[]>>;
	setIsDisabledInput: (isDisabled: boolean) => void;
};
export const useHandoverState = ({ setActiveAgent, setMessages, setIsDisabledInput }: Props) => {
	const __ = useTranslate();
	const [handoverState, setHandoverState] = useState<HandoverState>(HandoverState.None);

	const isHandoverCompletedRef = useRef<boolean>(false);
	/**
	 * Handle the 'handover' event triggered by the Socket.IO server.
	 */
	const handleHandover = useCallback(async () => {
		console.log("Handover initiated.");
		setHandoverState(HandoverState.Online);
		isHandoverCompletedRef.current = true;

		try {
			const res = await fetch("/api/livechat/checkAgentAvailability", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
			});

			if (!res.ok) throw new Error("Failed to check agent availability");

			const data = await res.json();
			const agentAvailability = data.livechat_agent_availability;

			if (agentAvailability === "offline") {
				setHandoverState(HandoverState.Offline);
				setActiveAgent(AgentType.ContactForm);
				setIsDisabledInput(true);
			} else {
				setHandoverState(HandoverState.Online);
				setActiveAgent(AgentType.LiveChat);
				setMessages((prev) => [
					...prev,
					{
						role: Role.system,
						content: __("aLiveAgentHasJoinedTheConversation"),
						messageType: MessageType.message,
						seen: false,
						timestamp: new Date(),
					},
				]);
			}
		} catch (error: any) {
			console.error("Error during handover:", error);
			setHandoverState(HandoverState.Offline);
			setActiveAgent(AgentType.ContactForm);
			setIsDisabledInput(true);
		}
	}, [setActiveAgent, setMessages]);

	return {
		isHandoverCompletedRef,
		handoverState,
		setHandoverState,
		handleHandover,
	};
};
