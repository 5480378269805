import { formatLists } from "@/utils/widget/formatLists";

// Merge consecutive ul elements into a single ul
const mergeConsecutiveUls = (text: string): string => {
	// First, normalize newlines between uls to ensure consistent processing
	text = text.replace(/<\/ul>\s*<ul/g, '</ul><ul');
	
	// Replace consecutive </ul><ul> patterns with just the content between them, but only if they have the same style
	return text.replace(/<\/ul><ul style="([^"]*)">/g, (match, style, offset) => {
		const prevStyle = text.substring(0, offset).match(/style="([^"]*)"/)?.[1];
		return prevStyle === style ? '' : match;
	});
};

export const parseText = (text: string) => {
	// Handle strong/bold tags in various formats
	text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
	text = text.replace(/<b>(.*?)<\/b>/g, "<strong>$1</strong>");
	text = text.replace(/strong(.*?)\/strong/g, "<strong>$1</strong>"); // Handle strong without angle brackets
	text = text.replace(/<strong>(.*?)<\/strong>/g, "<strong>$1</strong>");
	text = text.replace(/<i>(.*?)<\/i>/g, "<em>$1</em>");

	text = text.replace(/<product-name href="(.*?)">(.*?)<\/product-name>/g, '<a class="product-link" href="$1">$2</a>');
	text = text.replace(/(?<![</])[<>](?![>\/])/g, ""); // Remove standalone angle brackets, but keep HTML tags
	text = text.replace(/\[(.*?)\]\(((?!mailto:).*?)\)/g, '<a href="$2">$1</a>');
	text = text.replace(/\[(.*?)\]\((mailto:.*?)\)/g, '<strong><a href="$2">$1</a></strong>');
	text = text.replace(/(?<!["'=])(https?:\/\/[^\s<]+)/g, '<a href="$1">$1</a>');

	// Handle horizontal rules (dividing lines)
	text = text.replace(/^(?:---|\*\*\*|___)\s*$/gm, '<hr class="message-divider">');

	// 3. Format plain text lists.
	text = formatLists(text);

	// 3.5 Merge consecutive ul elements with same style
	text = mergeConsecutiveUls(text);

	// 4. Convert markdown line breaks (two spaces + newline) to <br> tags.
	text = text.replace(/  \n/g, "<br>");

	// 5. Split into paragraphs by double newlines, but preserve list structure
	const paragraphs = text
		.split(/\n\n+/)
		.map((p) => p.trim())
		.filter((p) => p !== "");

	text = paragraphs
		.map((p) => {
			// If p already starts with a block element, contains a list, or is a heading, leave it as is
			if (
				p.startsWith("<ul") || 
				p.startsWith("<p") || 
				p.startsWith("<div") || 
				p.includes("<li>") ||
				p.includes("<hr") ||  // Updated to match any hr tag including with class
				/^#{1,4}\s+/.test(p)  // Don't wrap headings in p tags
			) return p;
			// If p is just text (not empty and not just whitespace), wrap in p tags
			if (p && p.trim()) return `<p>${p}</p>`;
			return p;
		})
		.join("\n");

	// 6. Process headings after paragraph wrapping
	text = text.replace(/^#{1,4}\s+(.*?)(?:\n|$)/gm, (match, content) => {
		const level = match.trim().split(' ')[0].length;
		return `<h${level}>${content}</h${level}>\n`;
	});

	// 7. Cleanup extra empty paragraphs and collapse duplicate newlines.
	text = text.replace(/<p>\s*<\/p>/g, "");
	text = text.replace(/\n{2,}/g, "\n");

	return `<div>${text.trim()}</div>`;
};
