
    window.process = {
      env: {
        NODE_ENV: 'production',
        NEXT_PUBLIC_APP_URL: 'https://ligaard.frontkom.ai',
        NEXT_PUBLIC_DRUPAL_API_URL: 'https://www.ligaard.net',
        NEXT_PUBLIC_WEBSOCKET_SERVER_URL: 'http://localhost:4000',
        NEXT_PUBLIC_LOCALE: 'no',
        NEXT_PUBLIC_LIVECHAT_CLIENT_ID: '925f8d7485116cbba775e879ab73e36b',
        NEXT_PUBLIC_LIVECHAT_ORG_ID: '6fb939ac-f34a-4950-9a06-9fd27b73463e',
        CLIENT_NAME: 'ligaard'
      }
    };

  