"use client";

import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from "react";

import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import useTranslate from "@/hooks/useTranslate";
import { useLiveChatAgent } from "@/services/liveChat/hooks/useLiveChatAgent";
import { useLiveChatErrors } from "@/services/liveChat/hooks/useLiveChatErrors";
import { useLiveChatIncomingListener } from "@/services/liveChat/hooks/useLiveChatIncomingListener";
import { useLivechatConversation } from "@/services/liveChat/hooks/useLivechatConversation";
import { CustomerSDKType, LivechatErrorContext, livechatUserInterface } from "@/services/liveChat/types";
import { initialLivechatMessages } from "@/services/liveChat/utils/getWelcomeMessage";
import { AgentType, LangflowMessageType, Message, MessageType, Role } from "@/types";
import { saveMessageInDB } from "@/utils/saveMessageInDB";

import { initializeChatSDK } from "./initializeChatSDK";

export const createMessage = (
	content: string,
	role: Role,
	agentType: AgentType,
	messageType: MessageType = MessageType.message,
) => {
	return {
		role,
		content,
		agentType,
		messageType,
		seen: false,
		timestamp: new Date(Date.now()),
	};
};

export enum LiveChatUserType {
	AGENT = "agent",
}

interface LiveChatContextType {
	postMessageToLiveChat: (message: string, type: MessageType) => Promise<void | undefined>;
	handleFileUploadToLiveChatAndSendMessage: (file: File) => Promise<void | undefined>;
	closeLiveChat: () => Promise<void>;
}

const LiveChatContext = createContext<LiveChatContextType | undefined>(undefined);

export const LiveChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const __ = useTranslate("Chat");
	const { liveChatConversationStarted, setLiveChatConversationStarted } = useLivechatConversation();
	const { sessionId, agentDetails, activeAgent, customer, setAgentDetails, setActiveAgent, setMessages, messages } =
		useDataContext();
	const { setIsUploadingFile, setIsAgentOnline } = useAppContext();
	const customerSDK = useRef<CustomerSDKType | undefined>(undefined);
	const chatID = useRef<string | undefined>(undefined);
	const initializationInProgress = useRef<boolean>(false);
	const { handleLiveChatError, setupErrorListeners } = useLiveChatErrors();
	const { setupAgentListeners } = useLiveChatAgent({ handleLiveChatError });

	const initializeSDK = useCallback(async () => {
		if (initializationInProgress.current || customerSDK.current || typeof window === "undefined") {
			return;
		}

		try {
			initializationInProgress.current = true;
			const sdk = await initializeChatSDK(setIsAgentOnline);
			customerSDK.current = sdk;
			setupErrorListeners(sdk);
			setupAgentListeners(sdk);
		} catch (error) {
			handleLiveChatError(error, LivechatErrorContext.INITIALIZATION_ERROR);
		} finally {
			initializationInProgress.current = false;
		}
	}, [handleLiveChatError, setIsAgentOnline, setupErrorListeners, setupAgentListeners]);

	useEffect(() => {
		if (typeof window !== "undefined" && !customerSDK.current && !initializationInProgress.current) {
			initializeSDK();
		}
	}, [initializeSDK]);

	useEffect(() => {
		if (activeAgent === AgentType.LiveChat && chatID.current === undefined && customerSDK.current) {
			customerSDK.current?.listChats({ limit: 10 }).then(({ chatsSummary, totalChats }) => {
				if (totalChats > 0) {
					chatID.current = chatsSummary[0].id;
				}
			});
		}
	}, [activeAgent]);

	useLiveChatIncomingListener({ activeCustomerSDK: customerSDK.current, chatID, setLiveChatConversationStarted });

	useEffect(() => {
		const updateCustomerData = async () => {
			if (customerSDK?.current && (customer?.name || customer?.email)) {
				const customerData: { name?: string; email?: string } = {};
				if (customer.name) customerData.name = customer.name;
				if (customer.email) customerData.email = customer.email;
				try {
					await customerSDK.current?.updateCustomer(customerData);
				} catch (error) {
					handleLiveChatError(error, "Error updating customer data");
				}
			}
		};

		updateCustomerData();
	}, [customer, handleLiveChatError]);

	const getChat = useCallback(() => {
		if (activeAgent !== AgentType.LiveChat) {
			console.log("activeAgent is not LiveChat, skipping getChat");
			return;
		}
		return customerSDK.current
			?.getChat({
				chatId: chatID?.current,
			})
			.then((chat: any) => {
				const agent = chat.users.find((user: livechatUserInterface) => user.type === LiveChatUserType.AGENT);
				setAgentDetails(agent);
				return chat;
			})
			.catch((error: any) => {
				handleLiveChatError(error, LivechatErrorContext.GET_CHAT_ERROR);
				return null;
			});
	}, [activeAgent, handleLiveChatError, setAgentDetails]);

	const startOrResumeChat = useCallback(() => {
		// Define the function once at a higher scope
		const sendPreviousMessages = async (chat: any) => {
			for (const msg of messages.filter((msg) => msg.messageType === MessageType.message)) {
				await customerSDK.current?.sendEvent({
					chatId: chat.id,
					event: {
						type: "message",
						text: msg.role === Role.assistant ? `Bot: ${msg.content}` : `Customer: ${msg.content}`,
					},
				});
			}
		};

		return initializeChatSDK(setIsAgentOnline)
			.then((sdk) => {
				customerSDK.current = sdk;
				return customerSDK.current?.listChats({ limit: 10 });
			})
			.then(({ chatsSummary, totalChats }) => {
				if (activeAgent !== AgentType.LiveChat) return;
				if (totalChats > 0) {
					chatID.current = chatsSummary[0].id;
					// Resume Chats
					if (!chatsSummary[0].active && chatID?.current !== undefined) {
						return customerSDK?.current
							?.resumeChat({
								chat: {
									id: chatID?.current ?? "",
									thread: {
										events: [],
									},
								},
							})
							.then(({ chat }: any) => {
								chatID.current = chat.id;

								sendPreviousMessages(chat).then(() => {
									initialLivechatMessages(chat, sessionId, __).then((messages) => {
										setMessages((prev) => [...prev, ...messages]);
									});
								});

								return chat;
							})
							.catch((error) => {
								handleLiveChatError(error, LivechatErrorContext.RESUME_CHAT_ERROR);
							});
					}
				} else {
					// Start a new chat
					return customerSDK?.current
						?.startChat({
							chat: {
								thread: {
									events: [],
								},
							},
						})
						.then(({ chat }: any) => {
							chatID.current = chat.id;
							if (activeAgent !== AgentType.LiveChat) return chat;

							sendPreviousMessages(chat).then(() => {
								initialLivechatMessages(chat, sessionId, __).then((messages) => {
									setMessages((prev) => [...prev, ...messages]);
								});
							});

							return chat;
						})
						.catch((error: any) => {
							handleLiveChatError(error, LivechatErrorContext.START_CHAT_ERROR);
						});
				}
			})
			.then((response: any) => {
				if (response) {
					chatID.current = response?.chat?.id;
					setLiveChatConversationStarted(true);
					getChat();
				}
			})
			.catch((error: any) => {
				handleLiveChatError(error, "general");
			});
	}, [
		setIsAgentOnline,
		messages,
		activeAgent,
		sessionId,
		__,
		setMessages,
		handleLiveChatError,
		setLiveChatConversationStarted,
		getChat,
	]);

	useEffect(() => {
		if (activeAgent === AgentType.LiveChat) {
			if (!customerSDK.current) {
				initializeChatSDK(setIsAgentOnline).then((sdk) => {
					customerSDK.current = sdk;
					startOrResumeChat().catch((error) => handleLiveChatError(error, LivechatErrorContext.START_CHAT_ERROR));
				});
			} else if (!chatID.current) {
				// If we have SDK but no chat ID, start a new chat
				startOrResumeChat().catch((error) => handleLiveChatError(error, LivechatErrorContext.RESUME_CHAT_ERROR));
			}
		}
	}, [activeAgent, startOrResumeChat, handleLiveChatError, setIsAgentOnline]);

	const handleEventMarkAsSeen = useCallback(
		(payload: any) => {
			const { chatId, userId, seenUpTo } = payload;
			console.log(seenUpTo);
			if (userId === agentDetails?.id) {
				setMessages((prev) => [
					...prev.map((msg) => {
						const date1 = new Date(msg.timestamp);
						const date2 = new Date(seenUpTo.toString());
						const isSeen = !msg.timestamp ? undefined : date1 <= date2;
						return { ...msg, seen: isSeen };
					}),
				]);

				localStorage.setItem(`lastSeen_${chatId}`, seenUpTo.toString());
			}
		},
		[agentDetails, setMessages],
	);

	const removeEventMarkAsSeenListener = useCallback(() => {
		customerSDK.current?.off("events_marked_as_seen", handleEventMarkAsSeen);
	}, [handleEventMarkAsSeen]);

	const addEventMarkAsSeenListener = useCallback(() => {
		customerSDK.current?.on("events_marked_as_seen", handleEventMarkAsSeen);
	}, [handleEventMarkAsSeen]);

	useEffect(() => {
		if (activeAgent === AgentType.LiveChat) {
			addEventMarkAsSeenListener();
		}
		return () => {
			removeEventMarkAsSeenListener();
		};
	}, [activeAgent, addEventMarkAsSeenListener, removeEventMarkAsSeenListener]);

	const postMessageToLiveChat = useCallback(
		(message: string, type: MessageType) => {
			if (!customerSDK.current || !chatID.current) {
				return Promise.reject(new Error("Chat is not initialized"));
			}

			const isMessageType = type === MessageType.message;
			const payload = {
				chatId: chatID.current,
				event: {
					type: type,
					text: isMessageType ? message : undefined,
					url: !isMessageType ? message : undefined,
				},
			};

			return customerSDK.current
				?.sendEvent(payload)
				.then((response: any) => {
					if (response.url) {
						const newUploadedFile: Message = {
							contentType: response.contentType,
							content: response.content,
							messageType: response.type,
							url: response.url,
							role: Role.human,
							fileName: response.name,
							seen: false,
							timestamp: response.createdAt,
						};

						setMessages((prev) => [...prev, newUploadedFile]);
						setIsUploadingFile(false);
					}
				})
				.catch((error) => {
					handleLiveChatError(error, LivechatErrorContext.SEND_EVENT_ERROR);
				});
		},
		[customerSDK, chatID, setMessages, setIsUploadingFile, handleLiveChatError],
	);

	const postFileToLiveChat = useCallback(
		(file: File) => {
			if (!customerSDK?.current) {
				return Promise.reject(new Error("Chat SDK is not initialized"));
			}

			const { promise } = customerSDK.current?.uploadFile({
				file,
				onProgress: (progress: number) => console.log(`upload progress: ${progress}`),
			});

			return promise.catch((error) => {
				handleLiveChatError(error, LivechatErrorContext.UPLOAD_FILE_ERROR);
				throw error;
			});
		},
		[customerSDK, handleLiveChatError],
	);

	const handleFileUploadToLiveChatAndSendMessage = useCallback(
		(file: File) => {
			return postFileToLiveChat(file)
				.then((response) => {
					console.log("File uploaded successfully:", response.url);
					saveMessageInDB({
						message: {
							content: response.url,
							url: response.url,
							messageType: MessageType.file,
							fileName: file.name,
							contentType: file.type,
							role: Role.human,
							seen: false,
							timestamp: new Date(),
						},
						sessionId,
						type: LangflowMessageType.message,
					});

					return postMessageToLiveChat(response.url, MessageType.file);
				})
				.catch((error) => {
					console.error("File upload failed:", error);
					setIsUploadingFile(false);
				});
		},
		[postFileToLiveChat, postMessageToLiveChat, sessionId, setIsUploadingFile],
	);

	const closeLiveChat = useCallback(async () => {
		console.log("Closing LiveChat - initial state:", {
			hasSDK: !!customerSDK.current,
			chatId: chatID.current,
		});

		try {
			// Initialize SDK if not already initialized
			if (!customerSDK.current) {
				customerSDK.current = await initializeChatSDK(setIsAgentOnline);
			}

			// Get current chat if chatId is not set
			if (!chatID.current && customerSDK.current) {
				const { chatsSummary, totalChats } = await customerSDK.current?.listChats({ limit: 1 });
				if (totalChats > 0) {
					chatID.current = chatsSummary[0].id;
				}
			}

			console.log("Closing LiveChat - after initialization:", {
				hasSDK: !!customerSDK.current,
				chatId: chatID.current,
			});

			if (customerSDK.current && chatID.current) {
				// First, create and save the close message
				const closeMessage = createMessage(__("chatSessionHasBeenArchived"), Role.system, AgentType.System);
				await saveMessageInDB({
					message: closeMessage,
					sessionId,
					type: LangflowMessageType.message,
				});
				setMessages((prev) => [...prev, closeMessage]);

				// Then deactivate the chat
				await customerSDK.current?.deactivateChat({
					id: chatID?.current,
				});

				// Clean up listeners
				removeEventMarkAsSeenListener();

				setAgentDetails({
					id: "",
					type: "",
					name: "",
					avatar: "",
				});
				setActiveAgent(AgentType.AI);

				// Only clear the chat ID, keep the SDK connection
				chatID.current = undefined;
			} else {
				console.log("Failed to initialize LiveChat connection for closing");
				// Still reset the agent even if we couldn't close properly
				setActiveAgent(AgentType.AI);
			}
		} catch (error) {
			console.error("Error during LiveChat closure:", error);
			handleLiveChatError(error, LivechatErrorContext.CLOSE_CHAT_ERROR);
			// Ensure we still reset the agent on error
			setActiveAgent(AgentType.AI);
		}
	}, [
		sessionId,
		setActiveAgent,
		setAgentDetails,
		setMessages,
		setIsAgentOnline,
		removeEventMarkAsSeenListener,
		handleLiveChatError,
		__,
	]);

	const value = useMemo(
		() => ({
			postMessageToLiveChat,
			handleFileUploadToLiveChatAndSendMessage,
			closeLiveChat,
			liveChatConversationStarted,
			setLiveChatConversationStarted,
		}),
		[
			postMessageToLiveChat,
			handleFileUploadToLiveChatAndSendMessage,
			closeLiveChat,
			liveChatConversationStarted,
			setLiveChatConversationStarted,
		],
	);

	return <LiveChatContext.Provider value={value}>{children}</LiveChatContext.Provider>;
};

export const useLiveChat = () => {
	return useContext(LiveChatContext);
};
