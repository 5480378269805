import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { PiMapPinArea } from "react-icons/pi";

import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import useOutsideClick from "@/hooks/useOutsideClick";
import useTranslate from "@/hooks/useTranslate";
import { verifyPostalCode } from "@/utils/verifyPostalCode";

export const PostalCodeButton = () => {
	const __ = useTranslate("Chat");
	const { isDisabledInput, setIsDisabledInput, clientConfig } = useAppContext();
	const { setPostalCode, postalCode, setShowLocationModal, showLocationModal } = useChatContext();

	const [showTooltip, setShowTooltip] = useState(false);
	const [isClosing, setIsClosing] = useState(false);
	const tooltipTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	// Ref to ensure postal code is fetched only once
	const hasFetchedPostalCodeRef = useRef(false);

	// Initial load of postal code from cookie
	useEffect(() => {
		const savedPostal = Cookies.get("postalCode") ?? "";
		setPostalCode(savedPostal);

		if (hasFetchedPostalCodeRef.current) return;
		hasFetchedPostalCodeRef.current = true;

		verifyPostalCode().then((storedPostalCode) => {
			setPostalCode(storedPostalCode);
		});
	}, [setPostalCode]);

	// Re-check cookie when location modal closes (in case it was updated)
	useEffect(() => {
		if (!showLocationModal) {
			const currentPostalCode = Cookies.get("postalCode") ?? "";
			if (currentPostalCode !== postalCode) {
				setPostalCode(currentPostalCode);
			}
		}
	}, [showLocationModal, setPostalCode, postalCode]);

	const openLocationModal = () => {
		setShowLocationModal(true);
		setIsDisabledInput(true);
		setShowTooltip(false);
		setIsClosing(false);
	};

	const handleMouseEnter = () => {
		// Clear any existing timeout to prevent the tooltip from closing
		if (tooltipTimeoutRef.current) {
			clearTimeout(tooltipTimeoutRef.current);
			tooltipTimeoutRef.current = null;
		}
		setIsClosing(false);
		setShowTooltip(true);
	};

	const handleMouseLeave = () => {
		// Start the closing animation
		setIsClosing(true);

		// Set a timeout to actually hide the tooltip after animation completes
		tooltipTimeoutRef.current = setTimeout(() => {
			setShowTooltip(false);
			setIsClosing(false);
		}, 250); // Slightly faster than the 300ms on other components
	};

	// Clean up timeout on unmount
	useEffect(() => {
		return () => {
			if (tooltipTimeoutRef.current) {
				clearTimeout(tooltipTimeoutRef.current);
			}
		};
	}, []);

	if (!clientConfig?.enablePostalCode) return null;

	return (
		<div className="relative flex items-center">
			<button
				onClick={openLocationModal}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				disabled={isDisabledInput}
				aria-label={__("setLocation")}
				className="shadow-none bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent"
			>
				<PiMapPinArea
					className={`text-neutral-400 h-8 w-8 rounded-md p-1 hover:bg-neutral-100 ${
						isDisabledInput ? "cursor-not-allowed opacity-50" : "hover:cursor-pointer"
					}`}
				/>
			</button>

			{/* Postal Code Tooltip */}
			{showTooltip && postalCode && (
				<div
					className={`absolute bottom-12 right-0 z-10 
					bg-white rounded-[10px] px-[16px] py-[10px] border border-client-gray-light
					pointer-events-none ${isClosing ? "closing-tooltip" : "postal-code-tooltip"}`}
					aria-label={__("currentPostalCode")}
				>
					{postalCode}
					<div className="absolute w-2 h-2 bg-white border-r border-b border-client-gray-light transform rotate-45 -bottom-1 right-[11.5px]"></div>
				</div>
			)}
		</div>
	);
};
