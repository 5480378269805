import { useCallback } from "react";

import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import { useDataContext } from "@/contexts/DataContext";
import { useLiveChat } from "@/services/liveChat/LiveChat";
import { AgentType } from "@/types";

export const useResetChat = () => {
	const { setMessages, resetSession, activeAgent } = useDataContext();
	const {
		setShowWidget,
		initialAssistantMessage,
		setShowEndConversationThankYouMessage,
		setIsDisabledInput,
		setLoading,
		setShowSuggestedPrompts,
		clientConfig,
	} = useAppContext();
	const { setShowEndConversationModal } = useChatContext();
	const liveChat = useLiveChat();

	const handleReset = useCallback(() => {
		resetSession();
		setIsDisabledInput(false);
		setLoading(false);
		if (clientConfig?.showInitialMessage) {
			setMessages([initialAssistantMessage]);
		} else {
			setMessages([]);
		}
		setShowEndConversationThankYouMessage(false);
		setShowEndConversationModal(false);
		setShowSuggestedPrompts(true);
	}, [resetSession, setMessages, initialAssistantMessage, setLoading]);

	const handleCloseLiveChat = useCallback(async () => {
		if (activeAgent === AgentType.LiveChat && liveChat) {
			await liveChat.closeLiveChat();
		}
		setLoading(false);
		setShowEndConversationThankYouMessage(true);
		setIsDisabledInput(true);
	}, [activeAgent, liveChat, setShowEndConversationThankYouMessage, setIsDisabledInput, setLoading]);

	const handleCloseWidget = useCallback(() => {
		setLoading(false);
		setShowWidget(false);
	}, [setShowWidget, setLoading]);

	return {
		handleReset,
		handleCloseWidget,
		handleCloseLiveChat,
	};
};
