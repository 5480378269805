import React from 'react';

interface AiIconProps {
  className?: string;
}

export default function AiIcon({ className = '' }: AiIconProps): React.ReactElement {
  return (
    <svg 
      width="16" 
      height="16" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y=".333" width="16" height="16" rx="5" fill="#E6E6E6"/>
      <path d="M4.518 11.833V5.288h.987v3.126h.08l2.746-3.126h1.243l-2.64 2.947 2.65 3.598h-1.19L6.28 8.91l-.774.888v2.036h-.987Zm6.964-6.545v6.545h-.987V5.288h.987Z" fill="#1A1A1A"/>
    </svg>
  );
}