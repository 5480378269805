import { SignOut } from "@phosphor-icons/react";

import { Modal } from "@/components/molecules/Modal";
import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import { useResetChat } from "@/hooks/useResetChat";
import useTranslate from "@/hooks/useTranslate";

export const EndConversationModal = () => {
	const __ = useTranslate();
	const { setShowModal } = useChatContext();
	const { setIsDisabledInput, setLoading } = useAppContext();
	const { handleCloseLiveChat } = useResetChat();

	const handleEndConversation = async () => {
		setLoading(false);
		handleCloseLiveChat();
	};

	const handleContinue = () => {
		setShowModal(false);
		setIsDisabledInput(false);
		setLoading(false);
	};

	return (
		<Modal>
			<p>{__("endConversationModalText")}</p>

			<div className="mt-4 flex flex-col space-y-3">
				<button
					onClick={handleEndConversation}
					className="px-4 py-2.5 bg-client-primary text-client-text-primary-light font-semibold rounded-md flex items-center justify-center hover:opacity-80 transition-opacity duration-200"
				>
					<span className="mr-2">
						<SignOut size={24} weight="fill" />
					</span>{" "}
					{__("confirm")}
				</button>
				<button
					className="px-4 py-2.5 bg-gray-300 font-semibold rounded-md hover:bg-gray-200 transition-colors duration-200"
					onClick={handleContinue}
				>
					{__("continue")}
				</button>
			</div>
		</Modal>
	);
};
