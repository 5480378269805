import Cookies from "js-cookie";
import { useEffect, useState } from "react";

export const useAgentAvailabilityTooltip = () => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const cookieValue = Cookies.get("hideAgentAvailabilityTooltip");

		if (!cookieValue) {
			setIsVisible(true);
		}
	}, []);

	const closeTooltip = () => {
		setIsVisible(false);
		Cookies.set("hideAgentAvailabilityTooltip", "true", { expires: 1 / 48 });
	};

	return { isVisible, closeTooltip };
};
