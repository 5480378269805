import { useEffect, useState } from "react";

import { defaultConfig } from "@/overwrite/config/defaultConfig";
import { IClientConfig } from "@/types/IClientConfig";

interface UseClientConfigResult {
	isConfigLoaded: boolean;
	clientConfig: IClientConfig;
}

interface ClientConfigResponse {
	clientName: string;
	config: IClientConfig;
}

export const useClientConfig = (): UseClientConfigResult => {
	const [isConfigLoaded, setIsConfigLoaded] = useState(false);
	const [clientConfig, setClientConfig] = useState(defaultConfig);

	useEffect(() => {
		const loadClientConfig = async () => {
			const response = await fetch("/api/client-config");
			const data = await response.json();
			const { config } = data as ClientConfigResponse;
			return config;
		};

		loadClientConfig()
			.then((config) => {
				setClientConfig(config);
			})
			.catch(() => {
				setClientConfig(defaultConfig);
			})
			.finally(() => {
				setIsConfigLoaded(true);
			});
	}, []);

	return { isConfigLoaded, clientConfig };
};
