// Default configuration
import { IClientConfig } from "@/types/IClientConfig";

export const defaultConfig: IClientConfig = {
	agentName: "Agent",
	initialMessage: "How can I help you?",
	headerAvatarUrl: "/images/avatar-header-default.png",
	messageAvatar: "/images/avatar-default.png",
	showInitialMessage: true,
	enableSuggestedPrompts: false,
	enablePostalCode: true,
	getLangflowTweaksConfig: ({ postalCode, isAgentOnline, saveMessageToLangflow }) => {
		return {
			"PostalCode-ztXsS": {
				postal_code_input: postalCode,
			},
			"AgentAvailability-z0l0m": {
				agent_available: isAgentOnline,
			},
			...(!saveMessageToLangflow && {
				"AstraDBChatMemory-XAf0I": {
					save_messages: saveMessageToLangflow,
				},
			}),
		};
	},
	contactPageUrl: "/contact-us",
	privatePolicyUrl: "https://frontkom.com/privacy-policy/",
};
export default defaultConfig;
