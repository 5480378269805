"use client";

import { useAppContext } from "@/contexts/AppContext";
import { resolveDynamicComponent } from "@/utils/componentResolver";

// Icons
import WidgetIcon from "../icons/outlined/large/WidgetIcon";

interface ChatIconProps {
	position: "fixed" | "absolute" | "static";
}

const AgentAvailabilityTooltip = () => {
	const Component = resolveDynamicComponent("atoms/AgentAvailabilityTooltip");
	return Component ? <Component /> : null;
};

export const ChatIcon = ({ position }: ChatIconProps) => {
	const { setShowWidget, isAgentOnline, widgetAlignment } = useAppContext();
	const handleIconClick = () => {
		setShowWidget(true);
	};

	return (
		<>
			<div
				className={`fixed sm:bottom-[6.5rem] bottom-[5.5rem] ${widgetAlignment === "left" ? "sm:left-[2.5rem] left-[1.5rem] right-[1.5rem]" : "sm:right-[2.5rem] right-[1.5rem] left-[1.5rem]"}`}
			>
				<AgentAvailabilityTooltip />
			</div>
			<div
				className={`rounded-full widget-container-wrapper fixed sm:bottom-8 bottom-4 flex flex-col items-end ${widgetAlignment === "left" ? "sm:left-8 left-4" : "sm:right-8 right-4"}`}
				style={{ position }}
				onClick={handleIconClick}
			>
				<div className="relative group">
					<button
						className={`rounded-full border-default-transparent-white-60 flex w-[60px] h-[60px] items-center justify-center bg-client-primary hover:bg-client-primary-hover ${isAgentOnline ? "active-agent" : ""}`}
						onKeyDown={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								handleIconClick();
							}
						}}
						aria-label="Open chat widget"
						style={{ animation: "widgetIconAppear 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards" }}
					>
						<div className="transition-transform group-hover:scale-95">
							<WidgetIcon color="light" filled={true} />
						</div>
					</button>
					{/* {isAgentOnline && <div className="status-indicator bg-client-status" />} */}
				</div>
			</div>
		</>
	);
};
