"use client";

import { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";

import { checkDebugMode } from "@/actions/debugMode";
import { useAppContext } from "@/contexts/AppContext";
import useTranslate from "@/hooks/useTranslate";
import { AIIntegration } from "@/types";
import { CaretDown, Code } from "@phosphor-icons/react";

function LangflowIcon() {
	return (
		<svg width="20" height="20" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="shrink-0">
			<path d="M22.368 12.667H26.14C26.88 12.667 27.479 13.267 27.479 14.007V16.231C27.479 16.971 26.879 17.571 26.139 17.571H22.85C22.4949 17.571 22.1543 17.712 21.903 17.963L16.258 23.607C16.0069 23.8584 15.6663 23.9997 15.311 24H12.597C12.2476 24.0001 11.912 23.8638 11.6616 23.62C11.4113 23.3762 11.2661 23.0443 11.257 22.695L11.199 20.424C11.1943 20.2451 11.2255 20.0671 11.2908 19.9004C11.356 19.7338 11.454 19.5819 11.5789 19.4538C11.7038 19.3256 11.8531 19.2238 12.018 19.1542C12.1829 19.0847 12.36 19.0489 12.539 19.049H14.874C15.23 19.049 15.57 18.908 15.821 18.657L21.419 13.059C21.6701 12.8076 22.0107 12.6663 22.366 12.666L22.368 12.667Z" fill="#7528FC"/>
			<path d="M11.17 0H14.941C15.681 0 16.281 0.6 16.281 1.34V3.564C16.281 4.304 15.681 4.904 14.941 4.904H11.651C11.2959 4.90401 10.9553 5.045 10.704 5.296L5.06 10.941C4.80892 11.1924 4.46829 11.3337 4.113 11.334H1.398C1.04894 11.3336 0.713814 11.197 0.463928 10.9533C0.214041 10.7096 0.0691216 10.3779 0.0600045 10.029L4.53691e-06 7.757C-0.00453623 7.57824 0.0267762 7.40037 0.0920941 7.23391C0.157412 7.06744 0.255412 6.91574 0.380311 6.78777C0.50521 6.65979 0.654478 6.55813 0.819306 6.48879C0.984134 6.41944 1.16118 6.38381 1.34 6.384H3.675C4.031 6.384 4.371 6.242 4.623 5.991L10.22 0.394C10.3444 0.269238 10.4921 0.17022 10.6548 0.102614C10.8174 0.0350068 10.9918 0.000137263 11.168 0L11.17 0Z" fill="#FF3276"/>
			<path d="M22.368 2.866H26.14C26.88 2.866 27.479 3.466 27.479 4.206V6.43C27.479 7.17 26.879 7.77 26.139 7.77H22.85C22.4949 7.77001 22.1543 7.911 21.903 8.162L16.258 13.807C16.1336 13.9316 15.9858 14.0304 15.8231 14.0978C15.6605 14.1653 15.4861 14.2 15.31 14.2H12.013C11.6676 14.1999 11.3355 14.3332 11.086 14.572L4.75001 20.645C4.50001 20.885 4.16801 21.017 3.82301 21.017H1.51301C0.773011 21.017 0.174011 20.417 0.174011 19.678V17.392C0.174011 16.652 0.774011 16.052 1.51401 16.052H3.80601C4.16201 16.052 4.50301 15.912 4.75401 15.66L10.772 9.642C11.0231 9.39063 11.3637 9.24927 11.719 9.249H14.875C15.23 9.249 15.571 9.108 15.822 8.856L21.42 3.26C21.6711 3.00863 22.0117 2.86727 22.367 2.867H22.369L22.368 2.866Z" fill="#F480FF"/>
		</svg>
	);
}

function ChatbaseIcon() {
	return (
		<svg width="20" height="20" viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg" className="shrink-0">
			<rect width="109" height="109" fill="#09090B" rx="28"></rect>
			<path fill="white" d="M84.5 46.5H66.9a12 12 0 0 0-1-4 9.1 9.1 0 0 0-5.5-5 13 13 0 0 0-4.5-.8c-3 0-5.4.7-7.4 2.1-2 1.4-3.6 3.5-4.6 6.1-1 2.7-1.5 5.9-1.5 9.6 0 4 .5 7.2 1.5 9.9 1.1 2.6 2.6 4.6 4.6 6 2 1.3 4.4 2 7.3 2 1.6 0 3-.3 4.3-.7 1.2-.4 2.3-1 3.3-1.8 1-.7 1.7-1.7 2.3-2.8.6-1 1-2.3 1.2-3.7l17.6.1a24 24 0 0 1-2.3 8.3 27 27 0 0 1-14.5 13.5 32.5 32.5 0 0 1-12.3 2.2c-5.9 0-11.2-1.3-15.8-3.8a27.5 27.5 0 0 1-11-11.2c-2.8-4.9-4.1-10.9-4.1-18 0-7.2 1.4-13.2 4.1-18 2.8-5 6.5-8.7 11.1-11.2a35.8 35.8 0 0 1 26.8-2.1c3.4 1 6.4 2.7 9 4.8a24 24 0 0 1 6.2 7.8c1.5 3.1 2.5 6.7 2.8 10.7Z"></path>
			<mask id="chatbase-logo-mask" width="44" height="66" x="21" y="23" maskUnits="userSpaceOnUse" style={{ maskType: "alpha" }}>
				<path fill="#A1A1AA" d="M41.5 23 51 37.5 62.5 71 65 87l-31.5 2L21 71l3.5-35 17-13Z"></path>
			</mask>
			<g mask="url(#chatbase-logo-mask)">
				<path fill="#B2AEB9" opacity="1" d="M84.5 46.5H66.9a12 12 0 0 0-1-4 9.1 9.1 0 0 0-5.5-5 13 13 0 0 0-4.5-.8c-3 0-5.4.7-7.4 2.1-2 1.4-3.6 3.5-4.6 6.1-1 2.7-1.5 5.9-1.5 9.6 0 4 .5 7.2 1.5 9.9 1.1 2.6 2.6 4.6 4.6 6 2 1.3 4.4 2 7.3 2 1.6 0 3-.3 4.3-.7 1.2-.4 2.3-1 3.3-1.8 1-.7 1.7-1.7 2.3-2.8.6-1 1-2.3 1.2-3.7l17.6.1a24 24 0 0 1-2.3 8.3 27 27 0 0 1-14.5 13.5 32.5 32.5 0 0 1-12.3 2.2c-5.9 0-11.2-1.3-15.8-3.8a27.5 27.5 0 0 1-11-11.2c-2.8-4.9-4.1-10.9-4.1-18 0-7.2 1.4-13.2 4.1-18 2.8-5 6.5-8.7 11.1-11.2a35.8 35.8 0 0 1 26.8-2.1c3.4 1 6.4 2.7 9 4.8a24 24 0 0 1 6.2 7.8c1.5 3.1 2.5 6.7 2.8 10.7Z"></path>
			</g>
		</svg>
	);
}

const providerLabels: Record<AIIntegration, string> = {
	[AIIntegration.Langflow]: "Langflow",
	[AIIntegration.Chatbase]: "Chatbase"
};

function AIProviderSelector() {
	const __ = useTranslate();
	const { aiProvider, setAiProvider } = useAppContext();
	const [isOpen, setIsOpen] = useState(false);
	const [position, setPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
	const triggerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (triggerRef.current && !triggerRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};

		const updatePosition = () => {
			if (triggerRef.current && isOpen) {
				const rect = triggerRef.current.getBoundingClientRect();
				setPosition({
					top: rect.bottom + window.scrollY + 4,
					left: rect.left + window.scrollX
				});
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		window.addEventListener('scroll', updatePosition, true);
		window.addEventListener('resize', updatePosition);

		updatePosition();

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			window.removeEventListener('scroll', updatePosition, true);
			window.removeEventListener('resize', updatePosition);
		};
	}, [isOpen]);

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	const handleProviderChange = (provider: AIIntegration) => {
		setAiProvider(provider);
		setIsOpen(false);
	};

	const renderProviderIcon = (provider: AIIntegration) => {
		switch (provider) {
			case AIIntegration.Langflow:
				return <LangflowIcon />;
			case AIIntegration.Chatbase:
				return <ChatbaseIcon />;
			default:
				return null;
		}
	};

	return (
		<div className="flex items-center space-x-4">
			<label className="text-gray-300 text-sm">
				{__("ai-provider")}
			</label>
			<div ref={triggerRef} className="relative">
				<div
					onClick={handleClick}
					className="flex items-center gap-2 px-4 py-2 rounded-lg transition-colors duration-200 border border-gray-600 hover:bg-gray-700 cursor-pointer min-w-[140px]"
				>
					{renderProviderIcon(aiProvider)}
					<span className="text-sm text-gray-300">{providerLabels[aiProvider]}</span>
					<CaretDown
						size={14}
						weight="bold"
						className={`text-gray-400 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''} ml-auto`}
					/>
				</div>

				{isOpen && createPortal(
					<div
						className="fixed z-[9999]"
						style={{
							top: position.top,
							left: position.left
						}}
					>
						<div className="px-2 py-1.5 rounded-xl bg-gray-800 border-2 border-gray-500 shadow-lg w-fit whitespace-nowrap min-w-[140px]">
							<div className="flex flex-col gap-1">
								{[AIIntegration.Langflow, AIIntegration.Chatbase].map((provider) => (
									<button
										key={provider}
										type="button"
										onMouseDown={(e) => {
											e.preventDefault();
											handleProviderChange(provider);
										}}
										className={`group flex items-center gap-2 px-2 py-1.5 rounded-lg transition-colors duration-200 
											${aiProvider === provider ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
									>
										{renderProviderIcon(provider)}
										<span className="text-sm text-gray-300 group-hover:text-white">{providerLabels[provider]}</span>
									</button>
								))}
							</div>
						</div>
					</div>,
					document.body
				)}
			</div>
		</div>
	);
}

export function PlaygroundSettings() {
	const [isVisible, setIsVisible] = useState(false);
	const __ = useTranslate();

	useEffect(() => {
		checkDebugMode().then(setIsVisible);
	}, []);

	if (!isVisible) {
		return null;
	}

	return (
		<div className="bg-gray-700 border-2 border-gray-600 rounded-xl shadow-lg absolute top-4 left-4 z-[100]">
			<div className="relative">
				<div className="bg-gray-800 rounded-t-xl border-b-2 border-gray-600 py-4 px-6">
					<div className="flex items-center gap-2">
						<Code size={20} weight="bold" className="text-yellow-500" />
						<h3 className="text-gray-200 font-medium">{__("developer-settings")}</h3>
					</div>
				</div>
			</div>
			<div className="p-4">
				<AIProviderSelector />
			</div>
		</div>
	);
}
