// types/index.ts

export enum AIIntegration {
	Langflow = "Langflow",
	Chatbase = "Chatbase",
}

export enum HumanIntegration {
	LiveChat = "LiveChat",
}

export enum Role {
	assistant = "assistant",
	human = "human",
	system = "system",
}

export enum MessageType {
	message = "message",
	file = "file",
	system = "system",
	info = "info",
}

export enum LangflowMessageType {
	message = "message",
}

export enum AgentType {
	AI = "AI",
	LiveChat = "LiveChat",
	System = "System",
	ContactForm = "ContactForm",
}

export interface Message {
	id?: string;
	avatar?: string;
	role: Role;
	content: string;
	agentType?: keyof typeof AgentType;
	file?: File;
	fileName?: string;
	messageType: keyof typeof MessageType;
	url?: string;
	contentType?: string;
	seen?: boolean;
	timestamp: Date;
}

export interface User {
	id: string;
	type: string;
	name: string;
	avatar: string;
}

export interface Customer {
	customFields?: any;
	id?: string;
	name?: string;
	email?: string;
	avatar?: string;
}
