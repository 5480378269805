import { SuggestedPromptProps } from "@/components/molecules/SuggestedPrompt";

export const SuggestedPrompt: React.FC<SuggestedPromptProps> = ({ onClick, children }) => {
	return (
		<button
			className="border-2 border-client-primary bg-transparent text-sm text-client-primary hover:text-white hover:bg-client-primary hover:scale-105 hover:rotate-1 rounded-xl px-4 py-2.5 whitespace-pre-wrap my-1 sm:my-1.5 inline-flex items-center font-medium transition-all duration-200"
			onClick={onClick}
		>
			{children}
		</button>
	);
};
