"use client";

import { useState } from "react";

import { Customer } from "@/types";

export const useCustomer = () => {
	const [customer, setCustomer] = useState<Customer>({
		customFields: undefined,
		id: "",
		name: "",
		avatar: "",
		email: "",
	});

	return {
		customer,
		setCustomer,
	};
};
