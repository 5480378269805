"use client";

import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import { useChatbase } from "@/services/chatbase/Chatbase";
import { useContactForm } from "@/services/email/hooks/useContactForm";
import { useLangflow } from "@/services/langflow/Langflow";
import { useLiveChat } from "@/services/liveChat/LiveChat";
import { AIIntegration, AgentType, Message, MessageType } from "@/types";
import { HandoverState } from "@/types/HandoverState";
import { saveMessageInDB } from "@/utils/saveMessageInDB";

export const useChat = () => {
	const { setIsUploadingFile, appUrl, setLoading, aiProvider, initialAssistantMessage, errorMessage, clientConfig } =
		useAppContext();
	const langFlow = useLangflow();
	const chatbase = useChatbase();
	const {
		sessionId,
		resetSession,
		activeAgent,
		setActiveAgent,
		setAgentDetails,
		setMessages,
		handoverState,
		setHandoverState,
		isHandoverCompletedRef,
	} = useDataContext();
	const liveChat = useLiveChat();
	const [loadingMessages, setLoadingMessages] = useState(true);
	const { handleContactFormSubmit } = useContactForm();

	useEffect(() => {
		if (activeAgent === AgentType.AI) {
			setAgentDetails({
				avatar: "",
				type: AgentType.AI,
				id: uuidv4(),
				name: clientConfig?.agentName ?? "Agent",
			});
		}
	}, [clientConfig?.agentName, activeAgent, setAgentDetails]);

	/**
	 * Initialize the chat with the initial assistant message and save it to the backend.
	 */
	const initializeChat = useCallback(() => {
		if (clientConfig?.showInitialMessage) setMessages([initialAssistantMessage]);
	}, []);

	/**
	 * Fetch messages from the backend. If no messages exist, initialize the chat.
	 */
	const fetchMessages = useCallback(async () => {
		try {
			if (!sessionId) {
				initializeChat();
				return;
			}
			const domain = process?.env?.NEXT_PUBLIC_DOMAIN ?? appUrl ?? "";
			const response = await fetch(`${domain}/api/chat/getMessages?sessionId=${sessionId}`);
			if (!response.ok) throw new Error("Failed to fetch messages");
			const data = await response.json();

			if (data.messages && data.messages.length > 0) {
				// If there are messages, just set them
				setMessages(data.messages);
			} else {
				// Otherwise, start fresh
				initializeChat();
			}
		} catch (error) {
			console.error("Error fetching messages:", error);
			initializeChat();
		} finally {
			setLoadingMessages(false);
		}
	}, [sessionId, initializeChat, appUrl, setMessages]);

	useEffect(() => {
		fetchMessages();
	}, [activeAgent, sessionId, fetchMessages]);

	const handleSend = (message: Message) => {
		switch (activeAgent) {
			case AgentType.AI:
				handleSendAI(message);
				break;
			case AgentType.LiveChat:
				handleSendLiveChat(message);
				break;
			default:
				console.error("Invalid agent type");
				setActiveAgent(AgentType.AI);
				return;
		}
	};

	const handleSendAI = (message: Message) => {
		switch (aiProvider) {
			case AIIntegration.Langflow:
				langFlow?.sendMessageToLangflow(message);
				break;
			case AIIntegration.Chatbase:
				chatbase?.sendMessageToChatbase(message);
				break;
			default:
				console.error("Invalid AI provider");
				return;
		}
	};
	/**
	 * Send a message and handle the response from the backend.
	 *
	 * @param message - The message to send.
	 */
	const handleSendLiveChat = useCallback(
		async (message: Message) => {
			if (handoverState === HandoverState.Offline || !liveChat) {
				console.warn("Cannot send messages while offline handover is active.");
				return;
			}

			try {
				if (message.messageType !== MessageType.file) {
					liveChat
						.postMessageToLiveChat(message.content, MessageType.message)
						?.then(() => {
							saveMessageInDB({
								message: message,
								sessionId,
								type: MessageType.message,
							});
						})
						.then(() => {
							setMessages((prev) => [...prev, message]);
						})
						.catch((error: any) => {
							console.log("error in get chat", error);
							return null;
						});
					return;
				}
				if (message.file !== undefined && message.messageType == MessageType.file) {
					setIsUploadingFile(true);
					liveChat.handleFileUploadToLiveChatAndSendMessage(message.file).finally(() => {
						setIsUploadingFile(false);
						setLoading(false);
					});
				}
			} catch (error: any) {
				if (error.name === "AbortError") {
					console.log("Request was aborted");
				} else {
					console.error("Error during handleSend:", error);

					await saveMessageInDB({
						message: errorMessage,
						sessionId,
						type: MessageType.message,
					}).then(() => {
						setMessages((prev) => [...prev, errorMessage]);
					});
				}
			}
		},
		[handoverState, sessionId, setIsUploadingFile, setLoading, setMessages, liveChat],
	);

	/**
	 * Reset the chat session by generating a new session ID and clearing messages.
	 *
	 * @param newSessionId - (Optional) A new session ID to set.
	 */
	const handleReset = useCallback(
		async (newSessionId?: string) => {
			setMessages([]);
			setHandoverState(HandoverState.None);
			setActiveAgent(activeAgent);
			isHandoverCompletedRef.current = false;

			resetSession(newSessionId);

			// Re-initialize the chat (and optionally rejoin socket room)
			setLoadingMessages(true);
			initializeChat();
			setLoadingMessages(false);
		},
		[initializeChat, activeAgent, isHandoverCompletedRef, resetSession, setActiveAgent, setHandoverState, setMessages],
	);

	return {
		loadingMessages,
		handoverState,
		handleSend,
		handleReset,
		handleContactFormSubmit,
	};
};
