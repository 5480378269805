import Cookies from "js-cookie";
import { useCallback, useEffect, useRef, useState } from "react";
import { RiSignpostFill } from "react-icons/ri";

import { Modal } from "@/components/molecules/Modal";
import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import useTranslate from "@/hooks/useTranslate";

export const LocationModal = () => {
	const __ = useTranslate("Chat");
	const { setShowLocationModal } = useChatContext();
	const { setIsDisabledInput } = useAppContext();

	const onClose = () => {
		setShowLocationModal(false);
		setIsDisabledInput(false);
	};

	return (
		<Modal onClose={onClose} title={__("locationTitle")}>
			<LocationContent onSubmitSuccess={onClose} />

			<div className="mt-4 flex justify-end">
				<button
					className="px-4 py-2.5 bg-gray-300 font-semibold rounded-md hover:bg-gray-200 focus:outline-none transition-colors duration-200"
					onClick={onClose}
				>
					{__("cancel")}
				</button>
			</div>
		</Modal>
	);
};

export const LocationContent = ({ onSubmitSuccess }: { onSubmitSuccess: (postalCode: string) => void }) => {
	const __ = useTranslate("Chat");
	const [postalCode, setPostalCode] = useState<string>(Cookies.get("postalCode") ?? "");
	const [inputError, setInputError] = useState<string | null>(null);

	const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

	const handleContainerClick = useCallback(() => {
		for (let i = 0; i < inputRefs.current.length; i++) {
			if (!postalCode[i]) {
				inputRefs.current[i]?.focus();
				return;
			}
		}
		inputRefs.current[inputRefs.current.length - 1]?.focus();
	}, [postalCode]);

	useEffect(() => {
		handleContainerClick();
	}, [handleContainerClick]);

	/**
	 * Handle changes to each individual input.
	 */
	const handleInputChange = (index: number, value: string) => {
		// Only allow single numeric digits
		if (isNaN(Number(value)) || value.length > 1) return;

		// As soon as the user starts typing, clear any existing error
		if (inputError && value !== "") {
			setInputError(null);
		}

		const newPostalCode = postalCode.split("");
		newPostalCode[index] = value;
		const joinedPostalCode = newPostalCode.join("");
		setPostalCode(joinedPostalCode);

		// Move focus to the next input if we're not at the last
		if (value && index < 3) {
			inputRefs.current[index + 1]?.focus();
		}

		// Clear any error if we've formed a potentially valid postal code
		if (joinedPostalCode.length === 4 && !isNaN(Number(joinedPostalCode))) {
			setInputError(null);
		}
	};

	/**
	 * Handle keyboard navigation between inputs.
	 */
	const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "ArrowLeft" && index > 0) {
			event.preventDefault();
			inputRefs.current[index - 1]?.focus();
		} else if (event.key === "ArrowRight" && index < 3) {
			event.preventDefault();
			inputRefs.current[index + 1]?.focus();
		} else if (event.key === "Backspace") {
			event.preventDefault();
			const newPostalCode = postalCode.split("");

			// If this input has a value, clear it
			if (newPostalCode[index]) {
				newPostalCode[index] = "";
				setPostalCode(newPostalCode.join(""));
			}
			// Otherwise, move to the previous input and clear that
			else if (index > 0) {
				inputRefs.current[index - 1]?.focus();
				newPostalCode[index - 1] = "";
				setPostalCode(newPostalCode.join(""));
			}
		} else if (event.key === "Enter") {
			onPostalCodeSubmit();
		}
	};

	/**
	 * Check if the current postal code is valid (4 digits).
	 */
	const isPostalCodeValid = () => {
		return postalCode.length === 4 && !isNaN(Number(postalCode));
	};

	/**
	 * Submit the postal code if valid; set error if not.
	 */
	const onPostalCodeSubmit = async () => {
		if (!isPostalCodeValid()) {
			setInputError(__("locationInvalidPostalCode"));
			return;
		}

		setInputError(null);
		Cookies.set("postalCode", postalCode, {
			path: "/",
			secure: process.env.NODE_ENV === "production",
			sameSite: "Strict",
		});

		try {
			onSubmitSuccess(postalCode);
			const sessionId = Cookies.get("sessionId") ?? "";
			const response = await fetch(`/api/session/savePostalNumber?sessionId=${sessionId}&postalNumber=${postalCode}`, {
				method: "GET",
			});

			if (!response.ok) {
				throw new Error("Failed to save postal code");
			}
		} catch (error) {
			console.error("Error saving postal code:", error);
			setInputError(__("locationSaveError"));
		}
	};

	return (
		<div>
			{inputError && <div className="text-red-600 mb-2">{inputError}</div>}
			
			<div className="flex items-center mb-4 input-container" onClick={handleContainerClick}>
				<div className="input-field-container rounded-md bg-client-secondary border-neutral-200 border-2">
					{[0, 1, 2, 3].map((index) => (
						<input
							key={index}
							type="tel"
							inputMode="numeric"
							pattern="[0-9]*"
							maxLength={1}
							value={postalCode[index] || ""}
							onChange={(e) => handleInputChange(index, e.target.value)}
							onKeyDown={(e) => handleKeyDown(index, e)}
							ref={(el) => {
								inputRefs.current[index] = el;
							}}
							placeholder="0"
							// If there's an error, apply text and placeholder red color
							className={
								`text-lg input-field bg-client-secondary border-b-2 border-gray-400 focus:border-client-primary` +
								(inputError ? ` text-red-600 placeholder-red-300 border-red-600` : ``)
							}
							aria-label={__("postalCodeDigit", { digit: index + 1 })}
						/>
					))}
				</div>
				<button
					onClick={onPostalCodeSubmit}
					aria-label={__("submitPostalCode")}
					className="flex ml-2 justify-center items-center submit bg-client-primary text-white px-4 py-2 rounded-md hover:bg-client-primary-hover focus:outline-none transition-colors duration-200"
				>
					<RiSignpostFill className="mr-1" />
					{__("save")}
				</button>
			</div>

			<p className="text-gray-500 text-xs postcode-info">
				{__("locationInfoText")}{" "}
				<a href="#" className="underline">
					{__("locationReadPrivacy")}
				</a>
			</p>
		</div>
	);
};