export type ChatbaseMessage = {
	role: "user" | "assistant";
	content: string;
};

export interface ChatbaseRequest {
	messages: ChatbaseMessage[];
	sessionId: string;
	isAgentOnline?: boolean;
	saveMessageToLangflow?: boolean;
}

export interface ChatbaseResponse {
	response: string;
	handover: boolean;
	toolInput: any;
}

export interface ChatbaseAPIResponse {
	text?: string;
	message?: string;
	response?: string;
	error?: string;
}

export interface ChatbaseErrorResponse {
	error: string;
	status: number;
}

type formDataType = {
	sessionId: string;
	messages: ChatbaseMessage[];
};

export const sendRequestToChatbaseAPI = async ({ messages, sessionId }: formDataType): Promise<ChatbaseAPIResponse> => {
	return await fetch("https://www.chatbase.co/api/v1/chat", {
		method: "POST",
		headers: {
			Authorization: `Bearer ${process.env.CHATBASE_API_KEY}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			messages: messages,
			chatbotId: process.env.CHATBASE_CHATBOT_ID,
			conversationId: sessionId,
		}),
	}).then((response) => {
		return response.json();
	});
};

export async function sendToChatbase(payload: {
	messages: { role: string; content: string }[];
	sessionId: string;
	appUrl?: string;
}) {
	const domain = process?.env?.NEXT_PUBLIC_DOMAIN ?? payload.appUrl ?? "";
	const response = await fetch(`${domain}/api/chat/chatbase`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(payload),
	});

	if (!response.ok) {
		const errorData: ChatbaseErrorResponse = await response.json();
		throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
	}

	return (await response.json()) as ChatbaseResponse;
}
