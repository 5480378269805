"use client";

import { useCallback } from "react";

import { useDataContext } from "@/contexts/DataContext";
import useTranslate from "@/hooks/useTranslate";
import { formDataType } from "@/services/email/sendEmail";
import { AgentType, LangflowMessageType, Message, MessageType, Role } from "@/types";
import { getEnvAppUrl } from "@/utils/getEnvAppUrl";
import { saveMessageInDB } from "@/utils/saveMessageInDB";

export const useContactForm = () => {
	const __ = useTranslate("Chat");
	const { sessionId, setMessages, setActiveAgent, setShowContactFormSubmissionError } = useDataContext();
	const handleContactFormSubmit = useCallback(
		async (formData: formDataType) => {
			try {
				const appUrl = getEnvAppUrl();
				const res = await fetch(`${appUrl}/api/email/send`, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(formData),
				});

				if (!res.ok) throw new Error("Failed to submit contact form");

				const systemMessage: Message = {
					role: Role.system,
					content: __("thankYouForYourMessage"),
					messageType: MessageType.info,
					seen: false,
					timestamp: new Date(),
				};
				setMessages((prev) => [...prev, systemMessage]);

				await saveMessageInDB({
					message: systemMessage,
					sessionId,
					type: LangflowMessageType.message,
				});

				setActiveAgent(AgentType.AI);
				return Promise.resolve();
			} catch (error) {
				console.error("Error submitting contact form:", error);
				const errorMessage: Message = {
					role: Role.system,
					content: "Beklager, noe gikk galt ved innsending av skjemaet. Vennligst prøv igjen senere.",
					messageType: MessageType.message,
					seen: false,
					timestamp: new Date(),
				};

				await saveMessageInDB({
					message: errorMessage,
					sessionId,
					type: MessageType.message,
				});

				setActiveAgent(AgentType.AI);
				setShowContactFormSubmissionError(true);

				return Promise.reject(error);
			}
		},
		[sessionId, setMessages, setActiveAgent, __],
	);

	return {
		handleContactFormSubmit,
	};
};
