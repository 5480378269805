import { useEffect, useState } from "react";

import { useChatContext } from "@/contexts/ChatContext";

export const Modal = ({ children }: { children: React.ReactNode }) => {
	const { showModal } = useChatContext();
	const [isClosing, setIsClosing] = useState(false);
	const [shouldRender, setShouldRender] = useState(false);

	useEffect(() => {
		if (showModal) {
			setIsClosing(false);
			setShouldRender(true);
		} else {
			setIsClosing(true);
			const timer = setTimeout(() => {
				setShouldRender(false);
			}, 200); // Duration should match CSS animation
			return () => clearTimeout(timer);
		}
	}, [showModal]);

	if (!shouldRender) return null;

	return (
		<div
			className={`modal absolute inset-0 z-10 flex items-center justify-center rounded-lg bg-black bg-opacity-70
        ${isClosing ? "animate-fade-out" : "animate-fade-in"}`}
		>
			<div
				className={`relative rounded-lg p-8 shadow-xl bg-white border-2 border-neutral-300 w-5/6 
          ${isClosing ? "animate-slide-out" : "animate-slide-in"}`}
			>
				{children}
			</div>
		</div>
	);
};
