import { useEffect, useRef, useState } from "react";

import { useDataContext } from "@/contexts/DataContext";
import { useContactForm as useSubmitContactForm } from "@/services/email/hooks/useContactForm";
import { AgentType } from "@/types";
import { saveEvent } from "@/utils/saveEvent";

export const useContactForm = () => {
	const { handleContactFormSubmit } = useSubmitContactForm();
	const { sessionId, activeAgent, customer } = useDataContext();

	const [name, setName] = useState(customer.name || "");
	const [email, setEmail] = useState(customer.email || "");
	const [phone, setPhone] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [message, setMessage] = useState("");
	const [includeChat, setIncludeChat] = useState<boolean>(true);

	const eventSavedRef = useRef(false);

	useEffect(() => {
		if (customer.name) setName(customer.name);
		if (customer.email) setEmail(customer.email);
	}, [customer.name, customer.email]);

	useEffect(() => {
		const savePresentedEvent = async () => {
			if (activeAgent === AgentType.ContactForm && !eventSavedRef.current && name && email) {
				const event = {
					event: "contactPresented",
					eventData: JSON.stringify({ name, email }),
				};
				await saveEvent(event);
				eventSavedRef.current = true;
			}
		};

		savePresentedEvent();
	}, [activeAgent, name, email]);

	const resetFields = () => {
		setName("");
		setEmail("");
		setPhone("");
		setPostalCode("");
		setMessage("");
		setIncludeChat(false);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const formData = {
			name,
			email,
			phone,
			postalCode,
			message,
			includeChat,
			sessionId,
		};

		try {
			await handleContactFormSubmit(formData);
			resetFields();

			const event = {
				event: "contactSubmitted",
				eventData: JSON.stringify(formData),
			};
			await saveEvent(event);
		} catch (error) {
			console.error(error);
		}
	};

	return {
		activeAgent,
		name,
		email,
		phone,
		postalCode,
		message,
		includeChat,
		setName,
		setEmail,
		setPhone,
		setPostalCode,
		setMessage,
		setIncludeChat,
		handleSubmit,
	};
};
