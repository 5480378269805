import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import { useDataContext } from "@/contexts/DataContext";
import useTranslate from "@/hooks/useTranslate";
import { AgentType } from "@/types";

export const ResetChat = () => {
	const __ = useTranslate();
	const { setShowEndConversationModal } = useChatContext();
	const { activeAgent, setMessages, resetSession } = useDataContext();
	const {
		initialAssistantMessage,
		setShowEndConversationThankYouMessage,
		setIsDisabledInput,
		setLoading,
		setShowSuggestedPrompts,
		clientConfig,
	} = useAppContext();
	const handleClick = () => {
		if (activeAgent === AgentType.LiveChat) {
			setLoading(false);
			setShowEndConversationModal(true);
		} else {
			// For non-LiveChat agents, directly reset without showing modal
			resetSession();
			setLoading(false);

			if (clientConfig?.showInitialMessage) {
				setMessages([initialAssistantMessage]);
			} else {
				setMessages([]);
			}
			setShowEndConversationThankYouMessage(false);
			setIsDisabledInput(false);
			setShowEndConversationModal(false);
			setShowSuggestedPrompts(true);
		}
	};

	return (
		<div className="flex flex-row items-center">
			<button
				className="text-sm sm:text-base shadow-none items-center inline-flex text-neutral-100 font-semibold rounded-md px-4 py-2 hover:bg-client-primary-hover focus:outline-none reset-button transition-colors duration-200"
				onClick={handleClick}
			>
				{activeAgent === AgentType.LiveChat ? __("endConversation") : __("newConversation")}
			</button>
		</div>
	);
};
