import { useAppContext } from "@/contexts/AppContext";
import { useResetChat } from "@/hooks/useResetChat";
import useTranslate from "@/hooks/useTranslate";

export const EndConversationThankYouMessage = () => {
	const { showEndConversationThankYouMessage } = useAppContext();
	const __ = useTranslate("Chat");
	const { handleCloseWidget, handleReset } = useResetChat();

	if (!showEndConversationThankYouMessage) return null;

	return (
		<div className="w-full relative flex flex-col rounded-lg p-3 py-6 border-2 border-neutral-200 bg-neutral-100 mt-6">
			<p className="text-sm text-neutral-800 font-bold text-center mt-2">{__("endConversationThankYouMessage")}</p>
			<p className="text-xs text-neutral-500 font-medium text-center mt-2">{__("startNewOrClose")}</p>
			<div className="flex space-x-3 mt-6">
				<button
					onClick={handleReset}
					className="w-1/2 px-4 py-2.5 bg-client-tertiary text-white font-semibold rounded-md flex items-center justify-center hover:opacity-80 transition-opacity duration-200"
				>
					{__("newConversation")}<span className="text-sm py-0.5 px-1.5 text-white/50 bg-white bg-opacity-10 rounded-md font-bold ml-1">KI</span>
				</button>
				<button className="w-1/2 px-4 py-2.5 bg-gray-300 font-semibold rounded-md transition-colors hover:bg-gray-200" onClick={handleCloseWidget}>
					{__("close")}
				</button>
			</div>
		</div>
	);
};
