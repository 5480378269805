import { useAppContext } from "@/contexts/AppContext";
import { useAgentAvailabilityTooltip } from "@/hooks/useAgentAvailabilityTooltip";

// Icons
import AiIcon from "@/components/icons/status/small/AiIcon";
import CloseIcon from "@/components/icons/status/small/CloseIcon";

export const AgentAvailabilityTooltip = () => {
	const { isVisible, closeTooltip } = useAgentAvailabilityTooltip();
	const { isAgentOnline, widgetAlignment } = useAppContext();

	if (!isVisible) return null;

	return (
		<div className={`availability-tooltip relative rounded-lg flex flex-row ${widgetAlignment === "left" ? "justify-start" : "justify-end"}`}>
			<div className="relative bg-white rounded-[10px] px-[16px] py-[10px] z-10 border border-gray-300 w-fit">
				<div className="flex justify-between items-center gap-[8px]">
					<h3 className="text-[14px] font-normal leading-[140%] text-client-text-primary-dark">
						{isAgentOnline ? (
							<div className="">
								<span className="text-client-primary text-[14px] font-semibold leading-[140%]">Kundeservice</span> og{" "}
								<span className="text-client-primary text-[14px] font-semibold leading-[140%]">Tore</span> <AiIcon className="inline" /> er pålogget og klar for å hjelpe deg!
							</div>
						) : (
							<div className="">
								<span className="text-client-primary text-[14px] font-semibold leading-[140%]">Tore</span> <AiIcon className="inline" /> er klar for å hjelpe deg!
							</div>
						)}
					</h3>
					<button onClick={closeTooltip} className="text-gray-500 hover:text-gray-700 shadow-none">
						<CloseIcon />
					</button>
				</div>
				<div className={`absolute w-2 h-2 bg-white border-r border-b border-gray-300 transform rotate-45 -bottom-1 ${widgetAlignment === "left" ? "left-4" : "right-4"}`}></div>
			</div>
		</div>
	);
};
