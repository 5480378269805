"use client";

import React, { useEffect, useState } from "react";

import { fetchImageUrlAction } from "@/actions/productActions";

interface DrupalImageProps {
	// One of these two props is required
	relationshipUrl?: string;
	directImageUrl?: string;
	alt: string;
	width?: number;
	height?: number;
	className?: string;
}

/**
 * A component that loads a Drupal image using server actions to avoid CORS issues
 * Can accept either a relationship URL (which will be processed server-side)
 * or a direct image URL that's already been processed
 */
const DrupalImage: React.FC<DrupalImageProps> = ({
	relationshipUrl,
	directImageUrl,
	alt,
	width = 300,
	height = 300,
	className = "",
}) => {
	const [imageUrl, setImageUrl] = useState<string | null>(directImageUrl ?? null);
	const [isLoading, setIsLoading] = useState(!directImageUrl);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (directImageUrl) {
			setImageUrl(directImageUrl);
			setIsLoading(false);
			return;
		}

		if (!relationshipUrl) {
			setError("No image source provided");
			setIsLoading(false);
			return;
		}

		const loadImage = async () => {
			try {
				setIsLoading(true);
				const url = await fetchImageUrlAction(relationshipUrl);
				setImageUrl(url);
			} catch (err) {
				console.error("Error loading image:", err);
				setError("Failed to load image");
			} finally {
				setIsLoading(false);
			}
		};

		loadImage();
	}, [relationshipUrl, directImageUrl]);

	if (isLoading) {
		return <div className="animate-pulse bg-gray-200" style={{ width, height }}></div>;
	}

	if (error || !imageUrl) {
		return (
			<div className="bg-gray-100 flex items-center justify-center" style={{ width, height }}>
				<span className="text-gray-500 text-sm">Image unavailable</span>
			</div>
		);
	}

	return <img src={imageUrl} alt={alt} width={width} height={height} className={className} />;
};

export default DrupalImage;
