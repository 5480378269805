"use client";

import * as React from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import { AgentType, Customer, Message, MessageType, Role } from "@/types";
import { HandoverState } from "@/types/HandoverState";
import { saveEvent } from "@/utils/saveEvent";
import { saveMessageInDB } from "@/utils/saveMessageInDB";

interface LangflowContextType {
	sendMessageToLangflow: (message: Message) => Promise<void>;
}

const LangflowContext = createContext<LangflowContextType | undefined>(undefined);

export const LangflowProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { isAgentOnline, setLoading, appUrl, demoMode, initialAssistantMessage, setIsDisabledInput } = useAppContext();
	const { sessionId, handoverState, messages, setActiveAgent, setCustomer, setMessages } = useDataContext();
	const [saveMessageToLangflow, setSaveMessageToLangflow] = useState(!demoMode);

	useEffect(() => {
		setSaveMessageToLangflow(!demoMode);
	}, [demoMode]);
	const sendMessageToLangflow = useCallback(
		async (message: Message) => {
			// Get fresh context values directly from DataContext
			const currentMessages = messages; // This will be the current value
			const currentSessionId = sessionId;

			if (handoverState === HandoverState.Offline) {
				console.warn("Cannot send messages while offline handover is active.");
				return;
			}

			// Remove 'seen' property if it exists
			const { seen, ...messageWithoutSeen } = message;

			// Use current messages instead of closure-captured messages
			const updatedMessages = [...currentMessages, messageWithoutSeen];

			setLoading(true);
			setMessages((prev) => [...prev, messageWithoutSeen]);

			try {
				const domain = process?.env?.NEXT_PUBLIC_DOMAIN ?? appUrl ?? "";
				let apiEndpoint = `${domain}/api/chat/langflow`;
				let requestBody: any = {
					messages: updatedMessages.map((message) => ({
						role: message.role,
						content: message.content,
					})),
					sessionId: currentSessionId,
					isAgentOnline,
					saveMessageToLangflow,
				};
				// if the user sends a message save initial message before sending
				if (messages.length === 1) {
					await saveMessageInDB({
						message: initialAssistantMessage,
						sessionId,
						type: MessageType.message,
					});
				}
				// Send request
				const chatResponse = await fetch(apiEndpoint, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(requestBody),
				});

				if (!chatResponse.ok) {
					const errorText = await chatResponse.text();
					throw new Error(errorText);
				}

				// Extract the response text from the JSON body
				const data = await chatResponse.json();
				const responseText = data.response;

				if (responseText) {
					const assistantMessage: Message = {
						role: Role.assistant,
						content: responseText,
						timestamp: new Date(),
						messageType: MessageType.message,
						agentType: AgentType.AI,
					};

					setMessages((prev) => [...prev, assistantMessage]);
				} else {
					// If there's no valid response, log error and display an error message
					console.error("No valid response from the chat API:", data);
					const errorMessage: Message = {
						role: Role.assistant,
						content: "Beklager, noe har gått alvorlig galt 💀",
						agentType: AgentType.AI,
						messageType: MessageType.message,
						timestamp: new Date(),
					};

					setMessages((prev) => [...prev, errorMessage]);
				}

				if (data.handover) {
					if (isAgentOnline) {
						setActiveAgent(AgentType.LiveChat);
					} else {
						setActiveAgent(AgentType.ContactForm);
						setIsDisabledInput(true);
					}

					const event = {
						event: "handover",
						eventData: JSON.stringify({ response: responseText, ...data.toolInput, isAgentOnline }),
					};
					await saveEvent(event);
				}
				if (data.toolInput) {
					const customer: Customer = {
						name: data.toolInput.name,
						email: data.toolInput.email,
					};
					setCustomer(customer);
				}
			} catch (error: any) {
				if (error.name === "AbortError") {
					console.log("Request was aborted");
				} else {
					const errorMessage: Message = {
						role: Role.assistant,
						content: "Beklager, noe har gått alvorlig galt 💀",
						agentType: AgentType.AI,
						messageType: MessageType.message,
						timestamp: new Date(),
					};
					await saveMessageInDB({
						message: errorMessage,
						sessionId,
						type: MessageType.message,
					}).then(() => {
						setMessages((prev) => [...prev, errorMessage]);
					});
				}
			} finally {
				setLoading(false);
			}
		},
		[
			messages,
			sessionId,
			handoverState,
			isAgentOnline,
			appUrl,
			saveMessageToLangflow,
			initialAssistantMessage,
			setLoading,
			setMessages,
			setActiveAgent,
			setCustomer,
			setIsDisabledInput,
		],
	);

	const value = useMemo(
		() => ({
			sendMessageToLangflow,
		}),
		[sendMessageToLangflow],
	);

	return <LangflowContext.Provider value={value}>{children}</LangflowContext.Provider>;
};

export const useLangflow = () => {
	return useContext(LangflowContext);
};
