"use client";

import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import { AgentType, User } from "@/types";

export const useAgent = () => {
	const [agentDetails, setAgentDetails] = useState<User>({
		id: "",
		type: "",
		name: "",
		avatar: "",
	});
	const [activeAgent, setActiveAgent] = useState<AgentType>((Cookies.get("agent") as AgentType) ?? AgentType.AI);

	useEffect(() => {
		if (activeAgent) {
			Cookies.set("agent", activeAgent);
		}
		const agentCookie = Cookies.get("agent");
		if (agentCookie) {
			setActiveAgent(agentCookie as AgentType);
		}
	}, [activeAgent]);

	return {
		agentDetails,
		setAgentDetails,
		activeAgent,
		setActiveAgent,
	};
};
