{
	"Dashboard": {
		"sign-in": "Logg inn",
		"access-dashboard": "Få tilgang til dashbordet",
		"username": "Brukernavn",
		"password": "Passord",
		"signing-in": "Logger inn...",
		"invalid-credentials": "Ugyldig brukernavn eller passord",
		"error-occurred": "Det oppstod en feil. Vennligst prøv igjen.",
		"contact-help": "Kontakt din kundekontakt for å få eller endre brukernavn og passord, eller for å rapportere misbruk.",
		"home": {
			"banner-title": "Viktig informasjon",
			"banner-description": "Denne siden og produktet eies av Frontkom AS og er konfidensielt. Det skal ikke deles videre.",
			"title": "Hei! 👋",
			"subtitle": "Velkommen til Frontkom sin Tjenesteplattform for AI! Naviger til seksjonene ved å bruke sidepanelet til venstre.",
			"latest-update": "Siste plattformoppdatering",
			"view-changelog": "Se alle oppdateringer",
			"see-whats-new": "Se hva som er nytt",
			"see-all-updates": "Se alle oppdateringer",
			"quick-actions": "Hurtighandlinger",
			"days-ago": "{days} dager siden",
			"yesterday": "i går",
			"today": "i dag",
			"update-title": "🌟 Større grensesnittoppdatering",
			"update-date": "Oppdatert {date}",
			"update-count": "{count} oppdateringer",
			"entries": "endringer",
			"updates-this-month": "oppdateringer denne måneden",
			"key-features": "Snarveier",
			"documentation": "Dokumentasjon og ressurser",
			"documentation-description": "Finn guider, veiledninger og beste praksis for å bruke plattformen vår.",
			"view-docs": "Se dokumentasjon",
			"feature-analytics": "Analyse",
			"feature-analytics-desc": "Analyser trender og vis ytelsesmetrikk",
			"feature-playground": "Sandkasse",
			"feature-playground-desc": "Test agenten uten å lagre meldinger",
			"feature-knowledge": "Kunnskapsbase",
			"feature-knowledge-desc": "Administrer kunnskap og instruksjoner",
			"feature-activity": "Aktivitet",
			"feature-activity-desc": "Se samtalehistorikk og brukerinteraksjoner",
			"coming-soon": "Kommer snart",
			"service-status": "Tjenestestatus",
			"ai-service": "AI Tjeneste",
			"status-operational": "Alle systemer er operative",
			"status-degraded": "Ytelsesproblemer oppdaget",
			"status-outage": "Tjenesten er utilgjengelig",
			"status-checking": "Sjekker status...",
			"status-operational-desc": "Tjenesten kjører optimalt uten registrerte problemer.",
			"status-degraded-desc": "Tjenesten opplever noen ytelsesproblemer, men er fortsatt tilgjengelig.",
			"status-outage-desc": "Tjenesten er for øyeblikket utilgjengelig. Vi jobber med å løse dette.",
			"status-checking-desc": "Sjekker tjenestestatus...",
			"last-checked": "Sist sjekket",
			"service-warning": "Vi har oppdaget et problem med vår AI-tjeneste og jobber med å løse det. Ved nedetid anbefaler vi å midlertidig deaktivere/fjerne widgeten fra din side for å unngå å skade brukeropplevelsen for dine kunder.",
			"service-alert-title": "Tjenesteavbrudd",
			"service-alert": "Vi har oppdaget nedetid med vår AI-tjeneste og jobber aktivt med å løse problemet. Ved nedetid anbefaler vi å midlertidig deaktivere/fjerne widgeten fra din side for å unngå å skade brukeropplevelsen for dine kunder.",
			"service-alert-simplified": "Vi har oppdaget nedetid med vår AI-tjeneste og jobber aktivt med å løse problemet.",
			"astra-serverless-db": "Database",
			"astra-db-alert-outage": "Databasen opplever for øyeblikket nedetid. Vi jobber aktivt med en løsning.",
			"astra-db-alert-degraded": "Databasen opplever for øyeblikket ytelsesproblemer.",
			"astra-warning": "Vi har oppdaget et problem med databasetjenesten.",
			"view-status-page": "Se statusside",
			"uptime": "Oppetid",
			"db-alert-outage": "Databasen opplever for øyeblikket nedetid. Vi jobber aktivt med en løsning.",
			"db-alert-degraded": "Databasen opplever for øyeblikket ytelsesproblemer.",
			"database": "Database",
			"sign-in": "Logg inn",
			"access-dashboard": "Få tilgang til dashbordet",
			"username": "Brukernavn",
			"password": "Passord",
			"contact-help": "Kontakt din kundekontakt for å få eller endre brukernavn og passord, eller for å rapportere misbruk.",
			"signing-in": "Logger inn..."
		},
		"Positive": "Positiv",
		"Negative": "Negativ",
		"Neutral": "Nøytral",
		"positive": "Positiv",
		"negative": "Negativ",
		"neutral": "Nøytral",
		"today": "I dag",
		"playground": "Sandkasse",
		"expand-content": "Utvid innhold",
		"collapse-content": "Skjul innhold",
		"playground-settings": "Sandkasse-innstillinger",
		"developer-settings": "Utviklerinnstillinger",
		"ai-provider": "AI-leverandør",
		"test-and-experiment-with-your-chatbot-conversations-are-not-stored": "Test og eksperimenter med chatboten din. Samtaler lagres ikke.",
		"sources": "Kilder",
		"knowledge": "Kunnskap",
		"instructions": "Instruksjoner",
		"integrations": "Integrasjoner",
		"this-is-the-instructions-section-content-is-coming": "Dette er instruksjonsseksjonen. Innhold kommer.",
		"activity": "Aktivitet",
		"refresh": "Oppdater",
		"delete-history": "Slett Historikk",
		"conversations": "Samtaler",
		"messages": "Meldinger",
		"avg-msg": "Gj.snitt meldinger",
		"positive-percent": "Positiv %",
		"neutral-percent": "Nøytral %",
		"negative-percent": "Negativ %",
		"chats": "Chatter",
		"no-data-available": "Ingen data tilgjengelig",
		"export-csv": "Eksporter CSV",
		"date": "Dato",
		"first-message": "Første melding",
		"postal-code": "Postnummer",
		"municipality": "Kommune",
		"sentiment": "Stemning",
		"topics": "Temaer",
		"result": "Resultat",
		"status": "Status",
		"status-not-reviewed": "Ikke behandlet",
		"status-in-progress": "Under behandling",
		"status-reviewed": "Behandlet",
		"analytics": "Analyse",
		"postal-numbers": "Lokasjon",
		"alpha-version-limitations-and-disclaimers": "Alpha-versjon – Begrensninger og forbehold",
		"the-dashboard": "Dashboardet",
		"this-is-the-first-pre-alpha-version-of-the-dashboard-and-contains-many-errors": "Dette er den første pre-alpha-versjonen av dashboardet og inneholder feil.",
		"this-is-an-early-version-meant-for-testing-and-development": "Dette er en tidlig versjon som er ment for testing og utvikling.",
		"manage-your-agents-training-data": "Administrer treningsdataene til agentene dine.",
		"close-chat-history": "Lukk chatthistorikken",
		"conversation-history": "Samtalelogg",
		"timestamp": "Tidsstempel",
		"no-messages-available": "Ingen meldinger tilgjengelig.",
		"select-date-range": "Velg datoperiode",
		"start-date": "Startdato",
		"end-date": "Sluttdato",
		"cancel": "Avbryt",
		"apply": "Bruk",
		"line-chart": "Linjediagram",
		"pie-chart": "Sektordiagram",
		"total-topics": "Totalt antall temaer",
		"others": "Andre",
		"loading": "Laster...",
		"show-more": "Se mer...",
		"page-not-found": "Siden ble ikke funnet.",
		"sorry-this-page-is-only-accessible-on-desktop": "Beklager, denne siden er kun tilgjengelig på desktop.",
		"try-resizing-the-window-or-opening-the-page-on-your-computer": "Prøv å endre størrelsen på vinduet eller åpne siden på datamaskinen din.",
		"compared-to-last-years": "I forhold til siste {years} årene",
		"compared-to-same-period-years-earlier": "i forhold til samme periode {years} år tidligere",
		"compared-to-last-year": "i forhold til i fjor",
		"compared-to-same-period-earlier": "i forhold tilsamme periode tidligere",
		"compared-to-last-days": "I forhold til siste {days}d",
		"compared-to-same-period-days-earlier": "i forhold tilsamme periode {days}d tidligere",
		"compared-to-yesterday": "i forhold til i går",
		"compared-to-previous-day": "i forhold tilforrige dag",
		"compared-to-current-period": "i forhold tilnåværende periode",
		"total-view-title": "Total {viewTitle}",
		"view": "Vis",
		"compare": "Sammenlign",
		"last-week": "Forrige uke",
		"last-month": "Forrige måned",
		"last-6-months": "Siste 6 måneder",
		"last-12-months": "Siste 12 måneder",
		"year-to-date": "År til dato",
		"all-time": "Hele tiden",
		"message": "Melding...",
		"type-your-message-here": "Skriv meldingen din her...",
		"search": "Søk",
		"search-knowledge": "Søk i kunnskapsbasen",
		"search-by-id": "Søk etter ID",
		"finish-editing-the-current-record-before-adding-a-new-one": "Fullfør redigeringen av den nåværende posten før du legger til en ny.",
		"add-a-new-record": "Legg til kunnskap",
		"add-more": "Legg til",
		"enter-content-here": "Skriv inn spørsmål her...",
		"empty-document-placeholder": "Begynn å skrive eller lim inn innholdet ditt her...",
		"new-record": "Ny kunnskap",
		"add-record": "Legg til kunnskap",
		"are-you-sure-you-want-to-delete-this-record": "Er du sikker på at du vil slette denne posten?",
		"visible-to-chatbot": "Synlig for chatbot",
		"limited-visibility-to-chatbot": "Begrenset synlighet for chatbot",
		"save-changes": "Lagre endringer",
		"edit": "Rediger",
		"delete": "Slett",
		"finish-editing-the-current-record-before-editing-another": "Fullfør redigeringen av den nåværende posten før du redigerer en annen.",
		"edit-this-record": "Rediger denne posten",
		"cannot-delete-while-editing": "Kan ikke slette mens du redigerer.",
		"delete-this-record": "Slett denne posten",
		"no-records-found": "Ingen poster funnet.",
		"live-chat": {
			"title": "LiveChat",
			"description": "LiveChat-funksjonen er ikke aktivert i denne versjonen."
		},
		"contact-form": {
			"title": "Kontaktskjema",
			"description": "Kontaktskjemaet er ikke funksjonelt."
		},
		"tone-and-format": {
			"title": "Tone og format",
			"description": "Svarenes tone og format vil justeres og forbedres løpende basert på deres feedback."
		},
		"limited-training-data": {
			"title": "Begrenset treningsdata",
			"description": "Agenten bruker begrenset treningsdata, noe som kan føre til feil på områder den ikke er trent opp på."
		},
		"performance": {
			"title": "Ytelse",
			"description": "Versjonen kan være treg i enkelte områder, men dette vil bli optimalisert i en produksjonsversjon."
		},
		"security": {
			"title": "Sikkerhet",
			"description": "Sikkerhetstiltak er ikke implementert i denne alphaversjonen."
		},
		"products": "Produkter",
		"events": "Hendelser",
		"customer-support": "Kundeservice",
		"total-records": "Antall dokumenter",
		"filter": "Filter",
		"clear-filter": "Fjern filter",
		"scroll-for-more": "Rull for mer",
		"list-view": "Listevisning",
		"grid-view": "Rutenettvisning",
		"showing-top-search-results": "Viser {count} beste resultater ved søk",
		"productImpressions": "Produktvisninger",
		"productDetailViews": "Produktdetaljvisninger",
		"productClick": "Produktklikk",
		"addToCart": "Legg til i handlekurv",
		"viewCart": "Åpne handlekurv",
		"removeFromCart": "Fjern fra handlekurv",
		"checkout": "Til kassen",
		"purchase": "Kjøp",
		"handover": "Overføring",
		"customerSupport": "Kundeservice",
		"contactPresented": "Kontaktskjema vist",
		"contactSubmitted": "Kontaktskjema sendt inn",
		"selected": "valgt",
		"endConversation": "Avslutt samtale",
		"newConversation": "Ny samtale",
		"endConversationModalText": "Er du sikker på at du vil avslutte samtalen?",
		"confirm": "Avslutt samtale",
		"continue": "Fortsett samtale",
		"talkToHuman": "Snakk med et menneske",
		"contactUs": "Kontakt oss",
		"thankYouForYourMessage": "Takk for din melding! Vi vil kontakte deg snart.",
		"close": "Lukk",
		"copy-session-url": "Kopier samtale-URL",
		"close-chat-history-modal": "Lukk samtalehistorikk",
		"instruction": "Instruksjon",
		"edit-instruction": "Rediger instruksjon",
		"version-history": "Versjonshistorikk",
		"back": "Tilbake",
		"active": "Aktiv",
		"reverted": "Tilbakestilt",
		"revert": "Tilbakestill",
		"save": "Lagre",
		"history": "Historikk",
		"last-updated": "Sist oppdatert",
		"words": "ord",
		"tokens": "tokens",
		"word-token-count": "{words} ord / {tokens} tokens",
		"no-instruction-click-edit": "Ingen instruksjon funnet. Klikk rediger for å legge til.",
		"confirm-password": "Bekreft passord",
		"enter-password-to-edit": "Vennligst skriv inn passordet for å redigere instruksjonen",
		"enter-password-to-revert": "Vennligst skriv inn passordet for å gå tilbake til denne versjonen",
		"confirm-button": "Bekreft",
		"confirm-action": "Bekreft handling",
		"confirm-generic": "Bekreft",
		"invalid-password": "Feil passord",
		"token-tooltip": "Tokens er enhetene som språkmodellen bruker til å behandle tekst - de kan være deler av ord, tegnsetting eller mellomrom. Antall tokens bestemmer API-kostnaden.",
		"set-status": "Sett status",
		"flere": "Flere statuser",
		"changelog": {
			"title": "Endringslogg",
			"subtitle": "Plattformoppdateringer",
			"description": "Følg alle forbedringer, nye funksjoner og feilrettinger i plattformen vår. Hold deg oppdatert på de nyeste endringene.",
			"legend": {
				"title": "Tegnforklaring",
				"feature": "Funksjon",
				"improvement": "Forbedring",
				"fix": "Feilretting",
				"security": "Sikkerhet",
				"deprecated": "Utfaset"
			}
		},
		"releases": "Utgivelser",
		"roadmap": "Veikart",
		"coming-soon": "Kommer snart",
		"roadmap-description": "Våre fremtidige utviklingsplaner vil bli publisert her. Sjekk tilbake for oppdateringer om kommende funksjoner og forbedringer.",
		"missing-something": "Har du en god idé?",
		"feature-request-prompt": "Kontakt din kundeansvarlig for å foreslå forbedringer til plattformen vår.",
		"request-feature": "Etterspør en funksjon",
		"found-a-bug": "Funnet en feil?",
		"report-bug": "Rapporter en feil",
		"need-help": "Trenger du hjelp til å komme i gang?",
		"view-examples": "Se eksempler på spørsmål",
		"check-back-later": "Vennligst sjekk tilbake senere for oppdateringer",
		"collapse": "Skjul",
		"expand": "Vis mer",
		"editing-document": "Redigerer dokument",
		"creating-new-document": "Oppretter nytt dokument",
		"draft-found-confirmation": "Et utkast fra {time} den {date} ble funnet. Vil du bruke det?",
		"auto-save": "Auto-lagring",
		"save-draft": "Lagre utkast",
		"draft-saved": "Utkast lagret",
		"draft-restored": "Utkast gjenopprettet",
		"unsaved-changes": "Ulagrede endringer",
		"last-saved": "Sist lagret: {time}",
		"discard-changes": "Forkast endringer",
		"editor-exit-confirmation-text": "Du har ulagrede endringer. Er du sikker på at du vil avslutte?",
		"error-loading-session": "Feil ved lasting av samtale",
		"session-not-found": "Samtale ikke funnet",
		"no-session-selected": "Ingen samtale valgt"
	},
	"Chat": {
		"initialMessage": "Hei! Jeg er **Tore** og er her for å svare på spørsmål, gi råd og veiledning, og ikke minst hjelpe deg med å finne produktene som passer dine behov. Hvordan kan jeg hjelpe deg i dag?",
		"errorMessage": "Beklager, noe har gått alvorlig galt \uD83D\uDC80",
		"newConversation": "Ny samtale",
		"close": "Lukk",
		"endConversationThankYouMessage": "Takk for at du kontaktet kundeservice!",
		"hasJoinedConversation": "har blitt med i samtalen",
		"send": "Send",
		"contactFormMessage": "Vi er dessverre ikke tilgjengelig nå. Legg igjen en beskjed, så kontakter vi deg så fort som mulig!",
		"contactFormFooterMessage": "Del meldingsloggen med oss",
		"message": "Melding",
		"postalCode": "Postnummer",
		"telephone": "Telefon",
		"email": "E-post",
		"name": "Navn",
		"removeAttachedFile": "Fjern vedlagt fil",
		"chatFooterText": "Ikke del sensitiv data i chat.",
		"privacyPolicy": "Personvernserklæring",
		"yourMessageHasNotBeenSent": "Meldingen din er ikke sendt",
		"aLiveAgentHasJoinedTheConversation": "En live agent har blitt med i samtalen.",
		"thankYouForYourMessage": "Takk for din melding! Vi vil kontakte deg snart.",
		"chatSessionHasBeenArchived": "Samtalen er avsluttet",
		"yourChatHasBeenArchived": "Chatten din har blitt arkivert og inaktiv.",
		"leftTheConversation": "forlot samtalen",
		"messageReceived": "Din melding er motatt!",
		"willContactSoon": "Vi kontakter deg så kjappt som mulig.",
		"startNewOrClose": "Start en ny samtale eller lukk vinduet",
		"infoMessage": "KI agent"
	},
	"login": {
		"sign-in": "Logg inn",
		"access-dashboard": "Logg inn for å få tilgang til dashbordet",
		"username": "Brukernavn",
		"password": "Passord",
		"signing-in": "Logger inn...",
		"invalid-credentials": "Ugyldig brukernavn eller passord",
		"wrong-credentials": "Feil brukernavn eller passord",
		"error-occurred": "Det oppstod en feil. Vennligst prøv igjen.",
		"contact-help": "Kontakt din kundekontakt for å få eller endre brukernavn og passord, eller for å rapportere misbruk."
	}
}
