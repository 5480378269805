import React from 'react';

export default function CloseIcon(): React.ReactElement {
  return (
    <>
      <svg 
        width="16" 
        height="16" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.5 4.83331L4.5 11.8333" stroke="#3F3F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.5 4.83331L11.5 11.8333" stroke="#3F3F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </>
  );
}