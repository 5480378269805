import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import { getImageUrl } from "@/utils/widget/getImageUrl";

export const HeaderAvatar = () => {
	const { agentDetails } = useDataContext();
	const { clientConfig } = useAppContext();

	const avatarUrl =
		agentDetails?.avatar && agentDetails?.avatar !== ""
			? agentDetails?.avatar
			: getImageUrl(clientConfig?.headerAvatarUrl);

	return (
		<>
			<div className="widget-header-avatar-status outline-client-primary group-hover:outline-client-primary-hover bg-client-status"></div>
			<img src={avatarUrl} alt="Avatar" className="rounded-full w-9 h-9" width={36} height={36} />
		</>
	);
};
