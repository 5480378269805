"use client";

import { useEffect, useLayoutEffect, useRef } from "react";

import { ChatMessage } from "@/components/Chat/ChatMessage";
import { ThankYouMessage } from "@/components/Chat/ThankYouMessage";
import { MessageLoader } from "@/components/atoms/MessageLoader";
import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import { Role } from "@/types";
import { scrollToBottom, scrollToElement } from "@/utils/scrollUtils";
import { isFirstInSequence, isLastInSequence } from "@/utils/widget/messagesUtils";

export const MessagesList = () => {
	const { isUploadingFile } = useAppContext();
	const { messages } = useDataContext();

	// Reference for the last (newest) message
	const lastMessageRef = useRef<HTMLDivElement>(null);
	// Reference for the messages container
	const messagesEndRef = useRef<HTMLDivElement>(null);
	// Track if this is the initial render
	const isInitialRender = useRef(true);

	// Find the index of the latest user message
	const latestUserMessageIndex = messages
		.map((msg, idx) => ({ msg, idx }))
		.filter(({ msg }) => msg.role === Role.human)
		.pop()?.idx;

	// Use useLayoutEffect to ensure scroll happens before browser paint
	useLayoutEffect(() => {
		if (isInitialRender.current) {
			// On initial render, scroll to bottom immediately without animation
			if (messagesEndRef.current) {
				scrollToElement(messagesEndRef.current, { behavior: "auto", block: "end" });
			}
			isInitialRender.current = false;
		} else if (lastMessageRef.current) {
			// For subsequent message changes, smooth scroll to the last message
			scrollToElement(lastMessageRef.current, { behavior: "smooth", block: "start" });
		}
	}, [messages]);

	// Ensure parent container is scrolled to bottom on mount
	useEffect(() => {
		// Find the parent scrollable container
		const scrollableParent = messagesEndRef.current?.closest(".chat-messages-container");
		if (scrollableParent) {
			scrollToBottom(scrollableParent as HTMLElement);
		}
	}, [isUploadingFile]);

	return (
		<>
			{messages.map((message, index) => {
				const isLastMessage = index === messages.length - 1;
				const isLatestUserMessage = index === latestUserMessageIndex;
				const firstInSequence = isFirstInSequence(index, messages);
				const lastInSequence = isLastInSequence(index, messages);

				return (
					<div
						key={index}
						ref={isLastMessage ? lastMessageRef : null}
						className={`my-1 sm:my-1.5 ${message.role === "system" ? "system-message-container" : ""}`}
						data-testid={isLastMessage ? "last-message" : undefined}
					>
						{message.role !== "system" ? (
							// If ChatMessage is frequently re-rendered, consider using React.memo
							<ChatMessage
								message={message}
								isFirstInSequence={firstInSequence}
								isLatestUserMessage={isLatestUserMessage}
								messagesEndRef={messagesEndRef}
							/>
						) : message.messageType === "info" ? (
							<ThankYouMessage />
						) : (
							<>
								<div className="before-system-message bg-neutral-200"></div>
								<div className="text-center text-gray-500 message-nowrap">{message.content}</div>
								<div className="after-system-message bg-neutral-200"></div>
							</>
						)}
					</div>
				);
			})}
			{isUploadingFile && <MessageLoader />}
			{/* Invisible div at the end to scroll to */}
			<div ref={messagesEndRef} data-testid="messages-end" />
		</>
	);
};
