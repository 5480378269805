import { useEffect, useState } from "react";

import { useDataContext } from "@/contexts/DataContext";
import { AgentType } from "@/types";

type WelcomeMessageProps = {
	title: string;
	icon?: React.ReactNode | React.ReactElement;
};

export const WelcomeMessage = ({ title, icon }: WelcomeMessageProps) => {
	const { activeAgent, messages } = useDataContext();
	const [shouldHidePrompts, setShouldHidePrompts] = useState(false);
	const [isPromptClicked, setIsPromptClicked] = useState<boolean>(false);

	useEffect(() => {
		setShouldHidePrompts(messages.length > 0);
		setIsPromptClicked(false);
	}, [messages]);

	if (isPromptClicked || shouldHidePrompts || activeAgent === AgentType.ContactForm) return null;

	return (
		<div className="text-center mb-3">
			<h2 className="font-bold text-client-xl text-client-text-secondary flex justify-center">
				{icon && <span className="mr-2">{icon}</span>}
				{title}
			</h2>
		</div>
	);
};
