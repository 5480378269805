import { ReactNode } from "react";

import { useAppContext } from "@/contexts/AppContext";
import { ChatbaseProvider } from "@/services/chatbase/Chatbase";
import { LangflowProvider } from "@/services/langflow/Langflow";
import { LiveChatProvider } from "@/services/liveChat/LiveChat";
import { hasLiveChatConfig } from "@/services/liveChat/utils/hasLiveChatConfig";
import { AIIntegration } from "@/types";

interface ContextProps {
	children: ReactNode;
}
const HumanProvider = ({ children }: { children: ReactNode }) => {
	if (!hasLiveChatConfig()) return children;
	return <LiveChatProvider>{children}</LiveChatProvider>;
};
export const IntegrationContexts = ({ children }: ContextProps) => {
	const { aiProvider } = useAppContext();
	const AIProvider = aiProvider === AIIntegration.Chatbase ? ChatbaseProvider : LangflowProvider;

	return (
		<AIProvider>
			<HumanProvider>{children}</HumanProvider>
		</AIProvider>
	);
};
