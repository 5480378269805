import useTranslate from "@/hooks/useTranslate";

export const MessageInfo = () => {
	const __ = useTranslate("Chat");

	return (
		<div className="flex flex-row justify-center align-middle mb-4 sm:mb-4 space-x-2">
			<p className="text-neutral-300 text-sm">{__("infoMessage")}</p>
		</div>
	);
};
