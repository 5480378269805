import { PiPaperPlaneTiltFill } from "react-icons/pi";

import { useAppContext } from "@/contexts/AppContext";

export type SendButtonProps = {
	onClick: () => void;
};

export const SubmitButtonContent: React.FC<SendButtonProps> = () => {
	const { isDisabledInput } = useAppContext();
	return (
		<PiPaperPlaneTiltFill
			className={`h-8 w-8 rounded-md p-1 bg-client-primary text-white ${
				isDisabledInput
					? "cursor-not-allowed opacity-50"
					: "hover:cursor-pointer message-user text-white hover:opacity-80"
			}`}
		/>
	);
};
