"use client";

import React, { useEffect, useRef } from "react";

import { ChatLoader } from "@/components/Chat/ChatLoader";
import { EndConversationModal } from "@/components/Chat/EndConversationModal";
import { EndConversationThankYouMessage } from "@/components/Chat/EndConversationThankYouMessage";
import { LocationModal } from "@/components/Chat/Location";
import { ChatIcon } from "@/components/molecules/ChatIcon";
import { MessagesList } from "@/components/molecules/MessagesList";
import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import { useDataContext } from "@/contexts/DataContext";
import { AgentType } from "@/types";
import { resolveDynamicComponent } from "@/utils/componentResolver";
import { scrollToBottom } from "@/utils/scrollUtils";

const ChatHeader = () => {
	const Component = resolveDynamicComponent("molecules/ChatHeader");
	return Component ? <Component /> : null;
};

const ChatInput = () => {
	const Component = resolveDynamicComponent("Chat/ChatInput", "ChatInput");
	return Component ? <Component /> : null;
};

const ChatFooter = () => {
	const Component = resolveDynamicComponent("atoms/ChatFooter");
	return Component ? <Component /> : null;
};

const SuggestedPrompts = () => {
	const Component = resolveDynamicComponent("molecules/SuggestedPrompts", "SuggestedPrompts");
	return Component ? <Component /> : null;
};

const MessageInfo = () => {
	const Component = resolveDynamicComponent("atoms/MessageInfo", "MessageInfo");
	return Component ? <Component /> : null;
};

const ContactForm = () => {
	const Component = resolveDynamicComponent("Chat/ContactForm", "ContactForm");
	return Component ? <Component /> : null;
};

interface ChatWidgetProps {
	position?: "fixed" | "absolute" | "static";
}

export const ChatWidget: React.FC<ChatWidgetProps> = ({ position = "fixed" }) => {
	const { loading, showWidget, showEndConversationThankYouMessage, widgetAlignment } = useAppContext();
	const { activeAgent } = useDataContext();
	const { showEndConversationModal, showLocationModal } = useChatContext();
	const messagesContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (showEndConversationThankYouMessage && messagesContainerRef.current) {
			messagesContainerRef.current.scrollTo({
				top: messagesContainerRef.current.scrollHeight,
				behavior: "smooth",
			});
		}
	}, [showEndConversationThankYouMessage]);

	// Ensure scroll to bottom on initial render, page refresh, and when widget becomes visible
	useEffect(() => {
		if (showWidget) {
			// Use requestAnimationFrame to ensure the DOM has fully rendered
			requestAnimationFrame(() => {
				scrollToBottom(messagesContainerRef.current);

				// Double-check scroll position after a short delay to ensure all content is rendered
				setTimeout(() => {
					scrollToBottom(messagesContainerRef.current);
				}, 100);
			});
		}
	}, [showWidget]);

	// Also scroll to bottom when loading state changes (new messages might be added)
	useEffect(() => {
		if (!loading) {
			requestAnimationFrame(() => {
				scrollToBottom(messagesContainerRef.current);
			});
		}
	}, [loading]);

	// Handle window resize to maintain scroll position
	useEffect(() => {
		const handleResize = () => {
			scrollToBottom(messagesContainerRef.current);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className={`${position} bottom-0 ${widgetAlignment === "left" ? "left-0" : "right-0"} z-50 frontkom-widget`}>
			{/* If widget is not visible, show an icon to open it */}
			{!showWidget && <ChatIcon position={position} />}

			{/* If widget is visible, show the chat window */}
			{showWidget && (
				<div
					className={`widget widget-container rounded-xl bg-client-secondary border-client-border drop-shadow-custom bottom-0 sm:bottom-8  ${position} ${widgetAlignment === "left" ? "left-0 sm:left-8" : "right-0 sm:right-8"}`}
				>
					<div className="widget-body relative h-full flex flex-col">
						<ChatHeader />
						{/* Messages */}
						<div
							ref={messagesContainerRef}
							className="chat-messages-container relative flex-1 overflow-y-auto px-4 py-2"
							data-testid="chat-messages-container"
						>
							<MessageInfo />
							<MessagesList />
							<EndConversationThankYouMessage />
							{/* Loading spinner while waiting for AI response */}
							{loading && (
								<div className="my-1 sm:my-1.5 relative">
									<ChatLoader />
								</div>
							)}
							<div className="absolute bottom-10 right-4">
								<SuggestedPrompts />
							</div>
							<ContactForm />
						</div>

						{/* Footer (Chat input) */}
						<div className="w-full px-4 pb-4">
							<ChatInput />
							<ChatFooter />
						</div>
						{activeAgent === AgentType.LiveChat && showEndConversationModal && <EndConversationModal />}
						{showLocationModal && <LocationModal />}
					</div>
				</div>
			)}
		</div>
	);
};
