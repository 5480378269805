import { LiveChatUserType } from "@/services/liveChat/LiveChat";

export interface AvailabilityUpdatedEvent {
	availability: "online" | "offline";
}

export interface TypingIndicatorPayload {
	typingIndicator: {
		isTyping: boolean;
	};
}

export type livechatUserInterface = {
	id: string;
	name: string;
	avatar: string;
	type: LiveChatUserType;
};

export enum LivechatErrorContext {
	INITIALIZATION_ERROR = "INITIALIZATION_ERROR",
	UPDATE_CUSTOMER_ERROR = "UPDATE_CUSTOMER_ERROR",
	GET_CHAT_ERROR = "GET_CHAT_ERROR",
	RESUME_CHAT_ERROR = "RESUME_CHAT_ERROR",
	START_CHAT_ERROR = "START_CHAT_ERROR",
	SEND_EVENT_ERROR = "SEND_EVENT_ERROR",
	UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR",
	CLOSE_CHAT_ERROR = "CLOSE_CHAT_ERROR",
	UPDATE_AGENT_ERROR = "UPDATE_AGENT_ERROR",
	SDK_ERROR = "SDK_ERROR",
	GENERAL_ERROR = "GENERAL_ERROR",
}
export type DisconnectedEvent = {
	reason: "network_error" | "invalid_token" | "session_expired" | "server_error" | string;
};

export type ChatEvent = {
	type: string;
	id?: string;
	authorId?: string;
	createdAt?: string;
	text?: string;
	title?: string;
	subtitle?: string;
	url?: string;
	imageUrl?: string;
	contentType?: string;
	name?: string;
	size?: number;
	width?: number;
	height?: number;
	duration?: number;
	recipients?: string;
	form_id?: string;
	fields?: any[];
	thumbnails?: {
		url: string;
		width: number;
		height: number;
	}[];
	properties?: Record<string, any>;
	customId?: string;
	postback?: {
		id: string;
		threadId: string;
		payload: string;
		value?: string;
		eventId?: string;
	};
	elements?: Array<{
		title: string;
		subtitle?: string;
		image?: {
			url: string;
			name?: string;
		};
		buttons?: Array<{
			type: "webview" | "message" | "url" | "phone" | "cancel";
			text: string;
			postback?: string;
			value?: string;
			webviewHeight?: "full" | "tall" | "compact";
			target?: "_blank" | "_self";
			url?: string;
		}>;
	}>;
	template?: {
		id: number;
		type: string;
	};
	quickReplies?: Array<{
		type: string;
		text: string;
		postback?: string;
		value?: string;
	}>;
	form?: {
		id: string;
		fields: Array<{
			type: string;
			id: string;
			label: string;
			required?: boolean;
			value?: string;
		}>;
	};
	options?: {
		[key: string]: any;
	};
};

export type ChatResponse = {
	id: string;
	active: boolean;
	users: Array<{
		id: string;
		type: string;
		name?: string;
		avatar?: string;
	}>;
	thread: {
		events: Array<{
			id: string;
			type: string;
			text?: string;
			createdAt: string;
			authorId: string;
			url?: string;
			contentType?: string;
			name?: string;
		}>;
	};
};

export type CustomerSDKType = Readonly<{
	acceptGreeting(params: { greetingId: number; uniqueId: string }): Promise<{ success: true }>;
	cancelGreeting(params: { uniqueId: string }): Promise<{ success: true }>;
	cancelRate(params: { chatId: string; properties: Array<"score" | "comment"> }): Promise<{ success: true }>;
	connect(): void;
	deactivateChat(params: { id: string }): Promise<{ success: true }>;
	deleteChatProperties(params: { id: string; properties: Record<string, string[]> }): Promise<{ success: true }>;
	deleteEventProperties(params: {
		chatId: string;
		threadId: string;
		eventId: string;
		properties: Record<string, string[]>;
	}): Promise<{ success: true }>;
	deleteThreadProperties(params: {
		chatId: string;
		threadId: string;
		properties: Record<string, string[]>;
	}): Promise<{ success: true }>;
	destroy(): void;
	disconnect(): void;
	on(event: string, handler: (payload: any) => void): void;
	off(event: string, handler?: (payload: any) => void): void;
	getPredictedAgent(): Promise<any>;
	getChat(params: { chatId: string | undefined }): Promise<ChatResponse>;
	listChats(params: {
		limit: number;
	}): Promise<{ chatsSummary: Array<{ id: string; active: boolean }>; totalChats: number }>;
	resumeChat(params: { chat: { id: string; thread: { events: ChatEvent[] } } }): Promise<{ id: string }>;
	startChat(params: { chat: { thread: { events: ChatEvent[] } } }): Promise<{ id: string }>;
	updateCustomer(data: { name?: string; email?: string }): Promise<void>;
	sendEvent(params: { chatId: string; event: { type: string; text?: string; url?: string } }): Promise<any>;
	uploadFile(params: { file: File; onProgress?: (progress: number) => void }): {
		promise: Promise<{
			url: string;
			name: string;
			type: string;
			contentType: string;
			createdAt: Date;
		}>;
		cancel: () => void;
	};
	isDestroyed?: boolean;
}>;
