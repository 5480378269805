import { getEnvAppUrl } from "@/utils/getEnvAppUrl";

export const saveEvent = async (event: { event: string; eventData: string }) => {
	try {
		const appUrl = getEnvAppUrl();
		const response = await fetch(`${appUrl}/api/event/saveEvent`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(event),
		});

		if (!response.ok) {
			throw new Error("Error while saving the event to database");
		}

		console.log(`Event "${event.event}" saved to database`);
	} catch (error) {
		console.error("Error during saving event:", error);
	}
};
