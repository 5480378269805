import React, { ChangeEvent, ClipboardEvent, KeyboardEvent, useEffect, useRef } from "react";

import { useAppContext } from "@/contexts/AppContext";
import useTranslate from "@/hooks/useTranslate";

export type TextareaProps = {
	content: string;
	onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
	onPaste: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
};

export const ChatTextarea: React.FC<TextareaProps> = ({ content, onChange, onKeyDown, onPaste }) => {
	const { isDisabledInput } = useAppContext();
	const textareaRef = useRef<HTMLTextAreaElement | null>(null);
	const __ = useTranslate();

	// Auto-resize textarea to match content height
	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = "inherit";
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	}, [content]);

	return (
		<textarea
			ref={textareaRef}
			className="min-h-[44px] max-h-[126px] rounded-md pl-4 py-2 pr-20 w-full text-black focus:outline-none focus:ring-1 focus:ring-neutral-300 border-2 border-neutral-200"
			style={{ resize: "none" }}
			placeholder={__("enter-content-here")}
			value={content}
			rows={1}
			onChange={onChange}
			onKeyDown={onKeyDown}
			onPaste={onPaste}
			disabled={isDisabledInput}
		/>
	);
};
