/**
 * Utility functions for scrolling behavior in the chat application
 */

/**
 * Scrolls a container element to the bottom
 * @param container The container element to scroll
 * @param smooth Whether to use smooth scrolling behavior
 */
export const scrollToBottom = (container: HTMLElement | null, smooth = false): void => {
  if (!container) return;
  
  container.scrollTop = container.scrollHeight;
};

/**
 * Scrolls to a specific element
 * @param element The element to scroll to
 * @param options Scrolling options
 */
export const scrollToElement = (
  element: HTMLElement | null, 
  options: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start' }
): void => {
  if (!element) return;
  
  element.scrollIntoView(options);
};

/**
 * Checks if a container is scrolled to the bottom
 * @param container The container element to check
 * @param threshold Threshold in pixels (allows for small differences)
 * @returns Whether the container is scrolled to the bottom
 */
export const isScrolledToBottom = (container: HTMLElement | null, threshold = 20): boolean => {
  if (!container) return false;
  
  const { scrollTop, scrollHeight, clientHeight } = container;
  return scrollHeight - scrollTop - clientHeight <= threshold;
}; 