import Cookies from "js-cookie";

import { fetchPostalCodeFromAPI } from "@/services/api";

export const verifyPostalCode = async () => {
	const storedPostalCode = Cookies.get("postalCode");
	const sessionId = Cookies.get("sessionId"); // Assuming sessionId is stored in cookies

	// If we already have a stored postal code but want to confirm with API, we can do that:
	// But if you're certain you do NOT need to re-verify, skip calling the API altogether.
	if (sessionId) {
		const apiPostalCode = await fetchPostalCodeFromAPI(sessionId);
		// If the API returns a code, and it's different, update cookies & local state
		if (apiPostalCode && apiPostalCode !== storedPostalCode) {
			Cookies.set("postalCode", apiPostalCode, {
				path: "/",
				secure: process.env.NODE_ENV === "production",
				sameSite: "Strict",
			});
			return apiPostalCode;
		} else if (storedPostalCode) {
			// If the cookie and API match, just use the cookie
			return storedPostalCode;
		}
	} else if (storedPostalCode) {
		// If no sessionId but have a stored postal code, just set it
		return storedPostalCode;
	}
};
