import { useState } from "react";
import { BiSolidFilePdf } from "react-icons/bi";
import { FaFileAlt } from "react-icons/fa";
import { RiFileVideoLine } from "react-icons/ri";

import Product from "@/components/Chat/Product";
import type { Message } from "@/types";
import { parseText } from "@/utils/widget/parseText";

export const useChatMessage = (message: Message) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const isImageUrl = (url: string) => {
		return /\.(jpg|jpeg|png|gif|webp)$/i.test(url);
	};

	const parseProductId = (content: string): string | null => {
		const productMatch = content.match(/<product>(.*?)<\/product>/);
		return productMatch ? productMatch[1].trim() : null;
	};

	const renderContent = () => {
		if (!message.content) return null;
		// Split the message content on product or table markers.
		const parts = message.content.split(/(<product>[\s\S]*?<\/product>|<table>[\s\S]*?<\/table>)/g);

		return (
			<div>
				{parts.map((part, index) => {
					if (part.includes("<product>")) {
						const productId = parseProductId(part);
						if (productId) {
							return (
								<div key={productId}>
									<Product id={productId} />
								</div>
							);
						} else {
							return <div key={index}>Invalid product data</div>;
						}
					}
					if (isImageUrl(part.trim())) {
						return (
							<div key={index}>
								<img src={part.trim() || "/placeholder.svg"} alt="Image" className="w-auto h-auto" />
							</div>
						);
					}
					return <div key={index} dangerouslySetInnerHTML={{ __html: parseText(part.trim()) }} />;
				})}
			</div>
		);
	};

	const renderAttachment = () => {
		const url = message.url ?? message.content;

		if (url) {
			const isImage: boolean =
				message.contentType === "image/jpeg" ||
				message.contentType === "image/png" ||
				message.contentType === "image/gif" ||
				message.contentType === "image/webp";

			let fileIcon: JSX.Element = <FaFileAlt className="h-10 w-10" />;
			const fileUrl: string = url;
			const fileName: string =
				message.fileName ?? `new media ${new Date().getDate()}-${new Date().getMonth()}-${new Date().getFullYear()}`;

			if (isImage) {
				// Image-specific handling if needed
			} else if (message.contentType === "application/pdf" || message.contentType === "application/octet-stream") {
				fileIcon = <BiSolidFilePdf className="h-10 w-10" />;
			} else if (message.contentType === "video/mp4") {
				fileIcon = <RiFileVideoLine className="h-10 w-10" />;
			}

			return (
				<div className="attachment my-2 flex items-end gap-2">
					{isImage ? (
						<>
							<img
								src={fileUrl || "/placeholder.svg"}
								alt={`${fileName} icon`}
								className="w-auto h-auto"
								onLoad={() => setIsLoaded(true)}
								style={{ display: isLoaded ? "block" : "none" }}
							/>
						</>
					) : (
						<>
							{fileIcon}
							<a
								href={fileUrl}
								target="_blank"
								rel="noopener noreferrer"
								download={fileUrl}
								className="hover:text-blue-500 hover:underline text-client-message-link"
							>
								{fileName}
							</a>
						</>
					)}
				</div>
			);
		}

		return "Laster opp...";
	};

	return {
		renderContent,
		renderAttachment,
		isImageUrl,
		parseProductId,
		isLoaded,
	};
};

export default useChatMessage;
