import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import { AgentType } from "@/types";
import { resolveDynamicComponent } from "@/utils/componentResolver";

export type SendButtonProps = {
	onClick: () => void;
};
const SubmitButtonContent = resolveDynamicComponent("atoms/SubmitButtonContent", "SubmitButtonContent");

export const ChatSubmitButton: React.FC<SendButtonProps> = ({ onClick }) => {
	const { isDisabledInput, loading } = useAppContext();
	const { activeAgent } = useDataContext();

	const isButtonDisabled = isDisabledInput || (loading && activeAgent !== AgentType.LiveChat);

	return (
		<button
			onClick={onClick}
			disabled={isButtonDisabled}
			aria-label="Send message"
			className="shadow-none bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent"
		>
			<SubmitButtonContent />
		</button>
	);
};
