import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { PiMapPinArea } from "react-icons/pi";

import { Location } from "@/components/Chat/Location";
import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import useOutsideClick from "@/hooks/useOutsideClick";
import { verifyPostalCode } from "@/utils/verifyPostalCode";

export const PostalCodeButton = () => {
	const { isDisabledInput } = useAppContext();
	const { setPostalCode, setIsEditing, postalCode } = useChatContext();

	const [isLocationOpen, setIsLocationOpen] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);

	const locationRef = useRef<HTMLDivElement | null>(null); // Ref for Location component
	// Ref to ensure postal code is fetched only once
	const hasFetchedPostalCodeRef = useRef(false);

	useEffect(() => {
		const savedPostal = Cookies.get("postalCode") ?? "";
		setPostalCode(savedPostal);

		if (hasFetchedPostalCodeRef.current) return;
		hasFetchedPostalCodeRef.current = true;

		verifyPostalCode().then((storedPostalCode) => {
			setPostalCode(storedPostalCode);
		});
	}, [setPostalCode]);

	const handleClickOutside = (event: MouseEvent) => {
		if (locationRef.current && !locationRef.current.contains(event.target as Node)) {
			setIsLocationOpen(false);
		}
	};

	useEffect(() => {
		if (isLocationOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isLocationOpen]);

	const toggleLocation = () => {
		setIsLocationOpen(!isLocationOpen);
		if (!isLocationOpen) {
			setShowTooltip(false); // Start editing postal code when opening
		}
	};

	useOutsideClick(locationRef, toggleLocation, isLocationOpen);

	const onPostalCodeUpdate = (newPostalCode: string) => {
		setPostalCode(newPostalCode);
		if (newPostalCode.length === 4 && !isNaN(Number(newPostalCode))) {
			setPostalCode(newPostalCode);
			setIsEditing(false);
		} else {
			alert("Vennligst oppgi et gyldig postnummer (4 siffer).");
		}
		setIsLocationOpen(false);
	};

	return (
		<div
			ref={locationRef}
			className="relative flex items-center"
			onMouseEnter={() => {
				if (!isLocationOpen) setShowTooltip(true);
			}}
			onMouseLeave={() => setShowTooltip(false)}
		>
			<button
				onClick={toggleLocation}
				disabled={isDisabledInput}
				aria-label="Set location"
				className="shadow-none bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent"
			>
				<PiMapPinArea
					className={`text-neutral-400 h-8 w-8 rounded-md p-1 hover:bg-neutral-100 ${
						isDisabledInput ? "cursor-not-allowed opacity-50" : "hover:cursor-pointer"
					}`}
				/>
			</button>

			{/* Custom tooltip */}
			{showTooltip && postalCode && !isLocationOpen && (
				<div
					className="absolute bottom-12 left-1/2 transform -translate-x-1/2 whitespace-nowrap px-2 py-1
              text-xs text-neutral-500 font-semibold bg-white rounded-md
              pointer-events-none border-2 border-neutral-200 postal-tooltip"
				>
					{postalCode}
				</div>
			)}

			{/* Edit Location Popover */}
			{isLocationOpen && (
				<div className="location-outer-container absolute bottom-12 -right-11 z-50">
					<Location onPostalCodeSubmitted={onPostalCodeUpdate} />
				</div>
			)}
		</div>
	);
};
