import { ChangeEvent, ClipboardEvent, KeyboardEvent, useEffect, useRef, useState } from "react";

import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import { useDataContext } from "@/contexts/DataContext";
import { AgentType, MessageType, Role } from "@/types";
import { HandoverState } from "@/types/HandoverState";

export const useChatInput = () => {
	// State variables
	const [content, setContent] = useState<string>("");
	const [error, setError] = useState<string>("");
	const [attachedFile, setAttachedFile] = useState<File | null>(null);

	// Refs
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	// Context
	const { loading, showAttachment, setShowSuggestedPrompts, setIsDisabledInput, isDisabledInput } = useAppContext();
	const { handleSend, handoverState } = useChatContext();
	const { activeAgent } = useDataContext();

	useEffect(() => {
		if (activeAgent === AgentType.ContactForm || handoverState === HandoverState.Offline) setIsDisabledInput(true);
	}, [loading, activeAgent, handoverState]);

	/**
	 * Handles the change event of the textarea.
	 */
	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const value = e.target.value;
		// Clear any existing error as soon as user types
		setError("");

		// Check length constraint
		if (value.length > 4000) {
			setError("Meldingen kan ikke være lengre enn 4000 tegn.");
			return;
		}

		setContent(value);
	};

	/**
	 * Handles the paste event to detect and attach images.
	 */
	const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
		if (!showAttachment) return; // Do nothing if attachments are disabled

		const items = e.clipboardData.items;
		for (const element of items) {
			const item = element;
			if (item.type.startsWith("image/")) {
				const file = item.getAsFile();
				if (file) {
					e.preventDefault(); // Prevent the image from being pasted as text
					setAttachedFile(file);
					break; // Handle only the first image for simplicity
				}
			}
		}
	};

	/**
	 * Handles file selection via the hidden file input.
	 */
	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0] || null;
		setAttachedFile(file);
	};

	/**
	 * Sends the message.
	 */
	const handleSendMessage = () => {
		// Clear existing error before validations
		setError("");

		// Validate empty
		if (!content.trim() && !attachedFile) {
			setError("Meldingen kan ikke være tom.");
			return;
		}

		// Validate length
		if (content.length > 4000) {
			setError("Meldingen kan ikke være lengre enn 4000 tegn.");
			return;
		}

		// If we have both text and file, send them as separate messages
		if (content.trim() && attachedFile) {
			// First send the text message
			const textMessage = {
				role: Role.human,
				content: content.trim(),
				messageType: MessageType.message,
				seen: false,
				timestamp: new Date(),
			};

			// Then send the file message
			const fileMessage = {
				role: Role.human,
				content: "",
				file: attachedFile,
				messageType: MessageType.file,
				seen: false,
				timestamp: new Date(),
			};

			// Send both messages
			handleSend(textMessage);
			handleSend(fileMessage);
		} else {
			// Create a single message object (text only or file only)
			const message = {
				role: Role.human,
				content: content.trim(),
				file: attachedFile ?? undefined,
				messageType: attachedFile ? MessageType.file : MessageType.message,
				seen: false,
				timestamp: new Date(),
			};

			handleSend(message);
		}

		setContent("");
		setAttachedFile(null); // Clear the attached file after sending
		setShowSuggestedPrompts(false);
	};

	/**
	 * Handles the key down event of the textarea.
	 */
	const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			const isButtonDisabled = isDisabledInput || (loading && activeAgent !== AgentType.LiveChat);
			if (!isButtonDisabled) {
				handleSendMessage();
			}
		}
	};

	/**
	 * Remove the attached file
	 */
	const removeAttachedFile = () => {
		setAttachedFile(null);
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	// Trigger file input click
	const openFileSelector = () => {
		fileInputRef.current?.click();
	};

	return {
		content,
		error,
		attachedFile,
		fileInputRef,
		activeAgent,
		handleChange,
		handlePaste,
		handleFileChange,
		handleSendMessage,
		handleKeyDown,
		removeAttachedFile,
		openFileSelector,
	};
};
