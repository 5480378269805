import { PiPaperclip } from "react-icons/pi";

import { ChatTextarea } from "@/components/Chat/ChatTextarea";
import { AttachedFile } from "@/components/atoms/AttachedFile";
import { ChatSubmitButton } from "@/components/molecules/ChatSubmitButton";
import { PostalCodeButton } from "@/components/molecules/PostalCodeButton";
import { useAppContext } from "@/contexts/AppContext";
import { useChatInput } from "@/hooks/useChatInput";

/**
 * ChatInput component - UI-focused implementation
 */
export const ChatInput = () => {
	const {
		content,
		error,
		attachedFile,
		fileInputRef,
		activeAgent,
		handleChange,
		handlePaste,
		handleFileChange,
		handleSendMessage,
		handleKeyDown,
		removeAttachedFile,
		openFileSelector,
	} = useChatInput();

	const { isDisabledInput } = useAppContext();

	return (
		<div className="relative flex flex-col w-full">
			{/* Error Message */}
			{error && (
				<div className="shake w-full bg-white text-center text-red-300 text-sm absolute -top-9 py-2">{error}</div>
			)}

			{/* Display Attached File */}
			<AttachedFile attachedFile={attachedFile} onRemoveAttachedFile={removeAttachedFile} />

			{/* Textarea for message input */}
			<ChatTextarea content={content} onChange={handleChange} onKeyDown={handleKeyDown} onPaste={handlePaste} />

			{/* Hidden File Input */}
			<input
				ref={fileInputRef}
				type="file"
				accept="image/*,application/pdf,video/*"
				onChange={handleFileChange}
				className="hidden"
			/>

			{/* Action Buttons */}
			<div className="absolute right-1.5 bottom-1.5 flex space-x-2 items-center">
				{/* File Attachment Button */}
				{activeAgent === "LiveChat" && (
					<button
						onClick={openFileSelector}
						disabled={isDisabledInput}
						aria-label="Attach file"
						className="-mr-1 shadow-none bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent"
					>
						<PiPaperclip
							className={`text-neutral-400 h-8 w-8 rounded-md p-1 hover:bg-neutral-100 ${
								isDisabledInput ? "cursor-not-allowed opacity-50" : "hover:cursor-pointer"
							}`}
						/>
					</button>
				)}

				{/* Postal Code Button */}
				<PostalCodeButton />

				{/* Send Button */}
				<ChatSubmitButton onClick={handleSendMessage} />
			</div>
		</div>
	);
};
