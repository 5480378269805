// services/api.ts

import { Message } from "@/types";

/**
 * Fetch messages associated with a session ID.
 *
 * @param sessionId - The current session ID.
 * @returns A promise resolving to the messages data.
 */
export const fetchMessages = async (sessionId: string) => {
  const response = await fetch(`/api/functions/getMessages?sessionId=${sessionId}`);
  if (!response.ok) throw new Error("Failed to fetch messages");
  return response.json();
};

/**
 * Save a message to the backend.
 *
 * @param data - The data containing session ID, message, and type.
 * @returns A promise resolving to the save message response.
 */
export const saveMessage = async (data: { sessionId: string; message: Message; type: string }) => {
  const response = await fetch("/api/functions/saveMessage", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error("Failed to save message");
  return response.json();
};

/**
 * Check agent availability by calling the LiveChat API.
 *
 * @returns A promise resolving to the agent availability data.
 */
export const checkAgentAvailability = async () => {
  const response = await fetch('/api/livechat/checkAgentAvailability', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  if (!response.ok) throw new Error('Failed to check agent availability');
  return response.json();
};

/**
 * Submit the contact form data.
 *
 * @param formData - The data submitted from the contact form.
 * @returns A promise resolving to the submission response.
 */
export const submitContactForm = async (formData: { name: string; email: string; phone: string; postalCode: string; message: string }) => {
  const response = await fetch('/api/contact', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });
  if (!response.ok) throw new Error('Failed to submit contact form');
  return response.json();
};

// Function to fetch postal code from the API
export const fetchPostalCodeFromAPI = async (sessionId: string) => {
  try {
    const domain = process?.env?.NEXT_PUBLIC_APP_URL ?? '';
    const response = await fetch(
        `${domain}/api/session/getPostalNumber?sessionId=${sessionId}`
    );
    if (response.ok) {
      const data = await response.json();
      return data.postalCode;
    } else {
      console.error("Failed to fetch postal code from API");
    }
  } catch (error) {
    console.error("Error fetching postal code from API:", error);
  }
  return null;
};
