import {format, subDays} from "date-fns";

export const INFORMATION_BOX_LIST_CONTENT =
    [
        "live-chat",
        "contact-form",
        "tone-and-format",
        "limited-training-data",
        "performance",
        "security",
    ]

export const DASHBOARD_API_PATH = "/dashboard/api/";
export const METRICS_API_PATH = DASHBOARD_API_PATH + "getMetrics";
export const CHART_DATA_API_PATH = DASHBOARD_API_PATH + "getChatsChartData";
export const SESSIONS_API_PATH = DASHBOARD_API_PATH + "getSessions";
export const GET_SESSION_API_PATH = DASHBOARD_API_PATH + "getSession";
export const CREATE_SESSIONS_API_PATH = DASHBOARD_API_PATH + "createSessions";
export const DELETE_SESSIONS_API_PATH = DASHBOARD_API_PATH + "deleteSessions";
export const TOPICS_API_PATH = DASHBOARD_API_PATH + "getTopics";
export const SENTIMENT_API_PATH = DASHBOARD_API_PATH + "getSentiment";
export const POSTAL_API_PATH = DASHBOARD_API_PATH + "getPostals";
export const PAGE_SIZE = 10;
export const REFRESH_COOLDOWN_MINS = 15;
export const STORAGE_KEY = "lastRefreshTimestamp";
export const HEADERS = [
    {label: "Date", key: "date"},
    {label: "Messages", key: "messages"},
    {label: "Municipality", key: "municipality"},
    {label: "Sentiment", key: "sentiment"},
    {label: "Topics", key: "topics"},
    {label: "Result", key: "result"},
    {label: "Session URL", key: "sessionUrl"},
];
export const INITIAL_METRICS = {
    totalConversations: 0,
    totalMessages: 0,
    avgMessages: 0,
    positiveRate: 0,
    neutralRate: 0,
    negativeRate: 0,
    percentChangeConversations: 0,
    percentChangeMessages: 0,
    totalConversationsPrev: 0,
    totalMessagesPrev: 0,
    avgMessagesPrev: 0,
    positiveRatePrev: 0,
    neutralRatePrev: 0,
    negativeRatePrev: 0,
};
export const DATE_FORMAT_YEARMONTHDAY = "yyyy-MM-dd";
export const DATE_FORMAT_MONTHDAYYEAR = "MM.dd.yyyy";
export const DATE_FORMAT_YEARMONTH = "yyyy-MM";
export const SUBDAYS = 6;
export const INITIAL_DATE_RANGE = {
    start: format(subDays(new Date(), SUBDAYS), DATE_FORMAT_YEARMONTHDAY),
    end: format(new Date(), DATE_FORMAT_YEARMONTHDAY),
};

export enum SENTIMENTS {
    positive = "Positive",
    neutral = "Neutral",
    negative = "Negative",
}

export const COLORS = [
    "#ff595e",
    "#ff924c",
    "#ffca3a",
    "#8ac926",
    "#52a675",
    "#1982c4",
    "#4267ac",
    "#000000", // For others
];

export const SESSION_COOKIE_NAME = "sessionId";
