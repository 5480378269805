import { MutableRefObject, useCallback, useEffect } from "react";

import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import useTranslate from "@/hooks/useTranslate";
import { CustomerSDKType, TypingIndicatorPayload } from "@/services/liveChat/types";
import { AgentType, LangflowMessageType, Message, MessageType, Role } from "@/types";
import { saveMessageInDB } from "@/utils/saveMessageInDB";

interface UseLiveChatIncomingListenerProps {
	activeCustomerSDK: CustomerSDKType | undefined;
	chatID: MutableRefObject<string | undefined>;
	setLiveChatConversationStarted: (started: boolean) => void;
}

export const useLiveChatIncomingListener = ({
	activeCustomerSDK,
	chatID,
	setLiveChatConversationStarted,
}: UseLiveChatIncomingListenerProps) => {
	const __ = useTranslate("Chat");
	const { setLoading, setIsDisabledInput, setShowEndConversationThankYouMessage } = useAppContext();
	const { activeAgent, setActiveAgent, setMessages, sessionId, agentDetails } = useDataContext();

	const handleIncomingEvent = useCallback(
		(payload: any) => {
			const savedMessageIds = new Set();
			const { event } = payload;
			if (savedMessageIds.has(event.id)) {
				return;
			}

			savedMessageIds.add(event.id);

			console.log("handleIncomingEvent from Livechat", event.type);

			if (activeAgent !== AgentType.LiveChat) {
				console.log("activeAgent is not LiveChat, skipping event", event.type);
				return;
			}

			switch (event.type) {
				case "message":
					activeCustomerSDK
						?.getChat({
							chatId: chatID?.current,
						})
						.then((chat: any) => {
							const agent = chat.users.find((x: any) => x.type === "agent");
							// Only process messages from the agent
							if (event.authorId === agent?.id) {
								setMessages((prev) => {
									// Check if this message is already in the list
									const isDuplicate = prev.some(
										(msg) => msg.timestamp?.toString() === event.createdAt?.toString() && msg.content === event.text,
									);

									if (isDuplicate) {
										return prev;
									}

									const message: Message = {
										role: Role.assistant,
										content: event.text,
										contentType: event.contentType,
										agentType: AgentType.LiveChat,
										messageType: event.type,
										seen: false,
										timestamp: event.createdAt,
										avatar: agent?.avatar || "",
									};

									// Save to DB only if it's a new message
									if (!savedMessageIds.has(message.timestamp)) {
										saveMessageInDB({
											message,
											sessionId,
											type: MessageType.message,
										});
										savedMessageIds.add(message.timestamp);
									}

									return [...prev, message];
								});
							}
						});
					break;

				case "file":
					activeCustomerSDK
						?.getChat({
							chatId: chatID?.current,
						})
						.then((chat: any) => {
							const agent = chat.users.find((x: any) => x.type === "agent");
							// Only process files from the agent
							if (event.authorId === agent?.id) {
								setMessages((prev) => {
									const isDuplicate = prev.some(
										(msg) => msg.timestamp?.toString() === event.createdAt?.toString() && msg.url === event.url,
									);

									if (isDuplicate) {
										return prev;
									}

									return [
										...prev,
										{
											role: Role.assistant,
											content: "",
											contentType: event.contentType,
											agentType: AgentType.LiveChat,
											messageType: event.type,
											url: event.url,
											seen: false,
											timestamp: event.createdAt,
											avatar: agent?.avatar || "",
										},
									];
								});
							}
						});
					break;
				case "system_message":
					console.log(event.systemMessageType, event);
					if (event.systemMessageType === "routing.archived_inactive") {
						setLoading(false);
						saveMessageInDB({
							message: {
								role: Role.system,
								content: __("yourChatHasBeenArchived"),
								contentType: event.contentType,
								agentType: AgentType.System,
								messageType: MessageType.system,
								seen: false,
								timestamp: event.createdAt,
							},
							sessionId,
							type: LangflowMessageType.message,
						});
						setActiveAgent(AgentType.AI);
						setMessages((prev) => [
							...prev,
							{
								role: Role.system,
								content: __("yourChatHasBeenArchived"),
								contentType: event.contentType,
								agentType: AgentType.System,
								messageType: MessageType.system,
								seen: false,
								timestamp: event.createdAt,
							},
						]);
						setLiveChatConversationStarted(false);
						break;
					}
					if (event.systemMessageType === "manual_archived_agent") {
						setLoading(false);
						saveMessageInDB({
							message: {
								role: Role.system,
								content: `${event.textVars.agent ?? "Agent"} ${__("leftTheConversation")}`,
								contentType: event.contentType,
								agentType: AgentType.System,
								messageType: MessageType.system,
								seen: false,
								timestamp: event.createdAt,
							},
							sessionId,
							type: LangflowMessageType.message,
						});
						setMessages((prev) => [
							...prev,
							{
								role: Role.system,
								content: `${agentDetails.name ?? "Agent"} ${__("leftTheConversation")}`,
								contentType: event.contentType,
								agentType: AgentType.System,
								messageType: MessageType.system,
								seen: false,
								timestamp: event.createdAt,
							},
						]);
						setActiveAgent(AgentType.AI);
						setShowEndConversationThankYouMessage(true);
						setIsDisabledInput(true);
						setLiveChatConversationStarted(false);
						break;
					}
					break;
				default:
					console.log("handleIncomingEvent from Livechat", event);
					break;
			}
		},
		[activeCustomerSDK, chatID, sessionId, activeAgent, agentDetails.name, setActiveAgent, setMessages, __],
	);

	const removeIncomingListener = useCallback(() => {
		activeCustomerSDK?.off("incoming_event");
		activeCustomerSDK?.off("incoming_typing_indicator");
	}, [activeCustomerSDK]);

	const addIncomingListener = useCallback(() => {
		activeCustomerSDK?.on("incoming_event", handleIncomingEvent);
		activeCustomerSDK?.on("incoming_typing_indicator", (payload: TypingIndicatorPayload) => {
			console.log(`LiveChat agent ${payload.typingIndicator.isTyping ? "started" : "stopped"} typing`);
			setLoading(payload.typingIndicator.isTyping);
		});
	}, [activeCustomerSDK, setLoading, handleIncomingEvent]);

	useEffect(() => {
		if (activeCustomerSDK) {
			addIncomingListener();
		}
		return () => {
			removeIncomingListener();
		};
	}, [activeCustomerSDK, addIncomingListener, removeIncomingListener]);
};
