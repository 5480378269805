import { useEffect } from "react";

import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import { useDataContext } from "@/contexts/DataContext";
import { MessageType, Role } from "@/types";

export const useSuggestedPrompts = () => {
	const { handleSend } = useChatContext();
	const { messages, activeAgent } = useDataContext();
	const { setShowSuggestedPrompts } = useAppContext();

	useEffect(() => {
		if (messages.length > 1) setShowSuggestedPrompts(false);
	}, [messages, setShowSuggestedPrompts]);

	const handlePromptClick = (prompt: string) => {
		setShowSuggestedPrompts(false);
		handleSend({
			role: Role.human,
			content: prompt,
			agentType: activeAgent,
			messageType: MessageType.message,
			seen: false,
			timestamp: new Date(),
		});
	};

	return {
		handlePromptClick,
	};
};
