"use client";

import React from "react";

import { AppProvider } from "@/contexts/AppContext";
import { ChatProvider } from "@/contexts/ChatContext";
import { DataProvider } from "@/contexts/DataContext";
import { IntegrationContexts } from "@/contexts/IntegrationContexts";
import { StandaloneContextProps } from "@/contexts/StandaloneContext";
import { AIIntegration } from "@/types";

export type ContextProps = StandaloneContextProps & {
	initialAiProvider?: AIIntegration;
	showInitialMessage?: boolean;
	initialMessage?: string;
};
export const Context = ({
	appUrl,
	children,
	initialAiProvider,
	showInitialMessage,
	widgetAlignment,
	initialMessage,
	clientConfig,
	demoMode,
}: ContextProps) => (
	<AppProvider
		appUrl={appUrl}
		initialAiProvider={initialAiProvider}
		showInitialMessage={showInitialMessage}
		widgetAlignment={widgetAlignment}
		initialMessage={initialMessage}
		clientConfig={clientConfig}
		demoMode={demoMode}
	>
		<DataProvider>
			<IntegrationContexts>
				<ChatProvider>{children}</ChatProvider>
			</IntegrationContexts>
		</DataProvider>
	</AppProvider>
);
