import React, { ReactNode } from "react";

export type SuggestedPromptProps = {
	onClick: () => void;
	children: ReactNode;
};

export const SuggestedPrompt: React.FC<SuggestedPromptProps> = ({ onClick, children }) => {
	return (
		<button
			className="bg-client-primary text-client-text-primary text-sm rounded-xl border-2 px-4 py-2.5 whitespace-pre-wrap my-1 sm:my-1.5 inline-flex items-center font-medium transition-all duration-200 hover:scale-105 hover:rotate-1"
			onClick={onClick}
		>
			{children}
		</button>
	);
};
