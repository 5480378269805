{
	"Dashboard": {
		"home": {
			"banner-title": "Important Information",
			"banner-description": "This page and product are owned by Frontkom AS and are confidential. They should not be shared further.",
			"title": "Hello! 👋",
			"subtitle": "Welcome to Frontkom's Service Platform for AI! Navigate to sections using the sidebar on the left.",
			"latest-update": "Latest Platform Update",
			"view-changelog": "View all updates",
			"see-whats-new": "See what's new",
			"see-all-updates": "See all updates",
			"quick-actions": "Quick Actions",
			"days-ago": "{days} days ago",
			"yesterday": "yesterday",
			"today": "today",
			"update-title": "🌟 Major UI Overhaul",
			"update-date": "Updated {date}",
			"update-count": "{count} updates",
			"entries": "entries",
			"updates-this-month": "updates this month",
			"key-features": "Shortcuts",
			"documentation": "Documentation & Resources",
			"documentation-description": "Find guides, tutorials and best practices for using our platform.",
			"view-docs": "View documentation",
			"feature-analytics": "Analytics",
			"feature-analytics-desc": "Analyze trends and view performance metrics",
			"feature-playground": "Playground",
			"feature-playground-desc": "Test the agent without saving messages",
			"feature-knowledge": "Knowledge Base",
			"feature-knowledge-desc": "Manage knowledge and instructions",
			"feature-activity": "Activity",
			"feature-activity-desc": "View conversation history and user interactions",
			"coming-soon": "Coming Soon",
			"service-status": "Service Status",
			"ai-service": "AI Service",
			"status-operational": "All systems operational",
			"status-degraded": "Performance issues detected",
			"status-outage": "Service unavailable",
			"status-checking": "Checking status...",
			"status-operational-desc": "The service is running optimally with no detected issues.",
			"status-degraded-desc": "The service is experiencing some performance issues but is still available.",
			"status-outage-desc": "The service is currently unavailable. We're working to resolve this.",
			"status-checking-desc": "Currently checking service status...",
			"last-checked": "Last checked",
			"service-warning": "We have noticed a problem with our AI service and are working on fixing it. During outages, we recommend temporarily disabling/removing the widget from your page to avoid damaging your customers' experience.",
			"service-alert-title": "Service Disruption",
			"service-alert": "We have detected an outage with our AI service and are actively working to resolve the issue. During outages, we recommend temporarily disabling/removing the widget from your page to avoid damaging your customers' experience.",
			"service-alert-simplified": "We have detected an outage with our AI service and are actively working to resolve the issue.",
			"astra-serverless-db": "Database",
			"astra-db-alert-outage": "The Database is currently experiencing an outage. We're actively working on a resolution.",
			"astra-db-alert-degraded": "The Database is currently experiencing performance issues.",
			"astra-warning": "We've detected an issue with the Database service.",
			"view-status-page": "View status page",
			"uptime": "Uptime",
			"db-alert-outage": "The Database is currently experiencing an outage. We're actively working on a resolution.",
			"db-alert-degraded": "The Database is currently experiencing performance issues.",
			"database": "Database",
			"settings": "Settings",
			"supported-file-types": "Supported file types",
			"drag-drop-files": "Drag & drop files here",
			"or-click-to-browse": "or click to browse",
			"browse-files": "Browse files",
			"upload-file": "Upload file",
			"processing": "Processing...",
			"search-files-and-links": "Search files and links",
			"search-files": "Search files",
			"no-results-found": "No results found",
			"total-documents": "Total documents",
			"upload-files": "Upload files",
			"document-processed-successfully": "Document processed successfully",
			"uploading": "Uploading",
			"saving": "Saving chunks",
			"no-documents-uploaded": "No documents uploaded yet",
			"check-chunks-to-convert": "Select text chunks to convert",
			"websites": "Websites"
		},
		"Positive": "Positive",
		"Negative": "Negative",
		"Neutral": "Neutral",
		"positive": "Positive",
		"negative": "Negative",
		"neutral": "Neutral",
		"positive-percent": "Positive %",
		"neutral-percent": "Neutral %",
		"negative-percent": "Negative %",
		"today": "Today",
		"playground": "Playground",
		"expand-content": "Expand content",
		"developer-settings": "Developer Settings",
		"playground-settings": "Playground Settings",
		"ai-provider": "AI Provider",
		"test-and-experiment-with-your-chatbot-conversations-are-not-stored": "Test and experiment with your chatbot. Conversations are not stored.",
		"sources": "Sources",
		"knowledge": "Knowledge",
		"instructions": "Instructions",
		"integrations": "Integrations",
		"this-is-the-instructions-section-content-is-coming": "This is the Instructions section. Content is coming.",
		"activity": "Activity",
		"refresh": "Refresh",
		"delete-history": "Delete History",
		"conversations": "Conversations",
		"messages": "Messages",
		"avg-msg": "Avg Msg",
		"chats": "Chats",
		"copy-session-url": "Copy session URL",
		"copy-id": "Copy ID",
		"close-chat-history-modal": "Close chat history",
		"no-data-available": "No data available",
		"export-csv": "Export CSV",
		"date": "Date",
		"first-message": "First message",
		"postal-code": "Postal Code",
		"municipality": "Municipality",
		"sentiment": "Sentiment",
		"topics": "Topics",
		"result": "Result",
		"analytics": "Analytics",
		"postal-numbers": "Postal Numbers",
		"alpha-version-limitations-and-disclaimers": "Alpha Version – Limitations and Disclaimers",
		"the-dashboard": "The Dashboard",
		"this-is-the-first-pre-alpha-version-of-the-dashboard-and-contains-many-errors": "This is the first pre-alpha version of the dashboard and contains errors.",
		"this-is-an-early-version-meant-for-testing-and-development": "This is an early version meant for testing and development.",
		"manage-your-agents-training-data": "Manage your agents' training data.",
		"conversation-history": "Conversation History",
		"timestamp": "Timestamp",
		"no-messages-available": "No messages available.",
		"select-date-range": "Select date range",
		"start-date": "Start Date",
		"end-date": "End Date",
		"cancel": "Cancel",
		"apply": "Apply",
		"line-chart": "Line Chart",
		"pie-chart": "Pie Chart",
		"total-topics": "Total Topics",
		"others": "Others",
		"loading": "Loading...",
		"show-more": "Show more...",
		"page-not-found": "Page not found",
		"sorry-this-page-is-only-accessible-on-desktop": "Sorry, this page is only accessible on desktop.",
		"try-resizing-the-window-or-opening-the-page-on-your-computer": "Try resizing the window or opening the page on your computer.",
		"compared-to-last-years": "compared to the last {years} years",
		"compared-to-same-period-years-earlier": "compared to the same period {years} years earlier",
		"compared-to-last-year": "compared to last year",
		"compared-to-same-period-earlier": "compared to the same period earlier",
		"compared-to-last-days": "compared to the last {days} days",
		"compared-to-same-period-days-earlier": "compared to the same period {days} days earlier",
		"compared-to-yesterday": "compared to yesterday",
		"compared-to-previous-day": "compared to the previous day",
		"compared-to-current-period": "compared to the current period",
		"total-view-title": "Total {viewTitle}",
		"view": "View",
		"compare": "Compare",
		"last-week": "Last Week",
		"last-month": "Last Month",
		"last-6-months": "Last 6 Months",
		"last-12-months": "Last 12 Months",
		"year-to-date": "Year to Date",
		"all-time": "All Time",
		"message": "Message...",
		"type-your-message-here": "Type your message here...",
		"search": "Search",
		"search-knowledge": "Search knowledge base",
		"search-by-id": "Search by ID",
		"finish-editing-the-current-record-before-adding-a-new-one": "Finish editing the current record before adding a new one.",
		"add-a-new-record": "Add a new record",
		"add-more": "Add more",
		"enter-content-here": "Enter content here...",
		"empty-document-placeholder": "Start writing or paste your content here...",
		"new-record": "New Record",
		"add-record": "Add record",
		"are-you-sure-you-want-to-delete-this-record": "Are you sure you want to delete this record?",
		"visible-to-chatbot": "Visible to chatbot",
		"limited-visibility-to-chatbot": "Limited visibility to chatbot",
		"save-changes": "Save Changes",
		"edit": "Edit",
		"new": "New",
		"delete": "Delete",
		"finish-editing-the-current-record-before-editing-another": "Finish editing the current record before editing another.",
		"edit-this-record": "Edit this record",
		"cannot-delete-while-editing": "Cannot delete while editing.",
		"delete-this-record": "Delete this record",
		"no-records-found": "No records found.",
		"live-chat": {
			"title": "LiveChat",
			"description": "The LiveChat feature is not enabled in this version."
		},
		"contact-form": {
			"title": "Contact Form",
			"description": "The contact form is not functional."
		},
		"tone-and-format": {
			"title": "Tone and Format",
			"description": "The tone and format of the responses will be adjusted and improved continuously based on your feedback."
		},
		"limited-training-data": {
			"title": "Limited Training Data",
			"description": "The agent uses limited training data, which can lead to errors in areas it is not trained on."
		},
		"performance": {
			"title": "Performance",
			"description": "The version may be slow in some areas, but this will be optimized in a production version."
		},
		"security": {
			"title": "Security",
			"description": "Security measures are not implemented in this alpha version."
		},
		"products": "Products",
		"events": "Events",
		"customer-support": "Customer Support",
		"total-records": "Total records",
		"filter": "Filter",
		"clear-filter": "Clear filter",
		"scroll-for-more": "Scroll for more",
		"list-view": "List view",
		"grid-view": "Grid view",
		"showing-top-search-results": "Showing top {count} search results",
		"productImpressions": "Product impressions",
		"productDetailViews": "Product detail views",
		"productClick": "Product click",
		"addToCart": "Add to cart",
		"viewCart": "View cart",
		"removeFromCart": "Remove from cart",
		"checkout": "Checkout",
		"purchase": "Purchase",
		"handover": "Handover",
		"customerSupport": "Customer support",
		"contactPresented": "Contact presented",
		"contactSubmitted": "Contact submitted",
		"selected": "selected",
		"endConversation": "End",
		"newConversation": "New",
		"endConversationModalText": "Are you sure you want to end the conversation?",
		"confirm": "Confirm",
		"continue": "Continue conversation",
		"talkToHuman": "Talk to a human",
		"contactUs": "Contact us",
		"thankYouForYourMessage": "Thank you for your message! We will contact you soon.",
		"close": "Close",
		"instruction": "Instruction",
		"edit-instruction": "Edit Instruction",
		"version-history": "Version History",
		"back": "Back",
		"active": "Active",
		"reverted": "Reverted",
		"revert": "Revert",
		"save": "Save",
		"history": "History",
		"last-updated": "Last updated",
		"words": "words",
		"tokens": "tokens",
		"word-token-count": "{words} words / {tokens} tokens",
		"no-instruction-click-edit": "No instruction found. Click edit to add one.",
		"confirm-password": "Confirm Password",
		"enter-password-to-edit": "Please enter the password to edit the instruction",
		"enter-password-to-revert": "Please enter the password to revert to this version",
		"confirm-button": "Confirm",
		"confirm-action": "Confirm Action",
		"confirm-generic": "Confirm",
		"invalid-password": "Invalid password",
		"token-tooltip": "Tokens are the units that GPT-4 uses to process text - they can be parts of words, punctuation, or spaces. The number of tokens determines the processing cost.",
		"set-status": "Set status",
		"status": "Status",
		"status-not-reviewed": "Not reviewed",
		"status-in-progress": "In progress",
		"status-reviewed": "Reviewed",
		"flere": "More statuses",
		"files-and-websites": "Files and websites",
		"upload-pdf": "Upload PDF",
		"unlink": "unlink",
		"chunks": "chunks",
		"unlinkAndConvert": "Unlink and convert",
		"checkAll": "Check all",
		"uncheckAll": "Uncheck all",
		"changelog": {
			"title": "Changelog",
			"subtitle": "Platform Updates",
			"description": "Track all improvements, new features, and fixes to our platform. Stay up to date with the latest changes.",
			"legend": {
				"title": "Legend",
				"feature": "Feature",
				"improvement": "Improvement",
				"fix": "Fix",
				"security": "Security",
				"deprecated": "Deprecated"
			}
		},
		"releases": "Releases",
		"roadmap": "Roadmap",
		"coming-soon": "Coming Soon",
		"roadmap-description": "Our future development plans will be published here. Check back for updates on upcoming features and improvements.",
		"request-feature": "Request a Feature",
		"found-a-bug": "Found a bug?",
		"report-bug": "Report a Bug",
		"error-occurred": "An error occurred",
		"check-back-later": "Please check back later for updates",
		"missing-something": "Got a great idea?",
		"feature-request-prompt": "Contact your customer representative to suggest improvements to our platform.",
		"need-help": "Need help getting started?",
		"view-examples": "View example prompts",
		"collapse": "Collapse",
		"expand": "Expand",
		"editing-document": "Editing document",
		"creating-new-document": "Creating new document",
		"draft-found-confirmation": "A draft from {time} on {date} was found. Do you want to use it?",
		"auto-save": "Auto-save",
		"save-draft": "Save draft",
		"draft-saved": "Draft saved",
		"draft-restored": "Draft restored",
		"unsaved-changes": "Unsaved changes",
		"last-saved": "Last saved: {time}",
		"discard-changes": "Discard changes",
		"editor-exit-confirmation-text": "You have unsaved changes. Are you sure you want to exit?",
		"error-loading-session": "Error loading session",
		"session-not-found": "Session not found",
		"no-session-selected": "No session selected",
		"document-processed-successfully": "Document processed successfully",
		"chunk-size": "Chunk size",
		"chunk-overlap": "Chunk overlap",
		"file": "File"
	},
	"Chat": {
		"initialMessage": "Hi! I'm **Tore** and I'm here to answer questions, give advice and guidance, and most importantly help you find the products that match your needs. How can I help you today?",
		"errorMessage": "Sorry, something went very wrong \uD83D\uDC80",
		"newConversation": "New conversation",
		"close": "Close",
		"endConversationThankYouMessage": "Thank you for conversation",
		"hasJoinedConversation": "has joined the conversation",
		"send": "Send",
		"contactFormMessage": "Unfortunately, we are not available right now. Leave a message and we will contact you as soon as possible!",
		"contactFormFooterMessage": "Share your message log with us",
		"message": "Message",
		"postalCode": "Postal code",
		"telephone": "Telephone",
		"email": "Email",
		"name": "Name",
		"removeAttachedFile": "Remove attached file",
		"chatFooterText": "Do not share sensitive data in chat.",
		"privacyPolicy": "Privacy Policy",
		"yourMessageHasNotBeenSent": "Your message has not been sent",
		"aLiveAgentHasJoinedTheConversation": "A live agent has joined the conversation.",
		"thankYouForYourMessage": "Thank you for your message! We will contact you soon.",
		"chatSessionHasBeenArchived": "Chat session has been archived",
		"yourChatHasBeenArchived": "Your chat has been archived and inactive.",
		"leftTheConversation": "left the conversation",
		"messageReceived": "Your message has been received!",
		"willContactSoon": "We will contact you as soon as possible.",
		"startNewOrClose": "Start a new conversation or close the window",
		"infoMessage": "AI agent",
		"locationTitle": "Set Your Location",
		"locationInvalidPostalCode": "Invalid postal code",
		"locationSaveError": "Failed to save postal code",
		"postalCodeDigit": "Postal code digit {digit}",
		"submitPostalCode": "Submit postal code",
		"save": "Save",
		"locationInfoText": "We need your postal code to provide you with accurate information.",
		"locationReadPrivacy": "Read privacy policy",
		"setLocation": "Set location",
		"currentPostalCode": "Current postal code",
		"cancel": "Cancel",
		"inputPlaceholder": "Can you help me with that?",
		"contactUsHere": "Contact us here:",
		"contactUs": "Contact us",
		"sorrySomethingWentWrong": "Sorry, something went wrong..."
	},
	"login": {
		"sign-in": "Sign in",
		"access-dashboard": "Sign in to access the dashboard",
		"username": "Username",
		"password": "Password",
		"signing-in": "Signing in...",
		"invalid-credentials": "Invalid username or password",
		"wrong-credentials": "Wrong username or password",
		"error-occurred": "An error occurred. Please try again.",
		"contact-help": "Contact your customer representative to get or change your username and password, or to report abuse."
	}
}
