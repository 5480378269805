import * as CustomerSDK from "@livechat/customer-sdk";

import { CustomerSDKType } from "@/services/liveChat/types";

interface CustomerPage {
	url: string;
	title: string;
}

export const initializeChatSDK = (setIsAgentOnline: (isOnline: boolean) => void): Promise<CustomerSDKType> => {
	return new Promise((resolve, reject) => {
		if (!process.env.NEXT_PUBLIC_LIVECHAT_ORG_ID || !process.env.NEXT_PUBLIC_LIVECHAT_CLIENT_ID) {
			reject({ type: "CONFIG_ERROR", message: "LiveChat configuration is missing" });
			return;
		}

		// Get current page information
		const currentPage: CustomerPage = {
			url: typeof window !== 'undefined' ? window.location.href : '',
			title: typeof window !== 'undefined' ? document.title : '',
		};

		// @ts-ignore - LiveChat SDK typing issue
		const customerSDK = CustomerSDK.init({
			organizationId: process.env.NEXT_PUBLIC_LIVECHAT_ORG_ID,
			clientId: process.env.NEXT_PUBLIC_LIVECHAT_CLIENT_ID,
			autoConnect: true,
			referrer: typeof window !== 'undefined' ? document.referrer : '',
		}) as CustomerSDKType;

		customerSDK.on("login_state_changed", (payload: any) => {
			console.log("login_state_changed", payload);
			if (payload.state === "failed" && payload.error) {
				setIsAgentOnline(false);
				reject({ type: "LOGIN_ERROR", message: payload.error.message });
			}
		});

		customerSDK.on("connected", (response: any) => {
			if (response.availability === "offline") {
				setIsAgentOnline(false);
				reject({ type: "OFFLINE", message: "LiveChat agents are offline" });
			} else {
				setIsAgentOnline(true);
				// Update customer page information
				if (typeof window !== 'undefined') {
					try {
						// @ts-ignore - LiveChat SDK typing issue
						customerSDK.updateCustomerPage(currentPage);
					} catch (error) {
						console.error("Failed to update customer page:", error);
					}
				}
			}
			resolve(customerSDK);
		});
	});
};
