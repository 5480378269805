import { useAppContext } from "@/contexts/AppContext";
import { AgentType } from "@/types";
import { getImageUrl } from "@/utils/widget/getImageUrl";

export type AvatarProps = {
	agentType?: keyof typeof AgentType;
	avatar?: string;
};

export const Avatar = ({ agentType, avatar }: AvatarProps) => {
	const { clientConfig } = useAppContext();

	const getAvatarSrc = () => {
		if (agentType === "LiveChat") {
			return avatar ?? getImageUrl("/images/livechat-avatar.png");
		}
		return getImageUrl(clientConfig?.messageAvatar);
	};

	const getAvatarAlt = () => {
		if (agentType === "LiveChat") {
			return "LiveChat Agent Avatar";
		}
		return "AI Assistant Avatar";
	};

	return (
		<div className="flex items-center avatar mb-1">
			<div className="chat-arrow border-r-neutral-100"></div>
			<img src={getAvatarSrc()} alt={getAvatarAlt()} className="rounded-full" />
		</div>
	);
};
