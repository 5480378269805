import { useEffect, useRef } from "react";

import useChatMessage from "@/hooks/useMessage";
import { AgentType, type Message, MessageType } from "@/types";
import { resolveDynamicComponent } from "@/utils/componentResolver";
import { scrollToBottom } from "@/utils/scrollUtils";

import { AvatarProps } from "@/components/atoms/Avatar";

const Avatar: React.FC<AvatarProps> = ({ agentType, avatar }) => {
	const Component = resolveDynamicComponent("atoms/Avatar", "Avatar") as React.ComponentType<AvatarProps>;
	return Component ? <Component agentType={agentType} avatar={avatar} /> : null;
};

export interface MessageProps {
	message: Message;
	isFirstInSequence: boolean;
	isLatestUserMessage?: boolean;
	disableAnimations?: boolean;
	messagesEndRef?: React.RefObject<HTMLDivElement>;
}

export const ChatMessage = ({
	message,
	isFirstInSequence,
	isLatestUserMessage = false,
	messagesEndRef,
}: MessageProps) => {
	const { renderContent, renderAttachment, isLoaded } = useChatMessage(message);
	const messageRef = useRef<HTMLDivElement>(null);

	// Determine if the 'levert/sett' text should be shown
	const shouldShowStatusText =
		isLatestUserMessage &&
		message?.agentType !== AgentType.AI &&
		message?.seen !== undefined &&
		message.role !== "assistant";

	// Determine the status text based on read status
	const statusText = message?.seen ? "levert" : "sett";

	useEffect(() => {
		// Find the parent scrollable container
		if (!messagesEndRef) return;
		const scrollableParent = messagesEndRef.current?.closest(".chat-messages-container");
		if (scrollableParent) {
			scrollToBottom(scrollableParent as HTMLElement);
		}
	}, [isLoaded]);

	return (
		<div
			ref={messageRef}
			className={`message ${isFirstInSequence ? "show-arrow-avatar" : "hide-arrow-avatar"} flex flex-col 
			${message.role === "assistant" ? "items-start assistant-message ml-10" : "items-end"} 
			${message.role === "system" ? "mb-0i system-message-container" : ""}`}
		>
			{isFirstInSequence && message.role === "assistant" && (
				<Avatar agentType={message.agentType} avatar={message.avatar} />
			)}
			{message.role === "system" ? (
				<div className="w-full flex items-center gap-4">
					<div className="before-system-message bg-gray-300 w-full h-[2px]"></div>
					<div
						className="text-gray-500 text-sm text-center font-medium px-3 py-2 max-w-[400px] whitespace-pre-wrap flex-shrink-0"
						style={{ overflowWrap: "anywhere" }}
					>
						{(message.messageType === MessageType.message || !message.messageType) && renderContent()}
						{message.messageType === MessageType.file && renderAttachment()}
					</div>
					<div className="after-system-message bg-gray-300 w-full h-[2px]"></div>
				</div>
			) : (
				<div
					className={`flex flex-col ${
						message.role === "assistant"
							? "message-assistant bg-neutral-100 text-neutral-900"
							: "message-user bg-client-primary text-client-text-primary-light"
					} rounded-lg px-3 py-2 ${message.role === "assistant" ? "max-w-[90%]" : "max-w-[70%]"} whitespace-pre-wrap`}
					style={{
						overflowWrap: "anywhere",
					}}
				>
					<div className="flex gap-2">
						<div className="">
							{(message.messageType === MessageType.message || !message.messageType) && renderContent()}
							{message.messageType === MessageType.file && renderAttachment()}
						</div>
					</div>
				</div>
			)}
			{shouldShowStatusText && (
				<div className="text-xs text-gray-400 mt-0.5">
					<span className="font-medium">{statusText}</span>{" "}
					{message.timestamp &&
						` ${new Date(message.timestamp).toLocaleTimeString("no-NO", {
							hour: "2-digit",
							minute: "2-digit",
						})}`}
				</div>
			)}
		</div>
	);
};

export default ChatMessage;
