import React, { ReactNode } from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	type: string;
	name: string;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	label?: string;
	labelClassName?: string;
	icon?: ReactNode;
}

export const Input: React.FC<InputProps> = ({ type, name, value, onChange, label, icon, ...props }) => {
	return (
		<div className="w-full mt-2">
			{label && (
				<label className="block text-xs font-medium text-client-gray-dark pl-2" htmlFor={name}>
					{label}
				</label>
			)}
			<div className="relative">
				{icon && <div className="absolute left-2 inset-y-0 flex items-center">{icon}</div>}
				<input
					id={name}
					type={type}
					placeholder={name}
					value={value}
					className={`p-2 border border-client-border rounded-md focus:outline-none focus:ring-1 focus:ring-neutral-400 w-full placeholder-neutral-300 ${icon ? "pl-8" : ""}`}
					onChange={onChange}
					{...props}
				/>
			</div>
		</div>
	);
};
