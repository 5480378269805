import { useTranslations } from "next-intl";

type Values = Record<string, string | number> | null;
export type TranslateFunction = (slug: string, values?: Values) => string;

export function useTranslate(namespace: Namespace = "Dashboard"): TranslateFunction {
	const t = useTranslations(namespace);

	return (slug: string, values: Values = null) => {
		return values ? t(slug, values) : t(slug);
	};
}

export default useTranslate;
