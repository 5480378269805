import { PiPaperclip } from "react-icons/pi";

import { useAppContext } from "@/contexts/AppContext";

type Props = {
	setAttachedFile: (file: File | null) => void;
	fileInputRef: React.RefObject<HTMLInputElement>;
};
export const FileInput = ({ setAttachedFile, fileInputRef }: Props) => {
	const { showAttachment, isDisabledInput } = useAppContext();

	if (!showAttachment) return null;

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			// Optional: Validate file type and size here
			setAttachedFile(file);
		}
	};

	const triggerFileInput = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	return (
		<>
			{showAttachment && (
				<>
					<button onClick={triggerFileInput} disabled={isDisabledInput} aria-label="Attach image">
						<PiPaperclip
							className={`text-neutral-400 h-8 w-8 rounded-md p-1 hover:bg-neutral-100 ${
								isDisabledInput ? "cursor-not-allowed opacity-50" : "hover:cursor-pointer"
							}`}
						/>
					</button>
					{/* Hidden file input accepting only image types */}
					<input
						type="file"
						ref={fileInputRef}
						style={{ display: "none" }}
						accept="image/*" // Restrict to image files
						onChange={handleFileChange}
					/>
				</>
			)}
		</>
	);
};
