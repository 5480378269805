import { IoChatboxEllipses } from "react-icons/io5";
import { PiEnvelope, PiMapPin, PiPhone, PiUser } from "react-icons/pi";

import { ContactFormIcon } from "@/components/Chat/ContactFormIcon";
import { Input } from "@/components/form/Input";
import { useContactForm } from "@/hooks/useConactForm";
import useTranslate from "@/hooks/useTranslate";
import { AgentType } from "@/types";

export const ContactForm = () => {
	const __ = useTranslate("Chat");
	const {
		activeAgent,
		name,
		email,
		phone,
		postalCode,
		message,
		includeChat,
		setName,
		setEmail,
		setPhone,
		setPostalCode,
		setMessage,
		setIncludeChat,
		handleSubmit,
	} = useContactForm();

	if (activeAgent !== AgentType.ContactForm) return null;

	return (
		<div className="mb-4 mt-10 contact-form-container">
			<form
				onSubmit={handleSubmit}
				className="relative flex flex-col rounded-lg p-3 border-2 border-neutral-200 bg-neutral-100"
			>
				<ContactFormIcon />
				<div className="text-sm text-client-text-primary-dark text-center mt-5 mb-2">{__("contactFormMessage")}</div>
				{/* Name */}
				<Input
					label={__("name")}
					type="text"
					name={__("name")}
					value={name}
					onChange={(e) => setName(e.target.value)}
					icon={<PiUser className="text-neutral-300" />}
					required
				/>
				{/* E-post */}
				<Input
					type="email"
					label={__("email")}
					name={__("email")}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					icon={<PiEnvelope className="text-neutral-300" />}
					required
				/>
				{/* Telefon */}
				<Input
					label={__("telephone")}
					type="tel"
					name={__("telephone")}
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					icon={<PiPhone className="text-neutral-300" />}
					required
				/>
				{/* Postnummer */}
				<Input
					label={__("postalCode")}
					type="text"
					name={__("postalCode")}
					value={postalCode}
					onChange={(e) => setPostalCode(e.target.value)}
					icon={<PiPhone className="text-neutral-300" />}
					required
				/>
				{/* Melding */}
				<div className="relative mb-2 mt-2">
					<label className="block text-xs font-medium text-client-gray-dark pl-2" htmlFor={__("message")}>
						{__("message")}
					</label>
					<textarea
						id={__("message")}
						placeholder={__("message")}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						className="p-2 border border-client-border rounded-md focus:outline-none focus:ring-2 focus:ring-neutral-300 w-full placeholder-neutral-300"
						rows={3}
						required
					/>
				</div>
				{/* Include Chat */}
				<div className="relative mb-4 flex items-center space-x-2">
					<input
						type="checkbox"
						id="includeChat"
						checked={includeChat}
						onChange={(e) => setIncludeChat(e.target.checked)}
						className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
					/>
					<label htmlFor="includeChat" className="flex items-center cursor-pointer text-sm text-neutral-500">
						<IoChatboxEllipses className="text-client-primary mr-1" />
						{__("contactFormFooterMessage")}
					</label>
				</div>
				{/* Submit */}
				<button
					type="submit"
					className="bg-client-primary text-white py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-neutral-300 w-full mt-2"
				>
					{__("send")}
				</button>
			</form>
		</div>
	);
};
