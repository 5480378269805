import React from "react";
import { createRoot } from "react-dom/client";

import { StandaloneContext } from "@/contexts/StandaloneContext";
import { defaultConfig } from "@/overwrite/config/defaultConfig";
import { AIIntegration } from "@/types";
import { IClientConfig } from "@/types/IClientConfig";
import { FrontkomChatWidgetInterface, WidgetConfig } from "@/types/widget";

import { ChatWidget } from "./ChatWidget";
import "./animations.scss";
import "./widget.scss";

// This will be replaced at build time with the actual config
declare const __CLIENT_CONFIG__: IClientConfig;

// Parse the build time config at module level
let buildTimeConfig: IClientConfig;
try {
	buildTimeConfig = __CLIENT_CONFIG__;
} catch (error) {
	console.error("Error parsing build time config:", error);
	buildTimeConfig = defaultConfig;
}

/**
 * StandaloneWidget class that provides widget functionality
 */
class StandaloneWidget {
	private static config: WidgetConfig = {
		apiKey: "",
		language: "no",
		theme: "light",
		position: "fixed",
		widgetAlignment: "left",
		containerId: "frontkom-chat-container",
		mobileBreakpoint: 768,
		widgetUrl: process.env.NEXT_PUBLIC_APP_URL,
		initialAiProvider: AIIntegration.Langflow,
		clientName: process.env.CLIENT_NAME,
		demoMode: false,
	};

	private static root: any = null;

	static init(config: WidgetConfig): void {
		this.config = { ...this.config, ...config, clientConfig: buildTimeConfig };
		this.createContainer();
		this.renderWidget();
		this.handleResize();
	}

	private static createContainer(): void {
		let container = document.getElementById(this.config.containerId!);
		if (container) {
			container.innerHTML = "";
		} else {
			container = document.createElement("div");
			container.id = this.config.containerId!;
			document.body.appendChild(container);
		}

		this.applyContainerStyles(container);
	}

	private static applyContainerStyles(container: HTMLElement): void {
		const baseStyles = {
			position: this.config.position,
			bottom: "2rem",
			zIndex: "999999",
			transition: "all 0.3s ease-in-out",
			backgroundColor: "transparent",
		};

		const styles = {
			...baseStyles,
			...(this.config.widgetAlignment === "left" ? { left: "2rem" } : { right: "2rem" }),
		};

		Object.assign(container.style, styles);
	}

	private static isMobile(): boolean {
		return window.innerWidth <= (this.config.mobileBreakpoint ?? 768);
	}

	private static renderWidget(): void {
		const container = document.getElementById(this.config.containerId!);
		if (!container) return;

		try {
			this.root = createRoot(container);
			this.root.render(
				<React.StrictMode>
					<div id="frontkom-chat-widget-standalone" className="frontkom-ai">
						<StandaloneContext
							appUrl={this.config.widgetUrl}
							language={this.config.language}
							initialAiProvider={this.config.initialAiProvider}
							widgetAlignment={this.config.widgetAlignment}
							clientConfig={this.config.clientConfig}
							demoMode={this.config.demoMode ?? false}
						>
							<ChatWidget position={this.config.position as "fixed" | "absolute" | "static"} />
						</StandaloneContext>
					</div>
				</React.StrictMode>,
			);
			console.log("Widget rendered successfully");
		} catch (error) {
			console.error("Error rendering widget:", error);
		}
	}

	private static handleResize(): void {
		let lastIsMobile = this.isMobile();

		window.addEventListener("resize", () => {
			const isMobile = this.isMobile();
			if (isMobile !== lastIsMobile) {
				lastIsMobile = isMobile;
				const container = document.getElementById(this.config.containerId!);
				if (container) {
					this.applyContainerStyles(container);
					this.renderWidget();
				}
			}
		});
	}

	static show(): void {
		const container = document.getElementById(this.config.containerId!);
		if (container) container.style.display = "block";
	}

	static hide(): void {
		const container = document.getElementById(this.config.containerId!);
		if (container) container.style.display = "none";
	}

	static destroy(): void {
		const container = document.getElementById(this.config.containerId!);
		if (container && this.root) {
			this.root.unmount();
			container.remove();
		}
	}
}

// Create an adapter object to match the FrontkomChatWidgetInterface
const FrontkomChatWidget: FrontkomChatWidgetInterface = {
	init: StandaloneWidget.init.bind(StandaloneWidget),
	destroy: StandaloneWidget.destroy.bind(StandaloneWidget),
	show: StandaloneWidget.show.bind(StandaloneWidget),
	hide: StandaloneWidget.hide.bind(StandaloneWidget),
};

// Make FrontkomChatWidget available globally
window.FrontkomChatWidget = FrontkomChatWidget;

// Export for possible direct imports
export { FrontkomChatWidget };
