import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { AgentType } from "@/types";
import { SESSION_COOKIE_NAME } from "@/utils/dashboard/constants";

interface UseSessionReturn {
	sessionId: string;
	resetSession: (sessionId?: string) => string;
	setSessionId: (value: ((prevState: string) => string) | string) => void;
}

export const useSession = ({
	setActiveAgent,
}: {
	setActiveAgent: (value: ((prevState: AgentType) => AgentType) | AgentType) => void;
}): UseSessionReturn => {
	const [sessionId, setSessionId] = useState<string>("");
	const [isNewSession, setIsNewSession] = useState<boolean>(false);

	useEffect(() => {
		if (isNewSession) {
			Cookies.set(SESSION_COOKIE_NAME, sessionId, {
				expires: 7,
				path: "/",
				secure: process.env.NODE_ENV === "production",
				sameSite: "Strict",
			});
		}
	}, [sessionId, isNewSession]);

	useEffect(() => {
		const initSession = () => {
			const existingSession = Cookies.get(SESSION_COOKIE_NAME);

			if (existingSession) {
				setSessionId(existingSession);
				setIsNewSession(false);
			} else {
				const newSessionId = uuidv4();
				setSessionId(newSessionId);
				setIsNewSession(true);
			}
		};

		initSession();
	}, [sessionId]);

	const resetSession = (sessionId: string | undefined) => {
		const newSessionId = sessionId ?? uuidv4().toString();
		setActiveAgent(AgentType.AI);
		setSessionId(newSessionId);
		setIsNewSession(true);
		return newSessionId;
	};

	return {
		sessionId,
		setSessionId,
		resetSession,
	};
};
