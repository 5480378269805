"use client";

import * as React from "react";
import { createContext, useCallback, useContext, useMemo } from "react";

import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import { sendToChatbase } from "@/services/chatbase/utils/api";
import { mapRoleToChatbaseRole } from "@/services/chatbase/utils/mapRoleToChatbaseRole";
import { AgentType, Message, MessageType, Role } from "@/types";
import { saveMessageInDB } from "@/utils/saveMessageInDB";

interface ChatbaseContextType {
	sendMessageToChatbase: (message: Message) => Promise<void>;
}

const ChatbaseContext = createContext<ChatbaseContextType | undefined>(undefined);

export const ChatbaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { setLoading, initialAssistantMessage, appUrl } = useAppContext();
	const { sessionId, messages, setMessages } = useDataContext();

	const sendMessageToChatbase = useCallback(
		async (message: Message) => {
			const modifiedMessage = { ...message, seen: undefined };
			setLoading(true);

			if (messages.length === 1) {
				await saveMessageInDB({
					message: initialAssistantMessage,
					sessionId,
					type: MessageType.message,
				});
			}

			saveMessageInDB({
				message: modifiedMessage,
				sessionId,
				type: MessageType.message,
			}).catch((error: any) => {
				console.error("Error saving message to DB:", error);
			});

			setMessages((prev) => [...prev, modifiedMessage]);
			const chatbaseMessages = [...messages, modifiedMessage].map((message) => {
				const role = mapRoleToChatbaseRole(message.role);
				return {
					content: message.content,
					role,
				};
			});
			await sendToChatbase({
				messages: chatbaseMessages,
				sessionId,
				appUrl,
			})
				.then(({ response }) => {
					const message = {
						content: response,
						role: Role.assistant,
						createdAt: new Date().toISOString(),
						agentType: AgentType.AI,
						messageType: MessageType.message,
						seen: false,
						timestamp: new Date(),
					};
					saveMessageInDB({
						message: message,
						sessionId,
						type: MessageType.message,
					});
					setMessages((prev) => [...prev, message]);
				})
				.catch((error) => {
					console.error("Error in sendToChatbase:", error);

					// Retry once sending the message after a delay
					setTimeout(
						() =>
							sendToChatbase({
								messages: chatbaseMessages,
								sessionId,
								appUrl,
							}),
						3000,
					);
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[sessionId, messages, setLoading, setMessages, appUrl, initialAssistantMessage],
	);

	const value = useMemo(
		() => ({
			sendMessageToChatbase,
		}),
		[sendMessageToChatbase],
	);

	return <ChatbaseContext.Provider value={value}>{children}</ChatbaseContext.Provider>;
};

export const useChatbase = () => {
	return useContext(ChatbaseContext);
};
