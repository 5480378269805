import { useCallback, useEffect, useState } from "react";

import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import { AvailabilityUpdatedEvent, CustomerSDKType, LivechatErrorContext } from "@/services/liveChat/types";
import { AgentType, User } from "@/types";

type Props = {
	handleLiveChatError: ({ message, type, error, ...rest }: any, context: LivechatErrorContext | string) => void;
};
export const useLiveChatAgent = ({ handleLiveChatError }: Props) => {
	const { setActiveAgent, activeAgent, setAgentDetails } = useDataContext();

	const [liveChatAgentDetails, setLiveChatAgentDetails] = useState<User | null>(null);
	const { setIsAgentOnline } = useAppContext();
	useEffect(() => {
		if (activeAgent == AgentType.LiveChat && liveChatAgentDetails) {
			setAgentDetails(liveChatAgentDetails);
			setIsAgentOnline(true);
		}
	}, [liveChatAgentDetails, activeAgent, setAgentDetails, setIsAgentOnline]);

	const handleAvailabilityListener = useCallback(
		(event: AvailabilityUpdatedEvent) => {
			const isOnline = event.availability === "online";
			if (!isOnline) {
				setActiveAgent(AgentType.AI);
			}
			setIsAgentOnline(isOnline);
		},
		[setActiveAgent, setIsAgentOnline],
	);

	const setupAgentListeners = useCallback(
		(sdk: CustomerSDKType | undefined) => {
			if (!sdk) return;
			const updateAgentDetails = async () => {
				const response = await sdk.getPredictedAgent().catch((error) => {
					handleLiveChatError(error, LivechatErrorContext.UPDATE_AGENT_ERROR);
				});
				if (response?.agent) {
					setLiveChatAgentDetails({
						id: response.agent?.id ?? "",
						name: response.agent?.name ?? "",
						avatar: response.agent?.avatar ?? "",
						type: AgentType.LiveChat,
					});
				}
			};

			// Initialize agent details
			updateAgentDetails();

			// Set up event listeners
			sdk.on("availability_updated", handleAvailabilityListener);

			return () => {
				sdk.off("availability_updated");
			};
		},
		[handleAvailabilityListener, setLiveChatAgentDetails, handleLiveChatError],
	);

	return { setupAgentListeners, liveChatAgentDetails };
};
