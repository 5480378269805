import { GoArrowRight } from "react-icons/go";

import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import { AgentType } from "@/types";

export const SubmitButtonContent = () => {
	const { isDisabledInput, loading } = useAppContext();
	const { activeAgent } = useDataContext();

	const isButtonDisabled = isDisabledInput || (loading && activeAgent !== AgentType.LiveChat);

	return (
		<GoArrowRight
			className={`h-8 w-8 rounded-md p-1 text-black ${
				isButtonDisabled
					? "cursor-not-allowed opacity-50"
					: "hover:cursor-pointer bg-client-primary text-white hover:opacity-80"
			}`}
		/>
	);
};
