import { useEffect, useState } from "react";

import { useAppContext } from "@/contexts/AppContext";
import { useDataContext } from "@/contexts/DataContext";
import { AgentType } from "@/types";

export const useLivechatConversation = () => {
	const { isAgentOnline } = useAppContext();

	const [liveChatConversationStarted, setLiveChatConversationStarted] = useState(false);
	const { activeAgent, setActiveAgent } = useDataContext();
	useEffect(() => {
		if (liveChatConversationStarted && activeAgent !== AgentType.LiveChat && isAgentOnline) {
			setActiveAgent(AgentType.LiveChat);
		}
	}, [setActiveAgent, isAgentOnline, activeAgent, liveChatConversationStarted]);

	return { liveChatConversationStarted, setLiveChatConversationStarted };
};
