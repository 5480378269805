import { Clock, EnvelopeSimple, Headset } from "@phosphor-icons/react";
import { MdAutoAwesome } from "react-icons/md";

import { useAppContext } from "@/contexts/AppContext";
import { useSuggestedPrompts } from "@/hooks/useSuggestedPrompts";
import useTranslate from "@/hooks/useTranslate";

import { SuggestedPrompt } from "./SuggestedPrompt";

export const SuggestedPrompts: React.FC = () => {
	const __ = useTranslate();
	const { isAgentOnline, showSuggestedPrompts } = useAppContext();
	const { handlePromptClick } = useSuggestedPrompts();

	if (!showSuggestedPrompts) return null;

	return (
		<div className="flex flex-col items-end">
			<SuggestedPrompt onClick={() => handlePromptClick("Er dere åpne?")}>
				Åpningstider <Clock weight="bold" className="inline ml-1" />
			</SuggestedPrompt>

			<SuggestedPrompt onClick={() => handlePromptClick("Anbefal et produkt")}>
				Produktanbefaling <MdAutoAwesome className="inline ml-1" />
			</SuggestedPrompt>

			<SuggestedPrompt
				onClick={() =>
					handlePromptClick(isAgentOnline ? "Jeg vil snakke med et menneske" : "Jeg ønsker å kontakte kundeservice")
				}
			>
				{isAgentOnline ? (
					<>
						{__("talkToHuman")}
						<Headset weight="fill" className="inline ml-1" />
					</>
				) : (
					<>
						{__("contactUs")}
						<EnvelopeSimple weight="bold" className="inline ml-1" />
					</>
				)}
			</SuggestedPrompt>
		</div>
	);
};
