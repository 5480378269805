import { useEffect, RefObject } from 'react';

function useOutsideClick<T extends HTMLElement>(
    ref: RefObject<T>,
    callback: () => void,
    isActive: boolean
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        }

        if (isActive) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback, isActive]);
}

export default useOutsideClick;
