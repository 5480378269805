import { Message } from "@/types";

// Determine if a message is the first in a sequence of consecutive messages from the same source
export const isFirstInSequence = (index: number, messages: Message[]) => {
	if (index === 0) return true;
	const currentMessage = messages[index];
	const previousMessage = messages[index - 1];
	return currentMessage.role !== previousMessage.role;
};

// Determine if a message is the last in a sequence of consecutive messages from the same source
export const isLastInSequence = (index: number, messages: Message[]) => {
	if (index === messages.length - 1) return true;
	const currentMessage = messages[index];
	const nextMessage = messages[index + 1];
	return currentMessage.role !== nextMessage.role;
};
