"use client";

import dots from "@public/lottie/loading-dots.json";
import dynamic from "next/dynamic";

const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

/**
 * Renders a loader component for the chat.
 * @component
 */
export const ChatLoader = () => {
	return (
		<div className="flex flex-col flex-start relative mt-4">
			<div
				className={`loading-container flex items-center bg-neutral-100 text-neutral-900 rounded-lg`}
				style={{ overflowWrap: "anywhere" }}
			>
				{/* <IconDots className="animate-pulse" /> */}

				<Lottie animationData={dots} loop autoplay className="loading-dots"></Lottie>
			</div>
			<div className="loader-arrow"></div>
		</div>
	);
};
