import { MdMinimize } from "react-icons/md";

import { ResetChat } from "@/components/Chat/ResetChat";
import { useAppContext } from "@/contexts/AppContext";
import { resolveDynamicComponent } from "@/utils/componentResolver";

const HeaderAvatar = () => {
	const Component = resolveDynamicComponent("atoms/HeaderAvatar", "HeaderAvatar");
	return Component ? <Component /> : null;
};

const HeaderAgentName = () => {
	const Component = resolveDynamicComponent("atoms/HeaderAgentName", "HeaderAgentName");
	return Component ? <Component /> : null;
};

export const ChatHeader = () => {
	const { setShowWidget } = useAppContext();

	const handleCloseClick = () => {
		setShowWidget(false);
	};

	return (
		<div className="widget-header w-full flex flex-row justify-between items-center border-b border-neutral-300 bg-client-primary text-client-text-primary-light relative z-50">
			<div className="flex gap-2 items-center widget-header-avatar px-2 rounded-md hover:bg-client-quaternary-hover group">
				<HeaderAvatar />
				<HeaderAgentName />
			</div>
			<div className="flex items-center space-x-2">
				<ResetChat />
				<MdMinimize
					className="close-icon p-2 h-10 w-10 rounded-md hover:bg-neutral-100/10 hover:text-white-100 focus:outline-none focus:ring-1 focus:ring-neutral-200"
					onClick={handleCloseClick}
				/>
			</div>
		</div>
	);
};
