import { useState } from "react";
import { PiPaperclip } from "react-icons/pi";

import type { TextareaProps } from "@/components/Chat/ChatTextarea";
import { LocationModal } from "@/components/Chat/Location";
import { AttachedFile } from "@/components/atoms/AttachedFile";
import type { SendButtonProps } from "@/components/molecules/ChatSubmitButton";
import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import { useChatInput } from "@/hooks/useChatInput";
import { resolveDynamicComponent } from "@/utils/componentResolver";

const PostalCodeButton = () => {
	const Component = resolveDynamicComponent("molecules/PostalCodeButton", "PostalCodeButton");
	return Component ? <Component /> : null;
};

const ChatSubmitButton = ({ onClick }: SendButtonProps) => {
	const Component = resolveDynamicComponent(
		"molecules/ChatSubmitButton",
		"ChatSubmitButton",
	) as React.ComponentType<SendButtonProps>;
	return Component ? <Component onClick={onClick} /> : null;
};

const ChatTextarea = ({ content, onChange, onKeyDown, onPaste, onFocus, onBlur, isExpanded }: TextareaProps) => {
	const Component = resolveDynamicComponent("Chat/ChatTextarea", "ChatTextarea") as React.ComponentType<TextareaProps>;
	return Component ? (
		<Component
			content={content}
			onChange={onChange}
			onKeyDown={onKeyDown}
			onPaste={onPaste}
			onFocus={onFocus}
			onBlur={onBlur}
			isExpanded={isExpanded}
		/>
	) : null;
};

/**
 * ChatInput component - UI-focused implementation
 */
export const ChatInput = () => {
	const {
		content,
		error,
		attachedFile,
		fileInputRef,
		activeAgent,
		handleChange,
		handlePaste,
		handleFileChange,
		handleSendMessage,
		handleKeyDown,
		removeAttachedFile,
		openFileSelector,
	} = useChatInput();

	const { isDisabledInput } = useAppContext();
	const { showLocationModal } = useChatContext();
	const [isFocused, setIsFocused] = useState(false);

	const isExpanded = isFocused || content.length > 0;

	return (
		<div className="flex flex-col w-full gap-2">
			{/* Error Message */}
			{error && <div className="shake w-full bg-white text-center text-red-300 text-sm pb-2 pt-3">{error}</div>}

			{/* Display Attached File */}
			<AttachedFile attachedFile={attachedFile} onRemoveAttachedFile={removeAttachedFile} />

			{/* Unified Input Field Container */}
			<div className="relative rounded-xl border-2 border-neutral-200 focus-within:ring-1 focus-within:ring-neutral-200 transition-all duration-300 ease-in-out">
				{/* Main Input Area */}
				<div className="transition-all duration-300 ease-in-out">
					{/* Textarea */}
					<div className="relative flex">
						<ChatTextarea
							content={content}
							onChange={handleChange}
							onKeyDown={handleKeyDown}
							onPaste={handlePaste}
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
							isExpanded={isExpanded}
						/>
					</div>
				</div>

				{/* Hidden File Input */}
				<input
					ref={fileInputRef}
					type="file"
					accept="image/*,application/pdf,video/*"
					onChange={handleFileChange}
					className="hidden"
				/>

				{/* Empty spacer div that changes height for animation */}
				<div className="transition-all duration-300 ease-in-out" style={{ height: isExpanded ? "40px" : "0" }}></div>

				{/* Buttons - Always visible */}
				<div className="flex justify-end items-center gap-2 absolute bottom-1 right-1 transition-all duration-300 ease-in-out">
					{/* File Attachment Button */}
					{activeAgent === "LiveChat" && (
						<button
							onClick={openFileSelector}
							disabled={isDisabledInput}
							aria-label="Attach file"
							className="shadow-none bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent transition-all duration-300 ease-in-out"
						>
							<PiPaperclip
								className={`text-neutral-400 h-8 w-8 rounded-xl p-1 hover:bg-neutral-100 transition-all duration-300 ease-in-out ${
									isDisabledInput ? "cursor-not-allowed opacity-50" : "hover:cursor-pointer"
								}`}
							/>
						</button>
					)}

					{/* Postal Code Button */}
					<PostalCodeButton />

					{/* Send Button */}
					<ChatSubmitButton onClick={handleSendMessage} />
				</div>
			</div>
		</div>
	);
};
