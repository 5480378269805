"use client";

import { NextIntlClientProvider } from "next-intl";
import { ReactNode } from "react";

import { Context } from "@/contexts/Contexts";
import enMessages from "@/messages/en.json";
import noMessages from "@/messages/no.json";
import { AIIntegration } from "@/types";
import { IClientConfig } from "@/types/IClientConfig";

const messages = {
	en: enMessages,
	no: noMessages,
};

export interface StandaloneContextProps {
	children: ReactNode;
	appUrl?: string;
	language?: string;
	initialAiProvider?: AIIntegration;
	widgetAlignment?: "left" | "right";
	clientConfig?: IClientConfig;
	demoMode: boolean;
}

export const StandaloneContext = ({
	appUrl,
	language = "no",
	initialAiProvider,
	widgetAlignment = "left",
	demoMode,
	children,
	clientConfig,
}: StandaloneContextProps) => {
	const currentMessages = messages[language as keyof typeof messages] || messages.no;

	return (
		<NextIntlClientProvider locale={language} messages={currentMessages}>
			<Context
				appUrl={appUrl}
				clientConfig={clientConfig}
				initialAiProvider={initialAiProvider}
				widgetAlignment={widgetAlignment}
				initialMessage={clientConfig?.initialMessage}
				demoMode={demoMode}
			>
				{children}
			</Context>
		</NextIntlClientProvider>
	);
};
