// components/Chat/Product.tsx
import { PaintBucket } from "@phosphor-icons/react";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { MdOutlineRefresh } from "react-icons/md";

import { fetchProductDetails } from "@/actions/productActions";
import DrupalImage from "@/components/molecules/DrupalImage";
import { getImageUrl } from "@/utils/widget/getImageUrl";

interface ProductProps {
	id: string;
}

interface StockSource {
	id: string;
	name: string;
	stock: number;
}

interface Variant {
	width: string;
	height: string;
	price: number;
	stockSources: StockSource[];
	swingDirection: string;
	swingDirectionImage: string;
}

interface ProductData {
	id: string;
	image: string;
	name: string;
	brand: string;
	price: number;
	variants: Variant[];
	stock: number;
	deliveryTime: string | null;
	isProductBundle?: boolean;
	pathAlias?: string;
}

/**
 * Get the local image path for a swing direction
 */
const getSwingDirectionImage = (swingDirection: string): string => {
	if (swingDirection === "Høyre") return getImageUrl("/images/hinged_right.png");
	if (swingDirection === "Venstre") return getImageUrl("/images/hinged_left.png");
	return "";
};

/**
 * Format price with Norwegian locale
 */
const formatPrice = (price: number) =>
	price.toLocaleString("no-NO", {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}) + " ,-";

/**
 * The main Product component
 */
const Product: React.FC<ProductProps> = ({ id }) => {
	const [productData, setProductData] = useState<ProductData | null>(null);
	const [selectedWidth, setSelectedWidth] = useState<string | null>(null);
	const [selectedHeight, setSelectedHeight] = useState<string | null>(null);
	const [selectedSwingDirection, setSelectedSwingDirection] = useState<string | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [showStockSources, setShowStockSources] = useState(false);

	// Fetch the product details using server action
	useEffect(() => {
		const loadProductData = async () => {
			try {
				const data = await fetchProductDetails(id);
				setProductData(data);

				// Set initial variant selection based on the first variant
				if (data.variants.length > 0) {
					const firstVariant = data.variants[0];
					setSelectedWidth(firstVariant.width);
					setSelectedHeight(firstVariant.height);
					setSelectedSwingDirection(firstVariant.swingDirection);
				}

				setLoading(false);
			} catch (err) {
				setError("Failed to fetch product data.");
				setLoading(false);
			}
		};

		loadProductData();
	}, [id]);

	// Filter available variants based on selected dimension & direction
	const filterVariants = (width?: string, height?: string, swingDirection?: string) => {
		return (
			productData?.variants.filter(
				(variant) =>
					(!width || variant.width === width) &&
					(!height || variant.height === height) &&
					(!swingDirection || variant.swingDirection === swingDirection),
			) || []
		);
	};

	// Handlers for dimension and direction changes
	const handleWidthChange = (width: string) => {
		setSelectedWidth(width);
		const validVariants = filterVariants(width, selectedHeight ?? undefined);
		const validHeights = [...new Set(validVariants.map((v) => v.height))];
		const validSwingDirections = [...new Set(validVariants.map((v) => v.swingDirection))];

		setSelectedHeight(validHeights[0] ?? null);
		setSelectedSwingDirection(validSwingDirections[0] ?? null);
	};

	const handleHeightChange = (height: string) => {
		setSelectedHeight(height);
		const validVariants = filterVariants(selectedWidth ?? undefined, height);
		const validWidths = [...new Set(validVariants.map((v) => v.width))];
		const validSwingDirections = [...new Set(validVariants.map((v) => v.swingDirection))];

		if (!validWidths.includes(selectedWidth ?? "")) {
			setSelectedWidth(validWidths[0] ?? null);
		}
		setSelectedSwingDirection(validSwingDirections[0] ?? null);
	};

	const handleSwingDirectionChange = (swingDirection: string) => {
		setSelectedSwingDirection(swingDirection);
		const validVariants = filterVariants(selectedWidth ?? undefined, selectedHeight ?? undefined, swingDirection);
		const validWidths = [...new Set(validVariants.map((v) => v.width))];
		const validHeights = [...new Set(validVariants.map((v) => v.height))];

		if (!validWidths.includes(selectedWidth ?? "")) {
			setSelectedWidth(validWidths[0] ?? null);
		}
		if (!validHeights.includes(selectedHeight ?? "")) {
			setSelectedHeight(validHeights[0] || null);
		}
	};

	const getStockClassAndText = (stock: number) => {
		if (stock > 5) return { className: "in-stock", text: "På lager" };
		if (stock > 0) return { className: "few-stock", text: "Få på lager" };
		return { className: "no-stock", text: "Ikke på lager" };
	};

	// Render states
	if (loading) {
		return (
			<div className="product bg-white font-bold">
				<MdOutlineRefresh className="animate-spin" />
				Laster produktvisning...
			</div>
		);
	}

	if (error) {
		return <div>Error: {error}</div>;
	}

	if (!productData) {
		return <div>No product data available.</div>;
	}

	// Filter variants by selected dimension/direction
	const filteredVariants = filterVariants(
		selectedWidth ?? undefined,
		selectedHeight ?? undefined,
		selectedSwingDirection ?? undefined,
	);
	const selectedVariant = filteredVariants[0];

	// Determine stock availability
	const nettbutikkStock = selectedVariant?.stockSources.find((s) => s.name === "Nettbutikk")?.stock ?? 0;
	const trondheimStock = selectedVariant?.stockSources.find((s) => s.name === "Trondheim")?.stock ?? 0;
	const isNetOrTrondheimAvailable = nettbutikkStock > 0 || trondheimStock > 0;

	const { className: stockClass, text: stockText } = isNetOrTrondheimAvailable
		? { className: "in-stock", text: "På nettlager" }
		: getStockClassAndText(nettbutikkStock);

	// Check if each variant option exists
	const hasWidthOptions = productData.variants.some((v) => v.width && !v.width.startsWith("0"));
	const hasHeightOptions = productData.variants.some((v) => v.height && !v.height.startsWith("0"));
	const hasSwingDirectionOptions = productData.variants.some((v) => v.swingDirection);

	// Determine if we should show simplified view
	const productPrice = selectedVariant?.price || productData.price;
	const hasNaNDimensions = selectedWidth === "NaN" || selectedHeight === "NaN" || !selectedWidth || !selectedHeight;
	const hasNoStock = nettbutikkStock <= 0 && trondheimStock <= 0;
	const showSimplifiedView = !productPrice || productPrice === 0 || (hasNaNDimensions && hasNoStock);

	return (
		<div className="product bg-white">
			<div className="product-info">
				<h3 className="product-brand text-client-primary">{productData.brand}</h3>
				<div>
					<a href={productData.pathAlias}>
						<DrupalImage
							directImageUrl={productData.image}
							alt={productData.name}
							className="product-image bg-white"
							width={400}
							height={400}
						/>
					</a>
				</div>
				{showSimplifiedView ? (
					<div className="product-details">
						<h2 className="product-name">{productData.name}</h2>
						<div className="product-price-container justify-end">
							<a href={productData.pathAlias} className="product-button text-client-secondary">
								Gå til produkt
							</a>
						</div>
					</div>
				) : (
					<div className="product-details">
						<h2 className="product-name">{productData.name}</h2>

						{(hasWidthOptions || hasHeightOptions || hasSwingDirectionOptions) && (
							<div className="product-variants">
								{/* Wrap width and height selectors together */}
								<div className="dimension-selectors">
									{hasWidthOptions && (
										<div>
											<label>Bredde:</label>
											<select value={selectedWidth ?? ""} onChange={(e) => handleWidthChange(e.target.value)}>
												<option value="" disabled>
													Velg Bredde
												</option>
												{[
													...new Set(
														productData.variants.map((v) => v.width).filter((width) => width && !width.startsWith("0") && width !== "NaN"),
													),
												].map((width, index) => (
													<option key={index} value={width}>
														{width}
													</option>
												))}
											</select>
										</div>
									)}

									{hasHeightOptions && (
										<div>
											<label>Høyde:</label>
											<select value={selectedHeight ?? ""} onChange={(e) => handleHeightChange(e.target.value)}>
												<option value="" disabled>
													Velg Høyde
												</option>
												{[
													...new Set(
														productData.variants
															.filter((v) => !selectedWidth || v.width === selectedWidth)
															.map((v) => v.height)
															.filter((h) => h && !h.startsWith("0") && h !== "NaN"),
													),
												].map((height, index) => (
													<option key={index} value={height}>
														{height}
													</option>
												))}
											</select>
										</div>
									)}
								</div>

								{/* Now place Slagretning below the two selectors */}
								{hasSwingDirectionOptions && (
									<div className="swing-direction-container">
										<label>Slagretning:</label>
										<div className="swing-direction-options">
											{[
												...new Set(
													productData.variants
														.filter(
															(v) =>
																(!selectedWidth || v.width === selectedWidth) &&
																(!selectedHeight || v.height === selectedHeight),
														)
														.map((v) => v.swingDirection),
												),
											].map((dir, index) => (
												<label
													key={index}
													className={`radio-label ${selectedSwingDirection === dir ? "selected" : ""} hover:cursor-pointer`}
												>
													<div className="relative">
														<input
															className="absolute w-4 h-4 opacity-0 cursor-pointer"
															type="radio"
															name="swingDirection"
															value={dir}
															checked={selectedSwingDirection === dir}
															onChange={() => handleSwingDirectionChange(dir)}
														/>
														<div
															className={`w-4 h-4 border border-gray-300 rounded-full ${
																selectedSwingDirection === dir
																	? 'border-client-primary after:absolute after:content-[""] after:w-2.5 after:h-2.5 after:top-[3px] after:left-[3px] after:rounded-full after:bg-blue-500'
																	: ""
															}`}
														/>
													</div>
													{dir === "Høyre" || dir === "Venstre" ? (
														<Image src={getSwingDirectionImage(dir)} alt={dir} width={24} height={24} />
													) : (
														dir
													)}
												</label>
											))}
										</div>
									</div>
								)}
							</div>
						)}

						{selectedVariant && (
							<div className="product-stock">
								<span className={`stock-indicator ${stockClass}`} /> {stockText},
								<a className="stock-link" href="#" onClick={() => setShowStockSources(!showStockSources)}>
									{" "}
									Se hele utvalget her
								</a>
							</div>
						)}

						{showStockSources && selectedVariant && (
							<div className="stock-sources">
								{selectedVariant.stockSources
									.filter((source) => source.name !== "Nettbutikk")
									.map((source, index) => {
										const { className: sClass, text: sText } = getStockClassAndText(source.stock);
										return (
											<div key={index} className="stock-source">
												<span className={`stock-indicator ${sClass}`} />
												{source.name}: {sText}
											</div>
										);
									})}
							</div>
						)}

						<div className="product-price-container">
							{(selectedVariant?.price || productData.price) ? (
								<div>
									<p className="product-price-prefix">Fra</p>
									<p className="product-price">{formatPrice(productPrice)}</p>
								</div>
							) : <div></div>}
							{productData.isProductBundle ? (
								<div className="design-self-button-container">
									<a href={productData.pathAlias} className="design-self-button text-white">
										<span>
											DESIGN SELV <PaintBucket size={16} weight="bold" className="ml-1" />
										</span>
										<Image
											src={getImageUrl("/images/button_bg.jpg")}
											alt="button background"
											width={200}
											height={40}
											className="w-full h-full object-cover absolute inset-0 -z-10"
										/>
									</a>
								</div>
							) : !selectedVariant?.price && !productData.price ? (
								<a href={productData.pathAlias} className="product-button text-client-secondary">
									Gå til produkt
								</a>
							) : (
								<a href={productData.pathAlias} className="product-button text-client-secondary">
									Kjøp
								</a>
							)}
						</div>

						{productData.deliveryTime && <div className="delivery-time text-gray-400">{productData.deliveryTime}</div>}
					</div>
				)}
			</div>
			{/* Hide the product ID in the DOM */}
			<div style={{ display: "none" }}>{id}</div>
		</div>
	);
};

export default Product;
