"use client";

import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { ContextProps } from "@/contexts/Contexts";
import useTranslate from "@/hooks/useTranslate";
import { AIIntegration, AgentType, HumanIntegration, Message, MessageType, Role } from "@/types";
import { IClientConfig } from "@/types/IClientConfig";

interface AppContextProps {
	appUrl?: string;
	loading: boolean;
	initialAssistantMessage: Message;
	errorMessage: Message;
	setLoading: (loading: boolean) => void;
	showWidget: boolean;
	setShowWidget: (show: boolean) => void;
	showAttachment: boolean;
	setShowAttachment: (show: boolean) => void;
	isUploadingFile: boolean;
	setIsUploadingFile: (isUploading: boolean) => void;
	isAgentOnline: boolean;
	setIsAgentOnline: (isAgentOnline: boolean) => void;
	aiProvider: AIIntegration;
	setAiProvider: (aiProvider: AIIntegration) => void;
	showSuggestedPrompts?: boolean;
	setShowSuggestedPrompts: (show: boolean) => void;
	showEndConversationThankYouMessage: boolean;
	setShowEndConversationThankYouMessage: (show: boolean) => void;
	isDisabledInput: boolean;
	setIsDisabledInput: (show: boolean) => void;
	widgetAlignment?: string;
	demoMode?: boolean;
	clientConfig?: IClientConfig;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider = ({
	children,
	appUrl = process?.env?.NEXT_PUBLIC_APP_URL,
	initialAiProvider,
	initialMessage,
	widgetAlignment = "right",
	demoMode,
	clientConfig,
}: ContextProps) => {
	const __ = useTranslate("Chat");

	const initialAssistantMessage = {
		role: Role.assistant,
		content: initialMessage ?? "",
		agentType: AgentType.AI,
		messageType: MessageType.message,
		seen: false,
		timestamp: new Date(),
	};
	const errorMessage = {
		role: Role.assistant,
		content: __("errorMessage"),
		agentType: AgentType.System,
		messageType: MessageType.message,
		seen: false,
		timestamp: new Date(),
	};

	const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
	const [isAgentOnline, setIsAgentOnline] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [aiProvider, setAiProvider] = useState(initialAiProvider ?? AIIntegration.Langflow);
	const [humanProvider, setHumanProvider] = useState(HumanIntegration.LiveChat);

	const [showWidget, setShowWidget] = useState<boolean>(() => {
		if (typeof window !== "undefined") {
			const cachedState = localStorage.getItem("isWidgetVisible");
			if (cachedState) {
				return JSON.parse(cachedState);
			}
		}
		return false;
	});
	const [showAttachment, setShowAttachment] = useState(true);
	const [showSuggestedPrompts, setShowSuggestedPrompts] = useState(true);
	const [showEndConversationThankYouMessage, setShowEndConversationThankYouMessage] = useState(false);
	const [isDisabledInput, setIsDisabledInput] = useState(false);

	useEffect(() => {
		if (typeof window !== "undefined") {
			localStorage.setItem("isWidgetVisible", JSON.stringify(showWidget));
		}
	}, [showWidget]);

	const contextValue = useMemo(
		() => ({
			demoMode: demoMode ?? false,
			clientConfig,
			appUrl,
			loading,
			initialAssistantMessage,
			errorMessage,
			aiProvider,
			setAiProvider,
			humanProvider,
			setLoading,
			showWidget,
			setShowWidget,
			showAttachment,
			setShowAttachment,
			isUploadingFile,
			setIsUploadingFile,
			isAgentOnline,
			setIsAgentOnline,
			showSuggestedPrompts,
			setShowSuggestedPrompts,
			showEndConversationThankYouMessage,
			setShowEndConversationThankYouMessage,
			isDisabledInput,
			setIsDisabledInput,
			widgetAlignment,
		}),
		[
			errorMessage,
			demoMode,
			clientConfig,
			showWidget,
			showAttachment,
			loading,
			appUrl,
			isUploadingFile,
			isAgentOnline,
			aiProvider,
			humanProvider,
			showSuggestedPrompts,
			showEndConversationThankYouMessage,
			isDisabledInput,
			widgetAlignment,
			initialAssistantMessage,
		],
	);

	return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export const useAppContext: () => AppContextProps = () => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error("useAppContext must be used within a AppProvider");
	}
	return context;
};
