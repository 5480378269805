import { useDataContext } from "@/contexts/DataContext";
import { useClientConfig } from "@/hooks/useClientConfig";

export const HeaderAgentName = () => {
	const { agentDetails, activeAgent } = useDataContext();
	const { clientConfig, isConfigLoaded } = useClientConfig();

	let name = agentDetails?.name;

	if (isConfigLoaded && clientConfig.agentName) {
		name = clientConfig.agentName;
	}

	return (
		<div className="flex flex-row items-center space-x-2">
			<p>{agentDetails?.name}</p>
			{activeAgent !== "LiveChat" && (
				<p className="text-xs py-1 px-1.5 text-white/50 bg-white bg-opacity-10 rounded-md font-bold">KI</p>
			)}
		</div>
	);
};
