import { LangflowMessageType, Message } from "@/types";
import { getEnvAppUrl } from "@/utils/getEnvAppUrl";

type Props = {
	sessionId: string;
	message: Message;
	type: keyof typeof LangflowMessageType;
};
export const saveMessageInDB = async ({ message, sessionId, type }: Props) => {
	const appUrl = getEnvAppUrl();
	return await fetch(`${appUrl}/api/chat/saveMessage`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			message,
			sessionId,
			type,
		}),
	});
};
