
	import React from 'react';
	
	// Import all components
	import * as Base_atoms_AttachedFile from '../src/components/atoms/AttachedFile';
		import * as Base_atoms_Avatar from '../src/components/atoms/Avatar';
		import * as Base_atoms_ChatFooter from '../src/components/atoms/ChatFooter';
		import * as Base_atoms_HeaderAgentName from '../src/components/atoms/HeaderAgentName';
		import * as Base_atoms_HeaderAvatar from '../src/components/atoms/HeaderAvatar';
		import * as Base_atoms_MessageInfo from '../src/components/atoms/MessageInfo';
		import * as Base_atoms_MessageLoader from '../src/components/atoms/MessageLoader';
		import * as Base_atoms_SubmitButtonContent from '../src/components/atoms/SubmitButtonContent';
		import * as Base_atoms_WidgetTooltip from '../src/components/atoms/WidgetTooltip';
		import * as Base_molecules_ChatHeader from '../src/components/molecules/ChatHeader';
		import * as Base_molecules_ChatIcon from '../src/components/molecules/ChatIcon';
		import * as Base_molecules_ChatSubmitButton from '../src/components/molecules/ChatSubmitButton';
		import * as Base_molecules_DrupalImage from '../src/components/molecules/DrupalImage';
		import * as Base_molecules_FileInput from '../src/components/molecules/FileInput';
		import * as Base_molecules_MessagesList from '../src/components/molecules/MessagesList';
		import * as Base_molecules_Modal from '../src/components/molecules/Modal';
		import * as Base_molecules_PlaygroundSettings from '../src/components/molecules/PlaygroundSettings';
		import * as Base_molecules_PostalCodeButton from '../src/components/molecules/PostalCodeButton';
		import * as Base_molecules_SuggestedPrompt from '../src/components/molecules/SuggestedPrompt';
		import * as Base_molecules_SuggestedPrompts from '../src/components/molecules/SuggestedPrompts';
		import * as Base_molecules_WelcomeMessage from '../src/components/molecules/WelcomeMessage';
		import * as Base_Chat_ChatInput from '../src/components/Chat/ChatInput';
		import * as Base_Chat_ChatLoader from '../src/components/Chat/ChatLoader';
		import * as Base_Chat_ChatMessage from '../src/components/Chat/ChatMessage';
		import * as Base_Chat_ChatTextarea from '../src/components/Chat/ChatTextarea';
		import * as Base_Chat_ContactForm from '../src/components/Chat/ContactForm';
		import * as Base_Chat_ContactFormIcon from '../src/components/Chat/ContactFormIcon';
		import * as Base_Chat_ContactFormSubmissionError from '../src/components/Chat/ContactFormSubmissionError';
		import * as Base_Chat_EndConversationModal from '../src/components/Chat/EndConversationModal';
		import * as Base_Chat_EndConversationThankYouMessage from '../src/components/Chat/EndConversationThankYouMessage';
		import * as Base_Chat_Location from '../src/components/Chat/Location';
		import * as Base_Chat_Product from '../src/components/Chat/Product';
		import * as Base_Chat_ResetChat from '../src/components/Chat/ResetChat';
		import * as Base_Chat_ThankYouMessage from '../src/components/Chat/ThankYouMessage';
		import * as Base_icons_Arrow from '../src/components/icons/Arrow';
		import * as Base_icons_Exit from '../src/components/icons/Exit';
		import * as Base_icons_WidgetIcon from '../src/components/icons/WidgetIcon';
		import * as Base_icons_AiIcon from '../src/components/icons/status/small/AiIcon';
		import * as Base_icons_CloseIcon from '../src/components/icons/status/small/CloseIcon';
		import * as Client_atoms_ChatSubmitButton from '../src/overwrite/ligaard/components/atoms/ChatSubmitButton';
		import * as Client_atoms_MessageInfo from '../src/overwrite/ligaard/components/atoms/MessageInfo';
		import * as Client_atoms_SubmitButtonContent from '../src/overwrite/ligaard/components/atoms/SubmitButtonContent';
		import * as Client_atoms_WidgetTooltip from '../src/overwrite/ligaard/components/atoms/WidgetTooltip';
		import * as Client_molecules_SuggestedPrompt from '../src/overwrite/ligaard/components/molecules/SuggestedPrompt';
		import * as Client_molecules_SuggestedPrompts from '../src/overwrite/ligaard/components/molecules/SuggestedPrompts';
	
	// Get the client name from the environment
	const clientName = process.env.CLIENT_NAME || '';
	
	// Create component map with base components and client overrides
	const COMPONENT_MAP = new Map();
	
	// Helper function to validate component
	const isValidComponent = (component) => {
		try {
			return (
				component !== null &&
				component !== undefined &&
				(typeof component === 'function' || (typeof component === 'object' && component.$$typeof))
			);
		} catch (error) {
			console.error('Error validating component:', error);
			return false;
		}
	};

	// Helper function to wrap component
	const wrapComponent = (Component, componentPath) => {
		if (!Component) return null;
		
		// If it's already a valid component, return it
		if (isValidComponent(Component)) {
			return Component;
		}

		// Create a wrapped component
		const WrappedComponent = (props) => {
			try {
				return React.createElement(Component, props);
			} catch (error) {
				console.error('Error rendering component:', componentPath, error);
				return null;
			}
		};

		// Copy over any static properties
		if (Component.displayName) WrappedComponent.displayName = Component.displayName;
		if (Component.defaultProps) WrappedComponent.defaultProps = Component.defaultProps;
		
		return WrappedComponent;
	};

	// Helper function to safely get Map keys
	const safeGetKeys = (map) => {
		try {
			return Array.from(map.keys());
		} catch (error) {
			console.error('Error getting keys:', error);
			return [];
		}
	};

	// Helper function to resolve component from module
	const resolveFromModule = (mod, componentName, componentPath) => {
		try {
			if (!mod) {
				console.warn('Module is undefined or null for component:', componentName);
				return null;
			}

			let resolvedComponent = null;

			// Try default export first
			if (mod.default) {
				resolvedComponent = wrapComponent(mod.default, componentPath);
				if (resolvedComponent) {
					return resolvedComponent;
				}
			}
			
			// Try named export matching the component name
			if (mod[componentName]) {
				resolvedComponent = wrapComponent(mod[componentName], componentPath);
				if (resolvedComponent) {
					return resolvedComponent;
				}
			}
			
			// If the module itself is a component, wrap it
			resolvedComponent = wrapComponent(mod, componentPath);
			if (resolvedComponent) {
				return resolvedComponent;
			}

			console.warn('No valid component found in module for:', componentName);
			return null;
		} catch (error) {
			console.error('Error resolving module for:', componentName, error);
			return null;
		}
	};
	
	console.log('Initializing component map');

	// Add base components
	try {
		
					const AttachedFileBase = resolveFromModule(Base_atoms_AttachedFile, 'AttachedFile', 'atoms/AttachedFile');
					if (AttachedFileBase) {
						COMPONENT_MAP.set('atoms/AttachedFile', AttachedFileBase);
					} else {
						console.warn('Failed to resolve base component:', 'atoms/AttachedFile');
					}
				
		
					const AvatarBase = resolveFromModule(Base_atoms_Avatar, 'Avatar', 'atoms/Avatar');
					if (AvatarBase) {
						COMPONENT_MAP.set('atoms/Avatar', AvatarBase);
					} else {
						console.warn('Failed to resolve base component:', 'atoms/Avatar');
					}
				
		
					const ChatFooterBase = resolveFromModule(Base_atoms_ChatFooter, 'ChatFooter', 'atoms/ChatFooter');
					if (ChatFooterBase) {
						COMPONENT_MAP.set('atoms/ChatFooter', ChatFooterBase);
					} else {
						console.warn('Failed to resolve base component:', 'atoms/ChatFooter');
					}
				
		
					const HeaderAgentNameBase = resolveFromModule(Base_atoms_HeaderAgentName, 'HeaderAgentName', 'atoms/HeaderAgentName');
					if (HeaderAgentNameBase) {
						COMPONENT_MAP.set('atoms/HeaderAgentName', HeaderAgentNameBase);
					} else {
						console.warn('Failed to resolve base component:', 'atoms/HeaderAgentName');
					}
				
		
					const HeaderAvatarBase = resolveFromModule(Base_atoms_HeaderAvatar, 'HeaderAvatar', 'atoms/HeaderAvatar');
					if (HeaderAvatarBase) {
						COMPONENT_MAP.set('atoms/HeaderAvatar', HeaderAvatarBase);
					} else {
						console.warn('Failed to resolve base component:', 'atoms/HeaderAvatar');
					}
				
		
					const MessageInfoBase = resolveFromModule(Base_atoms_MessageInfo, 'MessageInfo', 'atoms/MessageInfo');
					if (MessageInfoBase) {
						COMPONENT_MAP.set('atoms/MessageInfo', MessageInfoBase);
					} else {
						console.warn('Failed to resolve base component:', 'atoms/MessageInfo');
					}
				
		
					const MessageLoaderBase = resolveFromModule(Base_atoms_MessageLoader, 'MessageLoader', 'atoms/MessageLoader');
					if (MessageLoaderBase) {
						COMPONENT_MAP.set('atoms/MessageLoader', MessageLoaderBase);
					} else {
						console.warn('Failed to resolve base component:', 'atoms/MessageLoader');
					}
				
		
					const SubmitButtonContentBase = resolveFromModule(Base_atoms_SubmitButtonContent, 'SubmitButtonContent', 'atoms/SubmitButtonContent');
					if (SubmitButtonContentBase) {
						COMPONENT_MAP.set('atoms/SubmitButtonContent', SubmitButtonContentBase);
					} else {
						console.warn('Failed to resolve base component:', 'atoms/SubmitButtonContent');
					}
				
		
					const WidgetTooltipBase = resolveFromModule(Base_atoms_WidgetTooltip, 'WidgetTooltip', 'atoms/WidgetTooltip');
					if (WidgetTooltipBase) {
						COMPONENT_MAP.set('atoms/WidgetTooltip', WidgetTooltipBase);
					} else {
						console.warn('Failed to resolve base component:', 'atoms/WidgetTooltip');
					}
				
		
					const ChatHeaderBase = resolveFromModule(Base_molecules_ChatHeader, 'ChatHeader', 'molecules/ChatHeader');
					if (ChatHeaderBase) {
						COMPONENT_MAP.set('molecules/ChatHeader', ChatHeaderBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/ChatHeader');
					}
				
		
					const ChatIconBase = resolveFromModule(Base_molecules_ChatIcon, 'ChatIcon', 'molecules/ChatIcon');
					if (ChatIconBase) {
						COMPONENT_MAP.set('molecules/ChatIcon', ChatIconBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/ChatIcon');
					}
				
		
					const ChatSubmitButtonBase = resolveFromModule(Base_molecules_ChatSubmitButton, 'ChatSubmitButton', 'molecules/ChatSubmitButton');
					if (ChatSubmitButtonBase) {
						COMPONENT_MAP.set('molecules/ChatSubmitButton', ChatSubmitButtonBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/ChatSubmitButton');
					}
				
		
					const DrupalImageBase = resolveFromModule(Base_molecules_DrupalImage, 'DrupalImage', 'molecules/DrupalImage');
					if (DrupalImageBase) {
						COMPONENT_MAP.set('molecules/DrupalImage', DrupalImageBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/DrupalImage');
					}
				
		
					const FileInputBase = resolveFromModule(Base_molecules_FileInput, 'FileInput', 'molecules/FileInput');
					if (FileInputBase) {
						COMPONENT_MAP.set('molecules/FileInput', FileInputBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/FileInput');
					}
				
		
					const MessagesListBase = resolveFromModule(Base_molecules_MessagesList, 'MessagesList', 'molecules/MessagesList');
					if (MessagesListBase) {
						COMPONENT_MAP.set('molecules/MessagesList', MessagesListBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/MessagesList');
					}
				
		
					const ModalBase = resolveFromModule(Base_molecules_Modal, 'Modal', 'molecules/Modal');
					if (ModalBase) {
						COMPONENT_MAP.set('molecules/Modal', ModalBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/Modal');
					}
				
		
					const PlaygroundSettingsBase = resolveFromModule(Base_molecules_PlaygroundSettings, 'PlaygroundSettings', 'molecules/PlaygroundSettings');
					if (PlaygroundSettingsBase) {
						COMPONENT_MAP.set('molecules/PlaygroundSettings', PlaygroundSettingsBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/PlaygroundSettings');
					}
				
		
					const PostalCodeButtonBase = resolveFromModule(Base_molecules_PostalCodeButton, 'PostalCodeButton', 'molecules/PostalCodeButton');
					if (PostalCodeButtonBase) {
						COMPONENT_MAP.set('molecules/PostalCodeButton', PostalCodeButtonBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/PostalCodeButton');
					}
				
		
					const SuggestedPromptBase = resolveFromModule(Base_molecules_SuggestedPrompt, 'SuggestedPrompt', 'molecules/SuggestedPrompt');
					if (SuggestedPromptBase) {
						COMPONENT_MAP.set('molecules/SuggestedPrompt', SuggestedPromptBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/SuggestedPrompt');
					}
				
		
					const SuggestedPromptsBase = resolveFromModule(Base_molecules_SuggestedPrompts, 'SuggestedPrompts', 'molecules/SuggestedPrompts');
					if (SuggestedPromptsBase) {
						COMPONENT_MAP.set('molecules/SuggestedPrompts', SuggestedPromptsBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/SuggestedPrompts');
					}
				
		
					const WelcomeMessageBase = resolveFromModule(Base_molecules_WelcomeMessage, 'WelcomeMessage', 'molecules/WelcomeMessage');
					if (WelcomeMessageBase) {
						COMPONENT_MAP.set('molecules/WelcomeMessage', WelcomeMessageBase);
					} else {
						console.warn('Failed to resolve base component:', 'molecules/WelcomeMessage');
					}
				
		
					const ChatInputBase = resolveFromModule(Base_Chat_ChatInput, 'ChatInput', 'Chat/ChatInput');
					if (ChatInputBase) {
						COMPONENT_MAP.set('Chat/ChatInput', ChatInputBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/ChatInput');
					}
				
		
					const ChatLoaderBase = resolveFromModule(Base_Chat_ChatLoader, 'ChatLoader', 'Chat/ChatLoader');
					if (ChatLoaderBase) {
						COMPONENT_MAP.set('Chat/ChatLoader', ChatLoaderBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/ChatLoader');
					}
				
		
					const ChatMessageBase = resolveFromModule(Base_Chat_ChatMessage, 'ChatMessage', 'Chat/ChatMessage');
					if (ChatMessageBase) {
						COMPONENT_MAP.set('Chat/ChatMessage', ChatMessageBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/ChatMessage');
					}
				
		
					const ChatTextareaBase = resolveFromModule(Base_Chat_ChatTextarea, 'ChatTextarea', 'Chat/ChatTextarea');
					if (ChatTextareaBase) {
						COMPONENT_MAP.set('Chat/ChatTextarea', ChatTextareaBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/ChatTextarea');
					}
				
		
					const ContactFormBase = resolveFromModule(Base_Chat_ContactForm, 'ContactForm', 'Chat/ContactForm');
					if (ContactFormBase) {
						COMPONENT_MAP.set('Chat/ContactForm', ContactFormBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/ContactForm');
					}
				
		
					const ContactFormIconBase = resolveFromModule(Base_Chat_ContactFormIcon, 'ContactFormIcon', 'Chat/ContactFormIcon');
					if (ContactFormIconBase) {
						COMPONENT_MAP.set('Chat/ContactFormIcon', ContactFormIconBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/ContactFormIcon');
					}
				
		
					const ContactFormSubmissionErrorBase = resolveFromModule(Base_Chat_ContactFormSubmissionError, 'ContactFormSubmissionError', 'Chat/ContactFormSubmissionError');
					if (ContactFormSubmissionErrorBase) {
						COMPONENT_MAP.set('Chat/ContactFormSubmissionError', ContactFormSubmissionErrorBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/ContactFormSubmissionError');
					}
				
		
					const EndConversationModalBase = resolveFromModule(Base_Chat_EndConversationModal, 'EndConversationModal', 'Chat/EndConversationModal');
					if (EndConversationModalBase) {
						COMPONENT_MAP.set('Chat/EndConversationModal', EndConversationModalBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/EndConversationModal');
					}
				
		
					const EndConversationThankYouMessageBase = resolveFromModule(Base_Chat_EndConversationThankYouMessage, 'EndConversationThankYouMessage', 'Chat/EndConversationThankYouMessage');
					if (EndConversationThankYouMessageBase) {
						COMPONENT_MAP.set('Chat/EndConversationThankYouMessage', EndConversationThankYouMessageBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/EndConversationThankYouMessage');
					}
				
		
					const LocationBase = resolveFromModule(Base_Chat_Location, 'Location', 'Chat/Location');
					if (LocationBase) {
						COMPONENT_MAP.set('Chat/Location', LocationBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/Location');
					}
				
		
					const ProductBase = resolveFromModule(Base_Chat_Product, 'Product', 'Chat/Product');
					if (ProductBase) {
						COMPONENT_MAP.set('Chat/Product', ProductBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/Product');
					}
				
		
					const ResetChatBase = resolveFromModule(Base_Chat_ResetChat, 'ResetChat', 'Chat/ResetChat');
					if (ResetChatBase) {
						COMPONENT_MAP.set('Chat/ResetChat', ResetChatBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/ResetChat');
					}
				
		
					const ThankYouMessageBase = resolveFromModule(Base_Chat_ThankYouMessage, 'ThankYouMessage', 'Chat/ThankYouMessage');
					if (ThankYouMessageBase) {
						COMPONENT_MAP.set('Chat/ThankYouMessage', ThankYouMessageBase);
					} else {
						console.warn('Failed to resolve base component:', 'Chat/ThankYouMessage');
					}
				
		
					const ArrowBase = resolveFromModule(Base_icons_Arrow, 'Arrow', 'icons/Arrow');
					if (ArrowBase) {
						COMPONENT_MAP.set('icons/Arrow', ArrowBase);
					} else {
						console.warn('Failed to resolve base component:', 'icons/Arrow');
					}
				
		
					const ExitBase = resolveFromModule(Base_icons_Exit, 'Exit', 'icons/Exit');
					if (ExitBase) {
						COMPONENT_MAP.set('icons/Exit', ExitBase);
					} else {
						console.warn('Failed to resolve base component:', 'icons/Exit');
					}
				
		
					const WidgetIconBase = resolveFromModule(Base_icons_WidgetIcon, 'WidgetIcon', 'icons/WidgetIcon');
					if (WidgetIconBase) {
						COMPONENT_MAP.set('icons/WidgetIcon', WidgetIconBase);
					} else {
						console.warn('Failed to resolve base component:', 'icons/WidgetIcon');
					}
				
		
					const AiIconBase = resolveFromModule(Base_icons_AiIcon, 'AiIcon', 'icons/AiIcon');
					if (AiIconBase) {
						COMPONENT_MAP.set('icons/AiIcon', AiIconBase);
					} else {
						console.warn('Failed to resolve base component:', 'icons/AiIcon');
					}
				
		
					const CloseIconBase = resolveFromModule(Base_icons_CloseIcon, 'CloseIcon', 'icons/CloseIcon');
					if (CloseIconBase) {
						COMPONENT_MAP.set('icons/CloseIcon', CloseIconBase);
					} else {
						console.warn('Failed to resolve base component:', 'icons/CloseIcon');
					}
				
	} catch (error) {
		console.error('Error registering base components:', error);
	}

	// Add client overrides if they exist
	try {
		
					const ChatSubmitButtonOverride = resolveFromModule(Client_atoms_ChatSubmitButton, 'ChatSubmitButton', 'atoms/ChatSubmitButton');
					if (ChatSubmitButtonOverride) {
						COMPONENT_MAP.set('atoms/ChatSubmitButton', ChatSubmitButtonOverride);
					} else {
						console.warn('Failed to resolve client override:', 'atoms/ChatSubmitButton');
					}
				
		
					const MessageInfoOverride = resolveFromModule(Client_atoms_MessageInfo, 'MessageInfo', 'atoms/MessageInfo');
					if (MessageInfoOverride) {
						COMPONENT_MAP.set('atoms/MessageInfo', MessageInfoOverride);
					} else {
						console.warn('Failed to resolve client override:', 'atoms/MessageInfo');
					}
				
		
					const SubmitButtonContentOverride = resolveFromModule(Client_atoms_SubmitButtonContent, 'SubmitButtonContent', 'atoms/SubmitButtonContent');
					if (SubmitButtonContentOverride) {
						COMPONENT_MAP.set('atoms/SubmitButtonContent', SubmitButtonContentOverride);
					} else {
						console.warn('Failed to resolve client override:', 'atoms/SubmitButtonContent');
					}
				
		
					const WidgetTooltipOverride = resolveFromModule(Client_atoms_WidgetTooltip, 'WidgetTooltip', 'atoms/WidgetTooltip');
					if (WidgetTooltipOverride) {
						COMPONENT_MAP.set('atoms/WidgetTooltip', WidgetTooltipOverride);
					} else {
						console.warn('Failed to resolve client override:', 'atoms/WidgetTooltip');
					}
				
		
					const SuggestedPromptOverride = resolveFromModule(Client_molecules_SuggestedPrompt, 'SuggestedPrompt', 'molecules/SuggestedPrompt');
					if (SuggestedPromptOverride) {
						COMPONENT_MAP.set('molecules/SuggestedPrompt', SuggestedPromptOverride);
					} else {
						console.warn('Failed to resolve client override:', 'molecules/SuggestedPrompt');
					}
				
		
					const SuggestedPromptsOverride = resolveFromModule(Client_molecules_SuggestedPrompts, 'SuggestedPrompts', 'molecules/SuggestedPrompts');
					if (SuggestedPromptsOverride) {
						COMPONENT_MAP.set('molecules/SuggestedPrompts', SuggestedPromptsOverride);
					} else {
						console.warn('Failed to resolve client override:', 'molecules/SuggestedPrompts');
					}
				
	} catch (error) {
		console.error('Error registering client overrides:', error);
	}
	
	/**
	 * Enhanced implementation that resolves components with better error handling
	 * @param {string} componentPath - The path to the component (e.g., "atoms/HeaderAvatar")
	 * @param {string} [_ssr] - Unused SSR parameter (kept for compatibility)
	 * @param {Function|null} [fallback] - Optional fallback component
	 * @returns {Function} The resolved component or a fallback
	 */
	export const resolveDynamicComponent = (componentPath, _ssr, fallback) => {
		try {
			if (!componentPath) {
				console.error('Component path is required');
				return fallback || (() => null);
			}

			
			// Get the component from our map - this will prioritize client overrides
			const Component = COMPONENT_MAP.get(componentPath);
			
			if (!Component) {
				console.warn('Component not found:', componentPath);
				console.warn('Available components:', safeGetKeys(COMPONENT_MAP));
				return fallback || (() => {
					console.warn('Rendering null for missing component:', componentPath);
					return null;
				});
			}

			return Component;
		} catch (error) {
			console.error('Error resolving component:', error);
			return fallback || (() => null);
		}
	};
	
	/**
	 * Enhanced async resolve function from the original componentResolver
	 */
	export const resolveComponent = (componentPath) => {
		try {
			if (!componentPath) {
				console.error('Component path is required for async resolution');
				return Promise.resolve({ default: null });
			}

			// This will prioritize client overrides because of how COMPONENT_MAP is constructed
			const component = COMPONENT_MAP.get(componentPath);
			
			if (!component) {
				console.warn('Async component not found:', componentPath);
				console.warn('Available components:', safeGetKeys(COMPONENT_MAP));
				return Promise.resolve({ default: null });
			}

			return Promise.resolve({
				default: component
			});
		} catch (error) {
			console.error('Error in async component resolution:', error);
			return Promise.resolve({ default: null });
		}
	};
	
	// Export all components
	export const AttachedFile = COMPONENT_MAP.get('atoms/AttachedFile');
		export const Avatar = COMPONENT_MAP.get('atoms/Avatar');
		export const ChatFooter = COMPONENT_MAP.get('atoms/ChatFooter');
		export const HeaderAgentName = COMPONENT_MAP.get('atoms/HeaderAgentName');
		export const HeaderAvatar = COMPONENT_MAP.get('atoms/HeaderAvatar');
		export const MessageInfo = COMPONENT_MAP.get('atoms/MessageInfo');
		export const MessageLoader = COMPONENT_MAP.get('atoms/MessageLoader');
		export const SubmitButtonContent = COMPONENT_MAP.get('atoms/SubmitButtonContent');
		export const WidgetTooltip = COMPONENT_MAP.get('atoms/WidgetTooltip');
		export const ChatHeader = COMPONENT_MAP.get('molecules/ChatHeader');
		export const ChatIcon = COMPONENT_MAP.get('molecules/ChatIcon');
		export const ChatSubmitButton = COMPONENT_MAP.get('molecules/ChatSubmitButton');
		export const DrupalImage = COMPONENT_MAP.get('molecules/DrupalImage');
		export const FileInput = COMPONENT_MAP.get('molecules/FileInput');
		export const MessagesList = COMPONENT_MAP.get('molecules/MessagesList');
		export const Modal = COMPONENT_MAP.get('molecules/Modal');
		export const PlaygroundSettings = COMPONENT_MAP.get('molecules/PlaygroundSettings');
		export const PostalCodeButton = COMPONENT_MAP.get('molecules/PostalCodeButton');
		export const SuggestedPrompt = COMPONENT_MAP.get('molecules/SuggestedPrompt');
		export const SuggestedPrompts = COMPONENT_MAP.get('molecules/SuggestedPrompts');
		export const WelcomeMessage = COMPONENT_MAP.get('molecules/WelcomeMessage');
		export const ChatInput = COMPONENT_MAP.get('Chat/ChatInput');
		export const ChatLoader = COMPONENT_MAP.get('Chat/ChatLoader');
		export const ChatMessage = COMPONENT_MAP.get('Chat/ChatMessage');
		export const ChatTextarea = COMPONENT_MAP.get('Chat/ChatTextarea');
		export const ContactForm = COMPONENT_MAP.get('Chat/ContactForm');
		export const ContactFormIcon = COMPONENT_MAP.get('Chat/ContactFormIcon');
		export const ContactFormSubmissionError = COMPONENT_MAP.get('Chat/ContactFormSubmissionError');
		export const EndConversationModal = COMPONENT_MAP.get('Chat/EndConversationModal');
		export const EndConversationThankYouMessage = COMPONENT_MAP.get('Chat/EndConversationThankYouMessage');
		export const Location = COMPONENT_MAP.get('Chat/Location');
		export const Product = COMPONENT_MAP.get('Chat/Product');
		export const ResetChat = COMPONENT_MAP.get('Chat/ResetChat');
		export const ThankYouMessage = COMPONENT_MAP.get('Chat/ThankYouMessage');
		export const Arrow = COMPONENT_MAP.get('icons/Arrow');
		export const Exit = COMPONENT_MAP.get('icons/Exit');
		export const WidgetIcon = COMPONENT_MAP.get('icons/WidgetIcon');
		export const AiIcon = COMPONENT_MAP.get('icons/AiIcon');
		export const CloseIcon = COMPONENT_MAP.get('icons/CloseIcon');
	