"use client";

// contexts/ChatContext.tsx
import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { useChat } from "@/hooks/useChat";
import { Message } from "@/types";
import { HandoverState } from "@/types/HandoverState";

/**
 * Interface defining the Chat Context properties and methods.
 * Provides typing for all chat-related functionality available through the context.
 */
interface ChatContextProps {
	appUrl?: string;
	loadingMessages: boolean;
	/** Current state of customer service handover */
	handoverState: HandoverState;
	/** Handles sending a new message */
	handleSend: (message: Message) => void;
	/** Resets the chat session, optionally with a new session ID */
	handleReset: (newSessionId?: string) => void;
	/** Processes contact form submission */
	handleContactFormSubmit: (formData: {
		name: string;
		email: string;
		phone: string;
		postalCode: string;
		message: string;
		includeChat: boolean;
		sessionId: string;
	}) => Promise<void>;
	isEditing: boolean;
	setIsEditing: (isEditing: boolean) => void;
	postalCode: string | null;
	setPostalCode: (postalCode: string | null) => void;
	showModal: boolean;
	setShowModal: (show: boolean) => void;
}

/**
 * Create the Chat Context with undefined initial value.
 * This context provides chat functionality throughout the application.
 */
const ChatContext = createContext<ChatContextProps | undefined>(undefined);

/**
 * Props for the ChatProvider component
 */
interface ChatProviderProps {
	children: ReactNode;
}

/**
 * ChatProvider component that wraps the application to provide chat functionality.
 * Manages session state and integrates socket connection with chat operations.
 */
export const ChatProvider = ({ children }: ChatProviderProps) => {
	const chat = useChat();
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [postalCode, setPostalCode] = useState<string | null>(null);
	const [showModal, setShowModal] = useState<boolean>(false);

	const contextValue = useMemo(
		() => ({
			isEditing,
			setIsEditing,
			postalCode,
			setPostalCode,
			showModal,
			setShowModal,
			...chat,
		}),
		[isEditing, postalCode, chat, showModal],
	);

	return <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>;
};

/**
 * Custom hook to access the chat context.
 * Must be used within a ChatProvider component.
 *
 * @throws {Error} If used outside of a ChatProvider
 * @returns {ChatContextProps} The chat context value
 */
export const useChatContext = (): ChatContextProps => {
	const context = useContext(ChatContext);
	if (!context) {
		throw new Error("useChatContext must be used within a ChatProvider");
	}
	return context;
};
