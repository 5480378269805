import { useEffect, useState } from "react";

import { useChatContext } from "@/contexts/ChatContext";

interface ModalProps {
	children: React.ReactNode;
	isOpen?: boolean;
	onClose?: () => void;
	title?: string;
}

export const Modal = ({ children, isOpen, onClose, title }: ModalProps) => {
	const { showEndConversationModal, showLocationModal } = useChatContext();
	const [isClosing, setIsClosing] = useState(false);
	const [shouldRender, setShouldRender] = useState(false);
	
	// Use the prop if provided, otherwise use context
	const isModalOpen = isOpen !== undefined ? isOpen : (showEndConversationModal || showLocationModal);

	useEffect(() => {
		if (isModalOpen) {
			setIsClosing(false);
			setShouldRender(true);
			
			// Prevent background scrolling when modal is open
			document.body.style.overflow = 'hidden';
		} else {
			setIsClosing(true);
			const timer = setTimeout(() => {
				setShouldRender(false);
				// Restore scrolling when modal is closed
				document.body.style.overflow = '';
			}, 250); // Duration should match CSS animation (250ms)
			return () => clearTimeout(timer);
		}
		
		// Cleanup overflow style when component unmounts
		return () => {
			document.body.style.overflow = '';
		};
	}, [isModalOpen]);

	if (!shouldRender) return null;

	return (
		<div
			className={`fixed inset-0 z-50 flex items-center rounded-xl justify-center bg-black bg-opacity-70 will-change-opacity transition-opacity duration-250 ${
				isClosing ? 'modal-overlay-exiting' : 'modal-overlay-entering'
			}`}
			onClick={onClose ? (e) => {
				// Only close if the overlay is clicked, not the modal content
				if (e.target === e.currentTarget) onClose();
			} : undefined}
		>
			<div
				className={`bg-white rounded-lg border-2 border-client-gray-light p-6 w-10/12 max-w-[500px] shadow-xl will-change-transform ${
					isClosing ? 'modal-content-exiting' : 'modal-content-entering'
				}`}
				onClick={(e) => e.stopPropagation()} // Prevent clicks inside modal from closing it
			>
				{title && (
					<h2 className="text-lg font-semibold mb-4">{title}</h2>
				)}
				<div className="modal-content-inner">
					{children}
				</div>
			</div>
		</div>
	);
};
