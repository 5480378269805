import { useAppContext } from "@/contexts/AppContext";
import useTranslate from "@/hooks/useTranslate";

const ChatFooter = () => {
	const __ = useTranslate("Chat");
	const { clientConfig } = useAppContext();

	return (
		<div className="text-sm text-gray-400 mt-1 flex justify-center">
			<p>
				{__("chatFooterText")}{" "}
				<a className="underline" href={clientConfig?.privatePolicyUrl} target="_blank" rel="noopener noreferrer">
					{__("privacyPolicy")}
				</a>
			</p>
		</div>
	);
};

export default ChatFooter;
