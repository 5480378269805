import { SuggestedPromptProps } from "@/components/molecules/SuggestedPrompt";

export const SuggestedPrompt: React.FC<SuggestedPromptProps> = ({ onClick, children }) => {
	return (
		<button
			className="example-prompt border-client-primary message message-user text-client-primary hover:text-white hover:bg-client-primary rounded-lg px-3 py-2 whitespace-pre-wrap my-1 sm:my-1.5 inline-flex items-center"
			onClick={onClick}
		>
			{children}
		</button>
	);
};
