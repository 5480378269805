import { MdOutlineClose } from "react-icons/md";
import { PiPaperclip } from "react-icons/pi";

import useTranslate from "@/hooks/useTranslate";

type Props = {
	attachedFile: File | null;
	onRemoveAttachedFile: () => void;
};

export const AttachedFile = ({ attachedFile, onRemoveAttachedFile }: Props) => {
	const __ = useTranslate("Chat");
	if (!attachedFile) return null;

	return (
		<div className="flex items-center justify-between space-x-2 mb-2 p-2 bg-neutral-100 rounded-md attached-file">
			<div className="flex items-center space-x-1">
				<PiPaperclip className="text-neutral-500 w-5 h-5" />
				<span className="text-sm text-neutral-700 attached-name">{attachedFile.name}</span>
			</div>
			<button
				onClick={onRemoveAttachedFile}
				className="text-neutral-400 hover:text-neutral-600"
				aria-label={__("removeAttachedFile")}
			>
				<MdOutlineClose className="w-4 h-4" />
			</button>
		</div>
	);
};
