import React, { ChangeEvent, ClipboardEvent, FocusEvent, KeyboardEvent, useEffect, useRef } from "react";

import { useAppContext } from "@/contexts/AppContext";
import useTranslate from "@/hooks/useTranslate";

export type TextareaProps = {
	content: string;
	onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
	onPaste: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
	onFocus: () => void;
	onBlur: () => void;
	isExpanded: boolean;
};

export const ChatTextarea: React.FC<TextareaProps> = ({ content, onChange, onKeyDown, onPaste, onFocus, onBlur, isExpanded }) => {
	const { isDisabledInput } = useAppContext();
	const textareaRef = useRef<HTMLTextAreaElement | null>(null);
	const __ = useTranslate();

	// Auto-resize textarea to match content height
	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = "inherit";
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	}, [content]);

	return (
		<textarea
			ref={textareaRef}
			className={`max-h-[126px] rounded-[11px] w-full text-black outline-none focus:outline-none active:outline-none transition-all duration-300 ease-in-out px-4`}
			style={{ 
				resize: "none", 
				paddingTop: "0.7rem", 
				paddingBottom: "0.7rem",
				transition: "all 300ms ease-in-out"
			}}
			placeholder={__("enter-content-here")}
			value={content}
			rows={1}
			onChange={onChange}
			onKeyDown={onKeyDown}
			onPaste={onPaste}
			onFocus={onFocus}
			onBlur={onBlur}
			disabled={isDisabledInput}
		/>
	);
};
