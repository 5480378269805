export const formatLists = (text: string): string => {
	const lines = text.split("\n");
	let result = "";
	let inList = false;
	let inSubList = false;
	let isNumberedList = false;
  
	for (let i = 0; i < lines.length; i++) {
		const line = lines[i];
		// Match a list item: capture any leading whitespace, a "-" or "*" or number followed by dot, then the content
		const match = line.match(/^(\s*)([-*]|\d+\.)\s+(.*)/);
	  
		if (match) {
			const [, indent, marker, content] = match;
			const isNested = indent.length > 0;
			const isNumbered = /^\d+\./.test(marker);

			if (!inList) {
				// Start a new list with the appropriate style
				const listStyle = isNumbered ? "decimal" : "disc";
				result += `<ul style="list-style: ${listStyle}; padding-left: 1.5em; margin: 0.5em 0;">`;
				inList = true;
				isNumberedList = isNumbered;
			} else if (isNumbered !== isNumberedList && !isNested) {
				// Close previous list and start a new one with different style
				result += "</ul>";
				const listStyle = isNumbered ? "decimal" : "disc";
				result += `<ul style="list-style: ${listStyle}; padding-left: 1.5em; margin: 0.5em 0;">`;
				isNumberedList = isNumbered;
			}

			if (isNested && !inSubList) {
				// Start a nested list
				result += "<ul style=\"list-style: disc; padding-left: 1.5em; margin: 0.5em 0;\">";
				inSubList = true;
			} else if (!isNested && inSubList) {
				// Close nested list
				result += "</ul>";
				inSubList = false;
			}

			// Clean up any paragraph tags within list items and add margin for spacing
			const cleanContent = content.replace(/<\/?p>/g, '');
			result += `<li style="margin-bottom: 0.7em;">${cleanContent}</li>`;
		} else {
			// Close any open lists when we hit non-list content
			if (inSubList) {
				result += "</ul>";
				inSubList = false;
			}
			if (inList) {
				result += "</ul>";
				inList = false;
				isNumberedList = false;
			}
			result += line + "\n";
		}
	}
  
	// Close any remaining open lists
	if (inSubList) {
		result += "</ul>";
	}
	if (inList) {
		result += "</ul>";
	}
  
	return result;
};