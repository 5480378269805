import { PiPaperPlaneTiltFill } from "react-icons/pi";

export type SendButtonProps = {
	onClick: () => void;
	disabled: boolean;
};

export const SubmitButton: React.FC<SendButtonProps> = ({ onClick, disabled }) => {
	return (
		<button
			onClick={onClick}
			disabled={disabled}
			aria-label="Send message"
			className="shadow-none bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent"
		>
			<PiPaperPlaneTiltFill
				className={`h-8 w-8 rounded-md p-1 bg-client-primary text-black ${
					disabled ? "cursor-not-allowed opacity-50" : "hover:cursor-pointer message-user text-white hover:bg-client-primary-hover transition-colors duration-200"
				}`}
			/>
		</button>
	);
};
