"use server";

import axios from "axios";

import { getEnvAppUrl } from "@/utils/getEnvAppUrl";

// We'll only use this for constructing image URLs
const DRUPAL_API_URL = process.env.NEXT_PUBLIC_DRUPAL_API_URL;

if (!DRUPAL_API_URL) {
	console.error("DRUPAL_API_URL is not defined in the environment variables.");
}

// Interface definitions
interface StockSource {
	id: string;
	name: string;
	stock: number;
}

interface Variant {
	width: string;
	height: string;
	price: number;
	stockSources: StockSource[];
	swingDirection: string;
	swingDirectionImage: string;
}

interface ProductData {
	id: string;
	image: string;
	name: string;
	brand: string;
	price: number;
	variants: Variant[];
	stock: number;
	deliveryTime: string | null;
	isProductBundle?: boolean;
	pathAlias?: string;
}

// Stock sources definition
const stockSources = [
	{ id: "1294cf27-8c84-4aca-a609-81471109fa3f", name: "Oslo", stock: 0 },
	{ id: "a3c78978-a189-4306-94ec-2bc64daa4adb", name: "Trondheim", stock: 0 },
	{ id: "47da76ca-2d82-486b-abc2-f59b67023940", name: "Ålesund", stock: 0 },
	{ id: "5645e031-418d-4514-87c5-d0e92530b77d", name: "Stjørdal", stock: 0 },
	{ id: "5e228688-b6a4-47a8-9710-3274d18e4002", name: "Namsos", stock: 0 },
	{ id: "c218a42b-9fab-4fba-b296-c6b825738dfa", name: "Stavanger", stock: 0 },
	{ id: "7b75cd9a-8194-4ba5-a429-64bcc2dd3fda", name: "Askim", stock: 0 },
	{ id: "46fc6d23-0b16-402e-af41-edb2e0d4c82f", name: "Nettbutikk", stock: 0 },
];

/**
 * Server-side request to Drupal API via our proxy endpoint
 * Uses our own API route to hide the external API URL
 */
const drupalGet = async (url: string) => {
	try {
		// Extract the path part from the full Drupal URL
		let path = url;
		if (DRUPAL_API_URL && url.startsWith(DRUPAL_API_URL)) {
			path = url.substring(DRUPAL_API_URL.length);
		}

		// Remove leading slash if present
		if (path.startsWith("/")) {
			path = path.substring(1);
		}

		// Use our proxy endpoint with the path as part of the URL
		const appUrl = getEnvAppUrl();
		const proxyUrl = `${appUrl}/api/proxy/drupal/${path}`;

		const response = await axios.get(proxyUrl, {
			headers: {
				origin: getEnvAppUrl(), // Spoof the desired origin
			},
		});

		return response.data;
	} catch (error: any) {
		console.error(`Error in drupalGet for URL ${url}:`, error.message);
		throw error;
	}
};

/**
 * Server action to fetch an image URL from a Drupal image relationship.
 * This prevents CORS issues by handling the requests server-side.
 *
 * @param relationshipUrl - The URL for the image relationship in Drupal's JSON API
 * @returns The absolute URL to the image or null if not found
 */
export async function fetchImageUrlAction(relationshipUrl: string): Promise<string | null> {
	if (!relationshipUrl) return null;
	if (!DRUPAL_API_URL) {
		throw new Error("DRUPAL_API_URL is not defined.");
	}

	try {
		// 1) Relationship data
		const relationshipData = await drupalGet(relationshipUrl);
		if (!relationshipData?.data || !relationshipData.data[0]) return null;

		const fileId = relationshipData.data[0].id;
		const fileUrl = `${DRUPAL_API_URL}/jsonapi/file/file/${fileId}`;

		// 2) File data
		const fileData = await drupalGet(fileUrl);
		const relativePath = fileData?.data?.attributes?.uri?.url;
		if (!relativePath) return null;

		// Construct the full URL
		return `${DRUPAL_API_URL}${relativePath}`;
	} catch (error: any) {
		console.error(`Error fetching image URL from relationship URL ${relationshipUrl}:`, error.message);
		return null;
	}
}

/**
 * Internal helper for fetching image URLs
 * Now uses the server action internally
 */
const fetchImageUrl = async (relationshipUrl: string): Promise<string | null> => {
	return fetchImageUrlAction(relationshipUrl);
};

/**
 * Clean up decimal variant strings like '100.000' => '100'
 */
const cleanVariantName = (name: string): string => {
	return name.replace(/\.0+$/, ""); // Remove trailing decimals like ".00000"
};

/**
 * Get the local image path for a swing direction
 */
const getSwingDirectionImage = (swingDirection: string): string => {
	if (swingDirection === "Høyre") return "/images/hinged_right.png";
	if (swingDirection === "Venstre") return "/images/hinged_left.png";
	return "";
};

/**
 * Main server action to fetch product details
 */
export async function fetchProductDetails(productId: string): Promise<ProductData> {
	if (!DRUPAL_API_URL) {
		throw new Error("DRUPAL_API_URL is not defined.");
	}

	const mainUrl = `${DRUPAL_API_URL}/jsonapi/commerce_product/default/${productId}?include=variations,field_product_vendor,field_delivery_time,variations.attribute_swing_direction`;

	try {
		const responseData = await drupalGet(mainUrl);
		const product = responseData.data;

		if (!product?.attributes) {
			throw new Error(`Product with ID ${productId} has no attributes.`);
		}

		const imageRelationshipUrl = product.relationships?.product_image?.links?.related?.href;
		const imageUrl = await fetchImageUrl(imageRelationshipUrl);

		// Create included lookup for efficient relationship lookups
		const included = responseData.included || [];
		const includedLookup = included.reduce((acc: any, item: any) => {
			acc[`${item.type}:${item.id}`] = item;
			return acc;
		}, {});

		// Get vendor name from included data
		const vendorRel = product.relationships?.field_product_vendor?.data;
		let brand = "";
		if (vendorRel) {
			const key = `${vendorRel.type}:${vendorRel.id}`;
			const vendorItem = includedLookup[key];
			brand = vendorItem?.attributes?.name || "";
		}

		let price = parseFloat(product.attributes.price?.number || "0");

		let variants: Variant[] = [];
		if (included) {
			const variationsData = included.filter((item: any) => item.type === "commerce_product_variation--default");

			if (variationsData.length > 0) {
				variants = variationsData
					.map((variation: any): Variant => {
						const dimensions = variation.attributes?.dimensions || {};
						const variantPrice = parseFloat(variation.attributes?.price?.number || "0");

						let width = cleanVariantName(parseFloat(dimensions.width).toFixed(0));
						let height = cleanVariantName(parseFloat(dimensions.height).toFixed(0));

						const swingDirectionId = variation.relationships?.attribute_swing_direction?.data?.id;
						let swingDirection = "";

						if (swingDirectionId) {
							const swingItem = included.find(
								(item: any) =>
									item.type === "commerce_product_attribute_value--swing_direction" && item.id === swingDirectionId,
							);
							swingDirection = swingItem?.attributes?.name || "";
						}

						const swingDirectionImage = getSwingDirectionImage(swingDirection);

						// Process stock information from the relationships
						const stockRelationships = variation.relationships?.stock?.data || [];
						const stockSourcesMapped = stockSources.map((source) => {
							const stockEntry = stockRelationships.find((st: any) => st.id === source.id);
							const stockValue = stockEntry ? parseInt(stockEntry.meta.stock, 10) : 0;
							return { ...source, stock: stockValue };
						});

						return {
							width,
							height,
							price: variantPrice,
							stockSources: stockSourcesMapped,
							swingDirection,
							swingDirectionImage,
						};
					})
					.filter((variant: Variant) => variant.price > 0);
			}

			// Check for bundled product price
			const bundleVariation = included.find((item: any) => item.type === "commerce_product_variation--product_bundle");
			if (bundleVariation) {
				price = parseFloat(bundleVariation.attributes.price.number);
			}
		}

		const stock = parseInt(product.attributes.field_product_stock?.value || "0");

		// Get delivery time from included data
		const deliveryTimeRel = product.relationships?.field_delivery_time?.data;
		let deliveryTime = null;
		if (deliveryTimeRel) {
			const key = `${deliveryTimeRel.type}:${deliveryTimeRel.id}`;
			const deliveryItem = includedLookup[key];
			deliveryTime = deliveryItem?.attributes?.name || null;
		}

		const isProductBundle =
			product.relationships?.commerce_product_type?.data?.meta?.drupal_internal__target_id === "product_bundle";

		const pathAlias = product.attributes.path?.alias || "";

		return {
			id: productId,
			image: imageUrl ?? "",
			name: product.attributes.title ?? "",
			brand: brand ?? "",
			price: price > 0 ? price : 0,
			variants,
			stock,
			deliveryTime,
			isProductBundle,
			pathAlias,
		};
	} catch (err: any) {
		console.error(`Error fetching product details for ID ${productId}:`, err.message);
		throw err;
	}
}
