import React, { ReactNode } from "react";

export type SuggestedPromptProps = {
	onClick: () => void;
	children: ReactNode;
};

export const SuggestedPrompt: React.FC<SuggestedPromptProps> = ({ onClick, children }) => {
	return (
		<button
			className="bg-client-primary text-client-text-primary rounded-lg px-3 py-2 whitespace-pre-wrap my-1 sm:my-1.5 inline-flex items-center"
			onClick={onClick}
		>
			{children}
		</button>
	);
};
