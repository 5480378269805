import { useCallback, useRef } from "react";

import { useDataContext } from "@/contexts/DataContext";
import useTranslate from "@/hooks/useTranslate";
import { createMessage } from "@/services/liveChat/LiveChat";
import { CustomerSDKType, DisconnectedEvent, LivechatErrorContext } from "@/services/liveChat/types";
import { AgentType, LangflowMessageType, Role } from "@/types";
import { saveMessageInDB } from "@/utils/saveMessageInDB";

const RATE_LIMIT_DELAY = 1000; // 1 second delay for rate limiting

export const useLiveChatErrors = () => {
	const __ = useTranslate("Chat");
	const { setActiveAgent, sessionId, setMessages, activeAgent } = useDataContext();
	const rateLimitTimeout = useRef<NodeJS.Timeout | null>(null);

	const handleRateLimitError = useCallback(() => {
		if (rateLimitTimeout.current) {
			return; // Don't show multiple rate limit messages in quick succession
		}

		const rateLimitMessage = createMessage(__("yourMessageHasNotBeenSent"), Role.system, AgentType.System);
		saveMessageInDB({
			message: rateLimitMessage,
			sessionId,
			type: LangflowMessageType.message,
		}).then(() => {
			setMessages((prev) => [...prev, rateLimitMessage]);
		});

		// Set a timeout to prevent showing multiple rate limit messages
		rateLimitTimeout.current = setTimeout(() => {
			rateLimitTimeout.current = null;
		}, RATE_LIMIT_DELAY);
	}, [sessionId, setMessages, __]);

	const handleLiveChatError = useCallback(
		({ message, type, error, code, ...rest }: any, context: LivechatErrorContext | string) => {
			// Check for rate limit in multiple places
			if (
				code === "PENDING_REQUESTS_LIMIT_REACHED" ||
				error?.code === "PENDING_REQUESTS_LIMIT_REACHED" ||
				message?.includes("Requests limit reached")
			) {
				handleRateLimitError();
				return;
			}

			switch (context) {
				case LivechatErrorContext.SDK_ERROR:
					console.log("SDK_ERROR: " + type + " " + message);
					if (code === "PENDING_REQUESTS_LIMIT_REACHED") {
						handleRateLimitError();
						return;
					}
					break;
				case LivechatErrorContext.INITIALIZATION_ERROR:
					console.log("INITIALIZATION_ERROR: " + type + " " + message);
					if (activeAgent !== AgentType.LiveChat || type === "OFFLINE") return;
					setActiveAgent(AgentType.AI);
					break;
				case LivechatErrorContext.GET_CHAT_ERROR:
					console.log("GET_CHAT_ERROR: " + type + " " + message);
					break;
				case LivechatErrorContext.RESUME_CHAT_ERROR:
					console.log("RESUME_CHAT_ERROR: " + type + " " + message);
					break;
				case LivechatErrorContext.START_CHAT_ERROR:
					console.log("START_CHAT_ERROR: " + type + " " + message);
					break;
				case LivechatErrorContext.SEND_EVENT_ERROR:
					console.log("SEND_EVENT_ERROR: " + type + " " + message);
					if (activeAgent !== AgentType.LiveChat) return;
					const sendErroMessage = createMessage(__("yourMessageHasNotBeenSent"), Role.system, AgentType.System);
					saveMessageInDB({
						message: sendErroMessage,
						sessionId,
						type: LangflowMessageType.message,
					}).then(() => {
						setMessages((prev) => [...prev, sendErroMessage]);
					});
					break;
				case LivechatErrorContext.UPLOAD_FILE_ERROR:
					console.log("UPLOAD_FILE_ERROR: " + type + " " + message);
					break;
				case LivechatErrorContext.UPDATE_AGENT_ERROR:
					console.log("UPDATE_AGENT_ERROR: " + type + " " + message, activeAgent);
					if (activeAgent !== AgentType.LiveChat) return;
					const offlineMessage = createMessage(message, Role.system, AgentType.System);
					saveMessageInDB({
						message: offlineMessage,
						sessionId,
						type: LangflowMessageType.message,
					}).then(() => {
						setMessages((prev) => [...prev, offlineMessage]);
					});
					break;
				case LivechatErrorContext.UPDATE_CUSTOMER_ERROR:
					console.log("UPDATE_CUSTOMER_ERROR: " + type + " " + message, activeAgent);
					break;
				case LivechatErrorContext.CLOSE_CHAT_ERROR:
					console.log("CLOSE_CHAT_ERROR: " + type + " " + message);
					break;
				default:
					console.log("Unknown error:", message, type, error, rest);
					switch (type) {
						case "OFFLINE":
							if (activeAgent !== AgentType.AI) {
								setActiveAgent(AgentType.AI);
							} else {
								console.log("LiveChat agents are offline: " + type);
							}
							break;
						default:
							console.log("Unknown error type: " + type);
							break;
					}
			}
		},
		[setActiveAgent, activeAgent, sessionId, setMessages, __, handleRateLimitError],
	);

	const setupErrorListeners = useCallback(
		(sdk: CustomerSDKType | undefined) => {
			sdk?.on("error", (error: Error) => handleLiveChatError(error, LivechatErrorContext.SDK_ERROR));

			sdk?.on("disconnected", (event: unknown) => {
				const disconnectedEvent = event as DisconnectedEvent;
				handleLiveChatError(
					{
						type: "DISCONNECTED",
						message: disconnectedEvent.reason,
					},
					"Disconnected Event",
				);
			});

			return () => {
				sdk?.off("error");
				sdk?.off("disconnected");
			};
		},
		[handleLiveChatError],
	);

	return {
		handleLiveChatError,
		setupErrorListeners,
	};
};
